import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  color,
  StyleProps,
  useColorMode,
} from 'deepstash-ui';
import Head from 'next/head';
import React from 'react';
import { jsonLdScriptProps } from 'react-schemaorg';
import { BreadcrumbList } from 'schema-dts';
import { BreadcrumbLinkItem } from 'types/types';

interface BreadcrumbListProps {
  // Items to be sent in this order:
  // Home > Topic > Article > Idea
  // [home-bc, topic-bc, article-bc, idea-bc]
  items: BreadcrumbLinkItem[];
}

const BackButton: React.FC<BreadcrumbListProps & StyleProps> = ({
  items,
  ...props
}) => {
  const { colorMode } = useColorMode();

  const structuredDataObject = !!items.length
    ? jsonLdScriptProps<BreadcrumbList>({
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: items.map((item, index) => {
          return {
            '@type': 'ListItem',
            position: index + 1,
            item: {
              '@id': 'https://deepstash.com' + item.link,
              name: item.title,
            },
          };
        }),
      })
    : undefined;

  return !!items.length ? (
    <>
      <Head>
        <script {...structuredDataObject} />
      </Head>
      <Breadcrumb color={color[colorMode].text} opacity={0.6} {...props}>
        {items.map((item, index) => {
          return (
            <BreadcrumbItem
              key={index}
              isCurrentPage={index === items.length - 1}
            >
              <BreadcrumbLink href={item.link}>{item.title}</BreadcrumbLink>
            </BreadcrumbItem>
          );
        })}
      </Breadcrumb>
    </>
  ) : null;
};

export default BackButton;
