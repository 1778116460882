import * as React from 'react';
import { Icon, IconProps } from 'deepstash-ui';

export const LandingIllustration1 = React.memo<IconProps>(props => {
  return (
    <Icon
      width="1700"
      height="1080"
      viewBox="0 0 1700 1080"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_251_19310)">
        <rect width="1700" height="1080" fill="#FFD775" />
        <path
          d="M2956.22 -786.237C2815.02 -777.808 2430.34 -541.805 2312.11 -442.229C2181.82 -332.611 1980.8 -129.129 1871.36 7.19186C1748.58 160.085 1602.85 324.932 1445.42 393.524C1277.31 466.795 1110.05 464.893 944.698 368.783C779.347 272.672 683.836 66.1113 675.36 -165.781C668.058 -364.36 806.229 -683.727 948.123 -794.522L960.26 -811.107L-25.0109 -822.307L-48.1602 -793.486C-536.43 -231.907 -430.484 990.808 106.288 1481.15C413.053 1760.93 761.681 1784.67 1124.9 1712.31C1432.8 1650.96 1672.18 1446.46 1930.76 1245.66C2206.22 1031.69 2466.03 796.446 2750.65 595.95C2997.93 422.369 3365.59 316.904 3724.48 383.658C4083.38 450.413 4239.39 642.684 4325.35 858.88L4330.14 868.532L4348.79 -772.589L2961.86 -788.354L2956.22 -786.237Z"
          fill="#B395B6"
        />
        <path
          d="M3252.22 -1729.24C3111.02 -1720.81 2726.34 -1484.81 2608.11 -1385.23C2477.82 -1275.61 2276.8 -1072.13 2167.36 -935.808C2044.58 -782.915 1898.85 -618.068 1741.42 -549.476C1573.31 -476.205 1406.05 -478.107 1240.7 -574.217C1075.35 -670.328 979.836 -876.889 971.36 -1108.78C964.058 -1307.36 1102.23 -1626.73 1244.12 -1737.52L1256.26 -1754.11L270.989 -1765.31L247.84 -1736.49C-240.43 -1174.91 -134.484 47.8085 402.288 538.152C709.053 817.933 1057.68 841.673 1420.9 769.312C1728.8 707.956 1968.18 503.456 2226.76 302.665C2502.22 88.687 2762.03 -146.554 3046.65 -347.05C3293.93 -520.631 3661.59 -626.096 4020.48 -559.342C4379.38 -492.587 4535.39 -300.316 4621.35 -84.1201L4626.14 -74.468L4644.79 -1715.59L3257.86 -1731.35L3252.22 -1729.24Z"
          fill="#A784AA"
        />
        <path
          d="M1667.04 1218.88C1672.13 1158.77 1694.06 1093.56 1666 1036.96C1639.12 982.728 1573.74 979.526 1520.55 976.858C1518.32 976.858 1515.88 976.655 1513.34 976.604C1537.22 932.028 1565.73 887.324 1583.62 840.487C1610.15 771.005 1532.5 730.596 1475.96 717C1475.96 717 1485.03 758.577 1489.91 778.476C1485.46 785.287 1475.55 800.231 1470.6 807.448C1457.89 826.077 1443.97 844.07 1431.21 862.825C1413.42 888.773 1395.05 915.051 1376.15 939.525C1365.04 953.884 1338.82 957.111 1322.22 963.287C1277.5 979.933 1243.76 1017.55 1218.73 1057.32C1186.94 1107.92 1160.51 1161.87 1135 1215.77"
          fill="#EBF1F4"
        />
        <path
          d="M1667.04 1218.88C1672.13 1158.77 1694.06 1093.56 1666 1036.96C1639.12 982.728 1573.74 979.526 1520.55 976.858C1518.32 976.858 1515.88 976.655 1513.34 976.604C1537.22 932.028 1565.73 887.324 1583.62 840.487C1610.15 771.005 1532.5 730.596 1475.96 717C1475.96 717 1485.03 758.577 1489.91 778.476C1485.46 785.287 1475.55 800.231 1470.6 807.448C1457.89 826.077 1443.97 844.07 1431.21 862.825C1413.42 888.773 1395.05 915.051 1376.15 939.525C1365.04 953.884 1338.82 957.111 1322.22 963.287C1277.5 979.933 1243.76 1017.55 1218.73 1057.32C1186.94 1107.92 1160.51 1161.87 1135 1215.77"
          stroke="#323130"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1526.88 783.25L1533.75 779.5"
          stroke="#323130"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1543.75 797.625L1550.62 794.5"
          stroke="#323130"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1508.75 818.25C1513.8 826.726 1535.22 829.187 1542.5 822.674"
          stroke="#323130"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M126.686 957.072C130.409 958.235 135.148 958.786 139.787 955.996C144.125 953.41 146.878 948.591 147.34 944.708C147.802 940.824 143.008 938.615 145.099 934.557C147.191 930.5 152.654 927.951 157.082 926.944C161.51 925.937 166.061 925.618 170.395 923.005C175.32 920.032 178.586 914.731 179.374 910.267"
          stroke="black"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M204.304 991.014C203.2 987.603 202.648 983.144 205.163 978.36C207.494 973.889 211.888 970.654 215.447 969.723C219.006 968.793 221.08 973.097 224.782 970.588C228.485 968.08 230.771 962.539 231.651 958.181C232.531 953.824 232.78 949.435 235.135 944.966C238.958 937.712 248.052 914.828 252.14 913.514"
          stroke="black"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M83.5864 953.103L90.9255 963.571"
          stroke="black"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50.9066 935.291C51.7944 937.004 53.2072 938.354 54.9372 939.142"
          stroke="black"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M52.6674 958.96L58.5364 958.612"
          stroke="black"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.1598 981.578C20.4476 987.142 20.6149 993.485 24.6294 997.042C28.3561 1000.39 34.3944 999.978 38.9632 997.461C43.5319 994.944 45.2711 987.212 50.3172 986.586C55.3633 985.959 59.2388 990.736 61.1549 995.45C63.0709 1000.16 64.2135 1005.58 67.9701 1008.9C72.2448 1012.66 78.9281 1012.51 84.2229 1009.94C89.5177 1007.36 93.6679 1002.78 97.3566 998.137C101.494 992.94 105.283 987.465 108.697 981.755"
          stroke="black"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.0442 1029.74L17.942 1029.65"
          stroke="black"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.3975 1064.84C36.4305 1063.78 47.9198 1043.33 63.9412 1043.82"
          stroke="black"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M95.8788 1043.32C93.4215 1043.84 91.1127 1045.01 89.1856 1046.72"
          stroke="black"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M96.8299 1069.74C95.7437 1070.41 94.8152 1071.32 94.1209 1072.41C93.4265 1073.5 92.9861 1074.73 92.8361 1076"
          stroke="black"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M125.728 1065.05L116.337 1074.81C107.443 1084.05 94.4181 1101.28 96.8969 1109.54C99.3757 1117.8 111.943 1109.94 117.234 1108.86C111.636 1115.35 106.394 1122.77 105.336 1131.25C104.278 1139.73 108.721 1149.16 116.768 1150.68"
          stroke="black"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M194.077 942.892L159.754 980.555L134.108 1019.52"
          stroke="black"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M151.99 1043.44C151.583 1045.24 151.523 1047.08 151.812 1048.86C152.101 1050.64 152.733 1052.33 153.672 1053.83"
          stroke="black"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M189.391 1017.2C182.76 1029.49 175.917 1042.69 177.745 1056.26C178.415 1061.1 180.275 1067.05 183.469 1070.62C188.897 1076.74 199.573 1073.46 205.335 1067.13C207.1 1065.19 208.692 1062.76 208.455 1060.2C208.131 1057.05 204.929 1054.85 201.83 1054.88C198.671 1055.18 195.667 1056.62 193.35 1058.94C185.552 1065.95 177.636 1081.1 177.613 1093.7C177.59 1106.31 188.918 1110.98 194.75 1111.83C200.582 1112.68 207.483 1108.75 208.435 1102.71C208.711 1101.17 208.429 1099.61 207.642 1098.31C206.985 1097.49 206.146 1096.85 205.189 1096.43C204.232 1096.01 203.183 1095.83 202.125 1095.9C192.591 1095.91 185.759 1107.44 186.722 1117.15C187.685 1126.86 193.879 1134.83 199.828 1142.22"
          stroke="black"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M213.033 1029.21C212.502 1033.26 215.27 1036.98 218.705 1038.82C222.141 1040.66 226.169 1041.05 230.045 1041.58C235.384 1042.3 240.797 1043.4 245.496 1045.99C250.196 1048.58 254.1 1053.02 255.141 1058.45"
          stroke="black"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M227.125 1077.18C228.182 1079.19 229.905 1080.72 231.997 1081.52"
          stroke="black"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M310.041 806.111C310.987 821.101 309.94 839.698 309.765 854.635C309.725 857.658 308.958 861.488 305.809 861.81C304.632 861.834 303.447 861.566 302.369 861.03C294.843 857.961 287.678 854.046 281.038 849.374"
          fill="#0083CC"
        />
        <path
          d="M310.041 806.111C310.987 821.101 309.94 839.698 309.765 854.635C309.725 857.658 308.958 861.488 305.809 861.81C304.632 861.834 303.447 861.566 302.369 861.03C294.843 857.961 287.678 854.046 281.038 849.374"
          stroke="#2C2B2A"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M257.893 774.352C257.893 774.352 230.294 791.444 218.019 798.322C216.155 799.229 214.521 800.484 213.206 802.017C212.556 802.792 212.147 803.732 212.02 804.742C211.892 805.752 212.05 806.796 212.478 807.769C213.315 809.097 214.484 810.223 215.871 811.036C221.371 814.898 234.993 822.179 234.993 822.179"
          fill="#0083CC"
        />
        <path
          d="M257.893 774.352C257.893 774.352 230.294 791.444 218.019 798.322C216.155 799.229 214.521 800.484 213.206 802.017C212.556 802.792 212.147 803.732 212.02 804.742C211.892 805.752 212.05 806.796 212.478 807.769C213.315 809.097 214.484 810.223 215.871 811.036C221.371 814.898 234.993 822.179 234.993 822.179"
          stroke="#2C2B2A"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M230.481 814.343C230.59 808.429 235.024 802.02 237.918 796.386C242.204 788.201 246.744 780.155 251.538 772.246C280.581 724.355 318.507 682.171 363.652 647.543C367.299 644.759 373.074 642.128 376.47 645.682C378.405 647.733 378.305 650.679 378.006 653.287C374.227 685.476 361.322 715.435 347.53 744.439C330.39 780.457 311.595 815.883 287.457 847.801C286.245 849.406 281.67 848.974 279.721 847.917L238.156 825.363C232.357 822.21 230.42 818.407 230.481 814.343Z"
          fill="#DBEEF7"
          stroke="#2C2B2A"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M327.071 713.348L306.512 703.861L291.598 723.028L315.251 734.166L339.181 749.325L347.846 727.379L327.071 713.348Z"
          fill="#743C7A"
        />
        <path
          d="M277.344 797.76C269.754 811.04 252.359 836.281 252.359 836.281"
          stroke="#2C2B2A"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M269.938 851.889L265.562 864.831C265.485 865.2 265.261 865.517 264.934 865.719C264.718 865.772 264.488 865.777 264.261 865.735C264.035 865.692 263.818 865.603 263.626 865.473L224.976 846.534L236.503 833.527"
          fill="#D44C3A"
        />
        <path
          d="M269.938 851.889L265.562 864.831C265.485 865.2 265.261 865.517 264.934 865.719C264.718 865.772 264.488 865.777 264.261 865.735C264.035 865.692 263.818 865.603 263.626 865.473L224.976 846.534L236.503 833.527"
          stroke="#2C2B2A"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M250.427 889.824C250.018 885.485 249.843 881.146 249.902 876.822"
          stroke="#2C2B2A"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M228.81 899.106C229.865 890.148 232.012 881.401 235.211 873.032"
          stroke="#2C2B2A"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M224.751 862.907C219.77 868.772 213.823 873.819 207.112 877.876"
          stroke="#2C2B2A"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M348.887 726.064C336.886 714.624 321.835 706.497 305.573 702.676"
          stroke="#2C2B2A"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M339.221 751.475C324.316 739.883 307.536 730.71 289.644 724.373"
          stroke="#2C2B2A"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1467.12 196.871C1446.06 206.931 1441.7 199.199 1458.02 184.952C1441.08 170.607 1446.51 163.005 1467.32 171.691C1475.79 148.99 1483.5 150.721 1483.54 174.773C1505.39 174.303 1506.76 182.769 1484.06 190.436C1486.18 216.502 1481.96 214.696 1467.12 196.871Z"
          stroke="black"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M489.117 956.871C468.057 966.931 463.704 959.2 480.018 944.952C463.083 930.607 468.509 923.005 489.321 931.691C497.794 908.99 505.498 910.721 505.539 934.773C527.393 934.303 528.761 942.769 506.062 950.436C508.184 976.503 503.96 974.697 489.117 956.871Z"
          stroke="black"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M385.117 126.871C364.057 136.931 359.704 129.199 376.018 114.952C359.083 100.607 364.509 93.0047 385.321 101.691C393.794 78.9898 401.498 80.7207 401.539 104.773C423.393 104.303 424.761 112.769 402.062 120.436C404.184 146.502 399.96 144.696 385.117 126.871Z"
          stroke="black"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1638.12 473.871C1617.06 483.93 1612.7 476.199 1629.02 461.952C1612.08 447.607 1617.51 440.004 1638.32 448.691C1646.79 425.99 1654.5 427.72 1654.54 451.773C1676.39 451.303 1677.76 459.769 1655.06 467.436C1657.18 493.502 1652.96 491.696 1638.12 473.871Z"
          stroke="black"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M169.117 427.872C148.057 437.932 143.704 430.201 160.018 415.954C143.083 401.608 148.509 394.006 169.321 402.692C177.794 379.991 185.498 381.722 185.539 405.775C207.393 405.305 208.761 413.77 186.062 421.437C188.184 447.504 183.96 445.698 169.117 427.872Z"
          stroke="black"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_251_19310">
          <rect width="1700" height="1080" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
});

export default LandingIllustration1;
