import React from 'react';
import {
  Flex,
  useIsMobileView,
  Text,
  TextProps,
  typography,
  color,
  Link,
} from 'deepstash-ui';
import { METATOPICS } from 'utils/metatopics.constants';
import { MetaTopic } from 'types';
import NextImage from 'src/components/image/NextImage';
import NextLink from 'src/components/navigation/NextLink';
import { formatStringToHashtagCase } from 'utils/global';

const TITLE = 'What are you interested in?';
const SUBTITLE =
  'Over 200,000+ of ideas from books, podcasts, articles, and more.';
const TOPIC_IMAGE_SIZE = 24;

interface LandingTopicsSectionProps {
  topicChipTextProps?: TextProps;
}

const LandingTopicsSection: React.FC<LandingTopicsSectionProps> = ({
  topicChipTextProps,
}) => {
  const isMobileView = useIsMobileView();

  const renderTopic = ({ metaTopic }: { metaTopic: MetaTopic }) => {
    const formatTopic = formatStringToHashtagCase(metaTopic.name);
    return (
      <NextLink href={`/topic/${formatTopic}`} passHref>
        <Link _focus={undefined} _hover={undefined} title={metaTopic.name}>
          <Flex
            key={`topic-${metaTopic.id}`}
            bgColor={color.light.top}
            px={16}
            py={8}
            borderRadius={20}
            gridColumnGap={8}
            alignItems="center"
            _hover={{
              cursor: 'pointer',
              boxShadow:
                '0px 6px 14px -6px rgba(0, 0, 0, 0.12), 0px 10px 32px -4px rgba(0, 0, 0, 0.1)',
            }}
          >
            <NextImage
              wrapperStyle={{
                width: TOPIC_IMAGE_SIZE,
                height: TOPIC_IMAGE_SIZE,
                pointerEvents: 'none',
              }}
              objectFit="contain"
              imageUrl={metaTopic.image}
              priority={true}
            />
            <Text
              color={color.light.text}
              noOfLines={1}
              {...typography.primaryTextFonts.medium.regular}
              {...topicChipTextProps}
            >
              {metaTopic.name}
            </Text>
          </Flex>
        </Link>
      </NextLink>
    );
  };

  const renderTopicRow = ({
    startIndex,
    endIndex,
  }: {
    startIndex: number;
    endIndex: number;
  }) => {
    return (
      <Flex gridColumnGap={8} maxWidth={'100%'} justifyContent="center">
        {METATOPICS.slice(startIndex, endIndex).map(metaTopic => {
          return renderTopic({ metaTopic: metaTopic });
        })}
      </Flex>
    );
  };

  return (
    <Flex
      paddingX={{ base: 16, md: 0 }}
      flexDirection="column"
      alignItems="center"
      mt={{ sm: 48, md: 128 }}
      overflow="hidden"
    >
      <Text
        {...typography.primaryHeadingFonts.large.semiBold}
        color={color.light.text}
        textAlign="center"
      >
        {TITLE}
      </Text>
      <Text
        {...typography.primaryTextFonts.large.regular}
        color={color.light.textSecondary}
        textAlign="center"
        mt={8}
      >
        {SUBTITLE}
      </Text>
      {isMobileView ? (
        <Flex flexDirection="column" gridRowGap={8} mt={32}>
          {renderTopicRow({ startIndex: 0, endIndex: 2 })}
          {renderTopicRow({ startIndex: 2, endIndex: 4 })}
          {renderTopicRow({ startIndex: 4, endIndex: 6 })}
          {renderTopicRow({ startIndex: 6, endIndex: 8 })}
          {renderTopicRow({ startIndex: 8, endIndex: 10 })}
          {renderTopicRow({ startIndex: 10, endIndex: 12 })}
          {renderTopicRow({ startIndex: 12, endIndex: 14 })}
          {renderTopicRow({ startIndex: 14, endIndex: 16 })}
          {renderTopicRow({ startIndex: 16, endIndex: 18 })}
          {renderTopicRow({ startIndex: 18, endIndex: 20 })}
          {renderTopicRow({ startIndex: 20, endIndex: 22 })}
        </Flex>
      ) : (
        <Flex flexDirection="column" gridRowGap={8} mt={32} pb={24}>
          {renderTopicRow({ startIndex: 0, endIndex: 4 })}
          {renderTopicRow({ startIndex: 4, endIndex: 9 })}
          {renderTopicRow({ startIndex: 9, endIndex: 13 })}
          {renderTopicRow({ startIndex: 13, endIndex: 18 })}
          {renderTopicRow({ startIndex: 18, endIndex: 22 })}
        </Flex>
      )}
    </Flex>
  );
};

export default LandingTopicsSection;
