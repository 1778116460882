import { StyleProps } from 'deepstash-ui';
import useAuth from 'providers/hooks/useAuth';
import React from 'react';
import { PageMetadata } from 'types/metadataTypes';
import { GAP_SIZE_REM } from 'utils/global';
import GetTheAppBanner from '../banner/GetTheAppBanner';
import SignUpBanner from '../banner/SignUpBanner';
import Layout from './Layout';
import { LayoutSection } from './Layout.types';

export interface LayoutAdapterProps extends StyleProps {
  children: React.ReactNode;

  pageMetadata: PageMetadata;
  childWrapperDataTestId?: string;
  backgroundColor?: string;
  withNavbar?: boolean;
  withFooter?: boolean;
  withFadingGradient?: boolean;
  /**
   * Whether or not this page should render AdditionalInfoNsi in the Navbar for desktop mode when the user is not logged in
   * @default False
   */
  withAdditionalInfoNsi?: boolean;
  /**
   * We can pass a custom link (and title) to tell the in-page back
   * button to point to a custom location rather than the one
   * found in the browser's history stack.
   */
  withCustomBackButtonData?: {
    pageTitle: string;
    pageLink: string;
  };
  hasInfiniteScroll?: boolean;
  /**
   * @default True
   */
  showMetacontentBanner?: boolean;
  beforeSection?: LayoutSection;
  disableNewsletter?: boolean;
  disableProAd?: boolean;
}

const LayoutAdapter: React.FC<LayoutAdapterProps> = ({
  children,
  pageMetadata,
  hasInfiniteScroll,
  beforeSection,
  showMetacontentBanner = true,
  disableNewsletter,
  disableProAd,
}) => {
  const { isLoggedIn } = useAuth();

  const metaContent = !isLoggedIn ? (
    <SignUpBanner size="sm" mb={{ base: GAP_SIZE_REM, md: 0 }} />
  ) : (
    <GetTheAppBanner />
  );

  const mainLayoutSection: LayoutSection = {
    content: children,
    infiniteScroll: hasInfiniteScroll,
    metaContent: showMetacontentBanner ? metaContent : undefined,
    metaContentStickyOffset: 32,
  };

  return (
    <Layout
      pageMetadata={pageMetadata}
      sections={
        beforeSection ? [beforeSection, mainLayoutSection] : [mainLayoutSection]
      }
      disableNewsletter={disableNewsletter}
      disableProAd={disableProAd}
    />
  );
};

export default LayoutAdapter;
