import React from 'react';
import {
  Flex,
  useIsMobileView,
  Text,
  color,
  typography,
  useDeviceDetect,
  useColorMode,
} from 'deepstash-ui';
import StoreRatingCard from '../StoreRatingCard';
import { LANDING_PAGE_REVIEWS } from 'utils/landingPageData';
import ReviewCard from 'src/components/cards/ReviewCard';

const TITLE = 'What our members say';
const NUMBER_OF_REVIEWS_ON_A_SIDE = 3;

interface LandingReviewsSectionProps {}

const LandingReviewsSection: React.FC<LandingReviewsSectionProps> = ({}) => {
  const isMobileView = useIsMobileView();
  const { isIos } = useDeviceDetect();
  const { colorMode } = useColorMode();

  const reviewsLeft = React.useMemo(
    () =>
      LANDING_PAGE_REVIEWS.filter(review => review.platform === 'ios')
        // .sort(() => 0.5 - Math.random())
        .slice(0, NUMBER_OF_REVIEWS_ON_A_SIDE),
    [isIos, isMobileView],
  );

  const reviewsRight = React.useMemo(
    () =>
      LANDING_PAGE_REVIEWS.filter(
        r => !reviewsLeft.map(r => r.id).includes(r.id),
      )
        .filter(r => r.platform === 'android')
        // .sort(() => 0.5 - Math.random())
        .slice(0, NUMBER_OF_REVIEWS_ON_A_SIDE),
    [reviewsLeft],
  );

  return (
    <Flex
      paddingX={{ base: 16, md: 0 }}
      flexDirection="column"
      mt={{ sm: 48, md: 88 }}
    >
      <Text
        {...typography.primaryHeadingFonts.large.semiBold}
        color={color.light.text}
        textAlign="center"
      >
        {TITLE}
      </Text>
      {isMobileView ? (
        <Flex>
          <StoreRatingCard
            store={isIos ? 'ios' : 'android'}
            flexGrow={1}
            flexShrink={0}
            width="auto"
            height="auto"
          />
        </Flex>
      ) : (
        <Flex flexDirection={{ sm: 'column', md: 'row', lg: 'row' }}>
          <StoreRatingCard
            store="ios"
            flexGrow={1}
            flexShrink={0}
            width="auto"
            height="auto"
          />
          <StoreRatingCard
            store="android"
            flexGrow={1}
            flexShrink={0}
            width="auto"
            height="auto"
          />
        </Flex>
      )}
      {isMobileView ? (
        isIos ? (
          <Flex flexDirection="column" flex={1} gridRowGap={16}>
            {reviewsLeft.map(review => (
              <ReviewCard
                review={review}
                key={review.id}
                bgColor={color[colorMode].surface}
              />
            ))}
          </Flex>
        ) : (
          <Flex flexDirection="column" flex={1} gridRowGap={16}>
            {reviewsRight.map(review => (
              <ReviewCard
                review={review}
                key={review.id}
                bgColor={color[colorMode].surface}
              />
            ))}
          </Flex>
        )
      ) : (
        <Flex flex={1} flexDirection="row" gridColumnGap={32}>
          <Flex flexDirection="column" flex={1} gridRowGap={16}>
            {reviewsLeft.map(review => (
              <ReviewCard
                review={review}
                key={review.id}
                bgColor={color[colorMode].surface}
              />
            ))}
          </Flex>
          <Flex flexDirection="column" flex={1} gridRowGap={16}>
            {reviewsRight.map(review => (
              <ReviewCard
                review={review}
                key={review.id}
                bgColor={color[colorMode].surface}
              />
            ))}
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default LandingReviewsSection;
