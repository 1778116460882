import {
  Button,
  Flex,
  FlexProps,
  PrimaryText,
  Text,
  useColorMode,
  spacing,
} from 'deepstash-ui';
import { color } from 'deepstash-ui';
import Cookie from 'js-cookie';
import React from 'react';
import { typography } from 'theme';
import { BORDER_RADIUS_L, CONTENT_PADDING_M } from 'theme/size';
import jsCookie from 'js-cookie';
import * as gtag from 'utils/analytics/gtag';

const trackingState = {
  hasAgreed: !!jsCookie.get('cookieConsent'),
};

interface OwnProps extends FlexProps {
  setHasAgreed: (_: boolean) => void;
  onClose: () => void;
}

const CookieAgreementToast: React.FC<OwnProps> = ({
  setHasAgreed,
  onClose,
  ...props
}) => {
  const { colorMode } = useColorMode();

  const onAgree = () => {
    Cookie.set('cookieConsent', 'agreement', {
      expires: 365,
      sameSite: 'lax',
      secure:
        process.env.NODE_ENV === 'production' &&
        window.location.hostname !== 'localhost',
    });
    setHasAgreed(true);
    if (window && (window as any).fbq) {
      (window as any).fbq('consent', 'grant');
      (window as any).fbq('track', 'PageView');
    }
    if (window) {
      gtag.grantConsent();
      gtag.pageview(window.location.pathname);
    }
    trackingState.hasAgreed = true;
    onClose();
  };

  return (
    <Flex
      color={color[colorMode].text}
      align="center"
      backgroundColor={color[colorMode].top}
      borderRadius={32}
      mb={{ base: spacing.toRem(80), md: spacing.toRem(96) }}
      shadow="4px"
      p={`${CONTENT_PADDING_M}px`}
      w={{ base: 'calc(100vw - 32px)', md: 'max-content' }}
      maxW={{ base: 'unset', md: '60vw' }}
      {...props}
    >
      <Text
        {...typography.ideaBody}
        textAlign="left"
        px={`${CONTENT_PADDING_M}px`}
      >
        To make Deepstash work, we log user data. By using us, you agree to our{' '}
        <a
          style={{
            color: color[colorMode].primary.default,
            textDecoration: 'underline',
          }}
          href="/privacy-policy"
        >
          Privacy Policy
        </a>
        , including cookie policy.
      </Text>
      <Button
        borderRadius={BORDER_RADIUS_L}
        size="lg"
        onClick={onAgree}
        _hover={{ backgroundColor: color[colorMode].primary.default }}
        _active={{ backgroundColor: color[colorMode].primary.darker }}
        _focus={undefined}
        h="40px"
        w={{ base: spacing.toRem(200), lg: spacing.toRem(160) }}
        backgroundColor={color.success}
        color={color[colorMode].top}
        fontSize="14px"
      >
        <PrimaryText
          size="s"
          display="flex"
          flexDir="row"
          alignContent="center"
          type="bold"
        >
          I Agree
        </PrimaryText>
      </Button>
    </Flex>
  );
};

export default CookieAgreementToast;
