import {
  color,
  Flex,
  FlexProps,
  Heading,
  Text,
  typography,
  useColorMode,
  useIsMobileView,
} from 'deepstash-ui';
import React from 'react';
import AnimateInView from 'src/components/animation/AnimateInView';
import UnderlineMarkerDrawing from 'src/components/text-decorations/UnderlineMarkerDrawing';

interface SectionHeaderProps {
  title: string;
  preTitle?: string;
  titleColor?: string;
  preTitleColor?: string;
  underlineColor?: string;
  headingSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  showUnderline?: boolean;
}

const SectionHeader: React.FC<SectionHeaderProps & FlexProps> = ({
  title,
  preTitle,
  titleColor,
  preTitleColor,
  underlineColor,
  headingSize,
  showUnderline = true,
  ...props
}) => {
  const { colorMode } = useColorMode();
  const isMobileView = useIsMobileView();

  const titleComponent = headingSize ? (
    <Heading
      size={headingSize}
      zIndex={1}
      fontSize={{ base: '2.5rem', md: '4rem' }}
      color={(titleColor as string) ?? color[colorMode].text}
      textAlign="center"
      fontWeight={900}
    >
      {title}
    </Heading>
  ) : (
    <Text
      zIndex={1}
      fontSize={{ base: '2.5rem', md: '4rem' }}
      color={(titleColor as string) ?? color[colorMode].text}
      textAlign="center"
      fontWeight={900}
    >
      {title}
    </Text>
  );

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      w="100%"
      {...props}
    >
      <AnimateInView
        variants={{
          visible: { opacity: 1, translateY: 0 },
          hidden: { opacity: 0, scale: 1, translateY: 32 },
        }}
      >
        <Text
          {...(isMobileView
            ? typography.primaryHeadingFonts.medium.bold
            : typography.primaryHeadingFonts.large.bold)}
          color={(preTitleColor as string) ?? color[colorMode].textSecondary}
          textAlign="center"
        >
          {preTitle}
        </Text>
      </AnimateInView>
      <AnimateInView
        variants={{
          visible: { opacity: 1, translateY: 0 },
          hidden: { opacity: 0, scale: 1, translateY: 32 },
        }}
      >
        <Flex position="relative">
          {titleComponent}
          {showUnderline && (
            <UnderlineMarkerDrawing
              position="absolute"
              top={{ base: '2rem', md: '3rem' }}
              left={0}
              height="35%"
              zIndex={0}
              fill={underlineColor}
            />
          )}
        </Flex>
      </AnimateInView>
    </Flex>
  );
};

export default SectionHeader;
