import {
  Box,
  color,
  Flex,
  spacing,
  StyleProps,
  useColorMode,
  useIsDesktopView,
  useIsMobileView,
} from 'deepstash-ui';
import LayoutProvider from 'providers/layout/LayoutProvider';
import React, { ReactNode } from 'react';
import { BreadcrumbLinkItem, PageMetadata } from 'types';
import { GAP_SIZE_REM, getRemWidthFromColumns } from 'utils/global';
import Footer from '../layout/components/Footer';
import { useLayoutInternals } from '../layout/hooks/useLayoutInternals';
import PublicLinksSection from '../metacontent/sections/PublicLinksSection';
import HeadMetadata from '../metadata/HeadMetadata';
import Navbar from '../navbar/Navbar';

import ProAdSection from '../page-sections/pro-benefits/ProAdSection';
import FullWidthSectionWrapper from './components/FullWidthSectionWrapper';
import { LayoutMetaContentPosition, LayoutSection } from './Layout.types';
import SpecialOfferBannerWrapper from 'src/page-components/special-offer/SpecialOfferBannerWrapper';
import BreadcrumbList from '../breadcrumbs/BreadcrumbList';

export interface LayoutProps {
  pageMetadata: PageMetadata;
  sections: LayoutSection[];
  firstSection?: React.ReactNode;
  disableNewsletter?: boolean;
  disableProAd?: boolean;
  disableSaleBanner?: boolean;
  customNavbar?: JSX.Element;
  shouldHideFooter?: boolean;
  isShortProAd?: boolean;
  showFooterPrices?: boolean;
  breadcrumbs?: BreadcrumbLinkItem[];
}

const Layout: React.FC<LayoutProps> = ({
  pageMetadata,
  sections,
  firstSection,
  disableNewsletter,
  disableProAd,
  disableSaleBanner,
  customNavbar,
  shouldHideFooter = false,
  isShortProAd,
  showFooterPrices,
  breadcrumbs,
}) => {
  const isDesktopView = useIsDesktopView();
  const isMobileView = useIsMobileView();
  const { colorMode } = useColorMode();

  const {
    metaContentMeasurements,
    measureContent,
    measureMetaContent,
    measureSection,
    metaContentPositions,
    sectionDisplayedHeights,
    remeasureLayout,
  } = useLayoutInternals(sections);

  const renderMetaContent = ({
    metaContentPosition,
    metaContentTopOffset,
    metaContentColumnStart = 8,
    metaContentStickyOffset,
    metaContentBottomPosition,
    sectionIndex,
    metaContentWrapperStyle,
    metaContent,
  }: {
    metaContentPosition: LayoutMetaContentPosition;
    metaContentTopOffset?: number;
    metaContentColumnStart?: number;
    metaContentStickyOffset?: number;
    metaContentBottomPosition: number;
    sectionIndex: number;
    metaContentWrapperStyle?: StyleProps;
    metaContent?: ReactNode;
  }) => {
    return (
      <Flex
        w={{
          base: 'unset',
          lg: getRemWidthFromColumns(4),
        }}
        pos={{
          base: 'unset',
          lg: metaContentPosition === 'fixed' ? 'fixed' : 'absolute',
        }}
        top={
          metaContentPosition === 'fixed'
            ? spacing.toRem(metaContentStickyOffset ?? 0)
            : metaContentPosition === 'top'
            ? spacing.toRem(metaContentTopOffset ?? 0)
            : spacing.toRem(metaContentBottomPosition)
        }
        left={
          metaContentPosition === 'fixed'
            ? `calc(50vw + ${getRemWidthFromColumns(
                metaContentColumnStart - 6 + 0.25,
              )})`
            : `calc(${getRemWidthFromColumns(
                metaContentColumnStart,
              )} + ${GAP_SIZE_REM})`
        }
        ref={
          isMobileView ? undefined : el => measureMetaContent(el, sectionIndex)
        }
        flexDirection="column"
        minH={
          sections[sectionIndex].infiniteScroll && metaContent && isDesktopView
            ? `calc(100vh - ${metaContentStickyOffset}px)`
            : 'auto'
        }
        {...metaContentWrapperStyle}
      >
        {metaContent}
        {sections[sectionIndex].infiniteScroll &&
          metaContent &&
          isDesktopView && (
            <PublicLinksSection
              position="fixed"
              bottom={0}
              width={{ base: '100%', md: getRemWidthFromColumns(4) }}
              pt={spacing.XS.rem}
            />
          )}
      </Flex>
    );
  };

  const renderSection = (
    {
      content,
      metaContent,
      metaContentStickyOffset,
      metaContentTopOffset,
      metaContentColumnStart,
      metaContentWrapperStyle,
      sectionStyle,
      contentWrapperStyle,
      infiniteScroll,
      isFullscreen,
    }: LayoutSection,
    sectionIndex: number,
  ) => {
    const sectionHeight = sectionDisplayedHeights[sectionIndex];

    const metaContentPosition = metaContentPositions[sectionIndex];

    // Align the metaContent at the bottom of the section
    const metaContentBottomPosition =
      sectionHeight - metaContentMeasurements[sectionIndex].height;

    return (
      //Section root
      <Box
        pos="relative"
        key={sectionIndex}
        height={{
          base: 'unset',
          lg: sectionHeight === 0 ? 'unset' : sectionHeight,
        }}
        ref={isMobileView ? undefined : el => measureSection(el, sectionIndex)}
        {...sectionStyle}
      >
        {/*Content area*/}
        <Box
          w={{
            base: '100%',
            md: isFullscreen ? '100%' : getRemWidthFromColumns(8),
          }}
        >
          {/*Meta content for mobile/tablet infinite list section*/}

          {!isDesktopView &&
            infiniteScroll &&
            renderMetaContent({
              metaContentStickyOffset,
              metaContentBottomPosition,
              metaContentColumnStart,
              sectionIndex,
              metaContentPosition,
              metaContent,
              metaContentTopOffset,
              metaContentWrapperStyle,
            })}
          {/*  Content-Element */}
          <Box
            ref={
              isMobileView ? undefined : el => measureContent(el, sectionIndex)
            }
            {...contentWrapperStyle}
          >
            {content}
          </Box>
        </Box>
        {/*  MetaContent*/}
        {/*We set the gridColumn to be used on lower tha desktop screen size*/}
        {(isDesktopView || !infiniteScroll) &&
          renderMetaContent({
            metaContentStickyOffset,
            metaContentBottomPosition,
            metaContentColumnStart,
            sectionIndex,
            metaContentPosition,
            metaContent,
            metaContentTopOffset,
            metaContentWrapperStyle,
          })}{' '}
      </Box>
    );
  };

  return (
    <LayoutProvider remeasureLayoutCallback={remeasureLayout}>
      {/* Box used to ensure background color consistency across themes */}
      <Box
        position="absolute"
        w="100%"
        h="100%"
        zIndex={-9999}
        backgroundColor={color[colorMode].background}
      />

      <HeadMetadata pageMetadata={pageMetadata} />

      <FullWidthSectionWrapper
        containerProps={
          breadcrumbs
            ? {
                flexDirection: 'column',
                gridGap: 0,
                marginBottom: GAP_SIZE_REM,
              }
            : {}
        }
      >
        {customNavbar ?? <Navbar />}
        {breadcrumbs && <BreadcrumbList items={breadcrumbs} />}
      </FullWidthSectionWrapper>

      <FullWidthSectionWrapper>
        {firstSection ? firstSection : null}
      </FullWidthSectionWrapper>

      {sections.map((section, index) =>
        section.isFullscreen ? (
          renderSection(section, index)
        ) : (
          <FullWidthSectionWrapper key={index}>
            {renderSection(section, index)}
          </FullWidthSectionWrapper>
        ),
      )}

      {!disableProAd && !sections[sections.length - 1].infiniteScroll && (
        <ProAdSection
          mt={spacing.toRem(64)}
          pb={spacing.toRem(64)}
          isShort={isShortProAd}
          showPrices={showFooterPrices}
        />
      )}

      {!shouldHideFooter &&
        (isMobileView || !sections[sections.length - 1].infiniteScroll) && (
          <Footer
            disableNewsletter={disableNewsletter}
            disableStickyStartTrial={true}
          />
        )}

      {!disableSaleBanner && <SpecialOfferBannerWrapper />}
    </LayoutProvider>
  );
};

export default Layout;
