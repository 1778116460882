import {
  Box,
  BoxProps,
  color,
  Flex,
  spacing,
  useColorMode,
  useDeviceDetect,
  useIsMobileView,
} from 'deepstash-ui';
import React, { useMemo } from 'react';
import AnimateInView from 'src/components/animation/AnimateInView';
import ReviewCard from 'src/components/cards/ReviewCard';
import FullWidthSectionWrapper from 'src/components/layout/components/FullWidthSectionWrapper';
import SectionHeader from 'src/components/layout/components/SectionHeader';
import StoreRatingCard from 'src/page-components/landing/StoreRatingCard';
import {
  GAP_SIZE_REM,
  getRemWidthFromColumns,
  SECTION_GAP_REM,
} from 'utils/global';
import { LANDING_PAGE_REVIEWS } from 'utils/landingPageData';
import pageStrings from 'utils/strings/pageStrings';

const NUMBER_OF_REVIEWS_ON_A_SIDE = 4;

const ProTestimonialsSection: React.FC<BoxProps> = ({ ...props }) => {
  const isMobileView = useIsMobileView();
  const { isIos } = useDeviceDetect();
  const { colorMode } = useColorMode();

  const reviewsLeft = useMemo(
    () =>
      LANDING_PAGE_REVIEWS.filter(
        review =>
          review.platform === (isIos || !isMobileView ? 'ios' : 'android'),
      )
        .sort(() => 0.5 - Math.random())
        .slice(0, NUMBER_OF_REVIEWS_ON_A_SIDE),
    [isIos, isMobileView],
  );

  const reviewsRight = useMemo(
    () =>
      LANDING_PAGE_REVIEWS.filter(
        r => !reviewsLeft.map(r => r.id).includes(r.id),
      )
        .filter(r => r.platform !== 'ios')
        .sort(() => 0.5 - Math.random())
        .slice(0, NUMBER_OF_REVIEWS_ON_A_SIDE),
    [reviewsLeft],
  );

  return (
    <Box w="100%" {...props}>
      <FullWidthSectionWrapper>
        <SectionHeader
          preTitle={pageStrings.landing.testimonialsHeader.preTitle}
          title={pageStrings.landing.testimonialsHeader.title}
          underlineColor={color[colorMode].secondary.default}
        />
      </FullWidthSectionWrapper>
      <AnimateInView
        variants={{
          visible: { opacity: 1, translateY: 0 },
          hidden: { opacity: 0, scale: 1, translateY: 32 },
        }}
      >
        <FullWidthSectionWrapper
          containerProps={{
            gridGap: GAP_SIZE_REM,
            alignItems: 'center',
            justifyContent: 'space-evenly',
            paddingY: GAP_SIZE_REM,
          }}
        >
          {((isIos && isMobileView) || !isMobileView) && (
            <StoreRatingCard
              store="ios"
              flexGrow={1}
              flexShrink={0}
              width="auto"
              height="auto"
              w={{ base: '100%', md: getRemWidthFromColumns(4) }}
            />
          )}
          {!isMobileView && (
            <Box
              width={spacing.toRem(2)}
              height={spacing.toRem(96)}
              backgroundColor={color[colorMode].underground}
            />
          )}
          {((!isIos && isMobileView) || !isMobileView) && (
            <StoreRatingCard
              store="android"
              flexGrow={1}
              flexShrink={0}
              width="auto"
              height="auto"
              w={{ base: '100%', md: getRemWidthFromColumns(4) }}
            />
          )}
        </FullWidthSectionWrapper>
        <FullWidthSectionWrapper
          mb={{
            base: GAP_SIZE_REM,
            md: SECTION_GAP_REM,
          }}
          containerProps={{
            alignItems: 'center',
            flexDirection: { base: 'column', md: 'row' },
          }}
        >
          <Flex
            gridGap={GAP_SIZE_REM}
            justifyContent="center"
            width={{
              base: '100%',
              md: getRemWidthFromColumns(4),
              lg: getRemWidthFromColumns(6),
            }}
            flexDirection="column"
          >
            {reviewsLeft.map(review => (
              <ReviewCard review={review} key={review.id} />
            ))}
          </Flex>
          {!isMobileView && (
            <Flex
              gridGap={GAP_SIZE_REM}
              justifyContent="center"
              width={{
                base: '100%',
                md: getRemWidthFromColumns(4),
                lg: getRemWidthFromColumns(6),
              }}
              flexDirection="column"
            >
              {reviewsRight.map(review => (
                <ReviewCard review={review} key={review.id} />
              ))}
            </Flex>
          )}
        </FullWidthSectionWrapper>
      </AnimateInView>
    </Box>
  );
};

export default ProTestimonialsSection;
