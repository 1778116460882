import React from 'react';
import {
  Flex,
  useColorMode,
  useIsMobileView,
  Text,
  color,
  typography,
  Checkbox,
  PrimaryButton,
  StyleProps,
} from 'deepstash-ui';
import useRouter from 'hooks/useRouter';
import { SETUP_ACHIEVEMENT_GOALS_QUESTION } from 'src/page-components/setup/setup-likes.constants';
import { Analytics, Events } from 'services/analytics';
import useNsi from 'providers/hooks/useNsi';

// List of indexes of goals that are shown on the homepage
const GOAL_WHITELIST = [0, 1, 2, 3];

interface LandingGoalQuestionProps {}

const LandingGoalQuestion: React.FC<LandingGoalQuestionProps & StyleProps> = ({
  ...props
}) => {
  const isMobileView = useIsMobileView();
  const { colorMode } = useColorMode();
  const router = useRouter();
  const { nsiDispatch } = useNsi();

  const [checkedIndexes, setCheckedIndexes] = React.useState<number[]>([]);

  const onClickOption = ({ index }: { index: number }) => {
    setCheckedIndexes(prevCheckedOptions => {
      const findOption = prevCheckedOptions.find(
        prevSelectedOption => prevSelectedOption === index,
      );
      if (findOption === undefined) {
        return [...prevCheckedOptions, index];
      } else {
        return prevCheckedOptions.filter(
          prevSelectedOption => prevSelectedOption !== index,
        );
      }
    });
  };

  const onClickContinue = () => {
    const achievements = checkedIndexes.map(
      optionIndex => SETUP_ACHIEVEMENT_GOALS_QUESTION.options[optionIndex],
    );
    Analytics.logEvent({
      eventName: Events.onboarding.clickContinueGoalLanding,
      properties: { goals: achievements.map(achievement => achievement.title) },
      platforms: ['amplitude'],
    });

    nsiDispatch({
      type: 'set-goal',
      payload: { setupGoal: achievements[0].title ?? '' },
    });
    router.push('/setup');
  };

  return (
    <Flex flexDirection="column" gridRowGap={4} maxW={440} {...props}>
      <Text
        maxW={450}
        {...typography.primaryHeadingFonts.small.semiBold}
        color={color[colorMode].text}
        mb={8}
      >
        {'What brings you to Deepstash?'}
      </Text>
      <Flex
        flexDirection="column"
        gridRowGap={4}
        borderRadius={8}
        overflow="hidden"
      >
        {SETUP_ACHIEVEMENT_GOALS_QUESTION.options.map((goal, index) => {
          const isSelected =
            checkedIndexes.find(checkedIndex => checkedIndex === index) !==
            undefined;
          return GOAL_WHITELIST.includes(index) ? (
            <Flex
              flexDirection="row"
              alignItems="center"
              key={`goal-${index}`}
              bgColor={
                isSelected ? color[colorMode].top : color[colorMode].surface
              }
              px={8}
              py={8}
              gridColumnGap={4}
              _hover={{
                cursor: 'pointer',
                bgColor: isSelected
                  ? color[colorMode].top
                  : color[colorMode].underground,
              }}
              onClick={() => {
                onClickOption({ index });
              }}
            >
              <Checkbox
                isChecked={isSelected}
                onChange={() => {
                  return;
                }}
                //   userSelect={'none'}
                //   _hover={{ cursor: 'pointer' }}
              />
              <Text
                {...typography.primaryTextFonts.large.regular}
                color={color[colorMode].text}
              >
                {goal.title}
              </Text>
            </Flex>
          ) : null;
        })}
      </Flex>
      <Flex>
        <PrimaryButton
          size="lg"
          type="button"
          borderRadius={16}
          mt={8}
          {...(isMobileView
            ? typography.primaryTextFonts.medium.semiBold
            : typography.primaryTextFonts.large.semiBold)}
          isDisabled={checkedIndexes.length === 0}
          onClick={onClickContinue}
          {...(isMobileView ? { width: '100%' } : {})}
        >
          Continue
        </PrimaryButton>
      </Flex>
    </Flex>
  );
};

export default LandingGoalQuestion;
