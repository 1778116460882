import {
  color,
  Flex,
  FlexProps,
  Link,
  PrimaryHeading,
  spacing,
  useColorMode,
} from 'deepstash-ui';
import React from 'react';
import NextLink from 'src/components/navigation/NextLink';
import { COLUMN_WIDTH, GAP_SIZE } from 'utils/global';
import NextImage from '../image/NextImage';
import BaseCard from './BaseCard';

export interface PreviewCardProps extends FlexProps {
  name: string;
  imageUrl?: string;
  targetUrl?: string;
  onClick?: () => void;
  hasShadow?: boolean;
  /**
   * Default: true
   */
  hasText?: boolean;
}

const PreviewCardSmall: React.FC<PreviewCardProps> = ({
  name,
  imageUrl,
  targetUrl,
  onClick,
  hasShadow,
  hasText = true,
  ...props
}) => {
  const { colorMode } = useColorMode();

  const card = (
    <BaseCard
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
      onClick={targetUrl ? undefined : onClick}
      cursor={targetUrl || onClick ? 'pointer' : 'unset'}
      px={GAP_SIZE}
      gridGap={spacing.XS.rem}
      {...props}
      hasHoverEffect
      boxShadow={
        hasShadow
          ? '0px 6px 16px rgba(0, 0, 0, 0.1); 0px 8px 4px rgba(0, 0, 0, 0.05);'
          : ''
      }
      cardSize="sm"
      bgColor={color[colorMode].surface}
      _hover={{ backgroundColor: color[colorMode].top }}
    >
      <NextImage
        wrapperStyle={{
          width: COLUMN_WIDTH,
          height: COLUMN_WIDTH,
          transition: `0.3s ease`,
        }}
        objectFit="contain"
        imageUrl={imageUrl}
      />
      {hasText && (
        <Flex height="2rem" alignItems="center">
          <PrimaryHeading
            size="h3"
            style={{ fontSize: '1rem', lineHeight: '1rem' }}
            type="bold"
            color={color[colorMode].text}
            noOfLines={2}
            textOverflow="ellipsis"
            width="100%"
            textAlign="center"
          >
            {name}
          </PrimaryHeading>
        </Flex>
      )}
    </BaseCard>
  );

  return targetUrl ? (
    <NextLink href={targetUrl} passHref>
      <Link
        _focus={undefined}
        _hover={undefined}
        title={name}
        onClick={onClick}
      >
        {card}
      </Link>
    </NextLink>
  ) : (
    card
  );
};

export default PreviewCardSmall;
