import React from 'react';
import {
  Flex,
  useColorMode,
  Text,
  typography,
  color,
  PrimaryButton,
  TextInput,
  FlexProps,
  Checkbox,
} from 'deepstash-ui';
import { validateEmail } from 'utils/global';
import { useRouter } from 'next/router';
import { Analytics, Events } from 'services/analytics';
import * as AuthApi from 'api/Auth';

const TITLE = 'Supercharge your mind with one idea per day';
const SUBTITLE =
  'Enter your email and spend 1 minute every day to learn something new.';

const CHECKBOX_LABEL = 'I agree to receive email updates';
const CHECKBOX_ERROR = 'You need to agree to receive updates';

const EMAIL_ERROR = 'Must be a valid email address';

const FINISHED_TITLE = 'Thanks for signing up!';
const FINISHED_SUBTITLE =
  "We'll let you know about new ideas from books, podcasts, and articles.";

interface NewsletterSectionProps {
  containerProps: FlexProps;
}

const NewsletterSection: React.FC<NewsletterSectionProps> = ({
  containerProps,
}) => {
  const { colorMode } = useColorMode();

  const router = useRouter();

  const [newsletterState, setNewsletterState] = React.useState<
    'introduce-email' | 'finished'
  >('introduce-email');

  const [email, setEmail] = React.useState<string | undefined>(undefined);
  const [isAgreeChecked, setIsAgreeChecked] = React.useState(true);

  const [haveError, setHaveError] = React.useState<{
    email: boolean;
    agree: boolean;
  }>({ email: false, agree: false });

  const onChangeEmail = (value: string) => {
    setEmail(value);
  };

  const onChangeCheckbox = (_event: React.FormEvent<HTMLDivElement>) => {
    setIsAgreeChecked(prevValue => !prevValue);
  };

  const submitEmailForNewsletter = () => {
    if (email === undefined) {
      return;
    }
    // POST save in backend
    AuthApi.addEmailToNewsletter({ email: email }).catch(_ => undefined);

    // Send Event to Amplitude
    Analytics.logEvent({
      eventName: Events.newsletter.submitEmailNewsletter,
      properties: { email: email, url: router.asPath },
      platforms: ['amplitude'],
    });

    // Update UI
    setNewsletterState('finished');
  };

  const onClickSubmit = () => {
    setHaveError(_prevValue => {
      const haveEmailError = email === undefined || !validateEmail(email);
      const haveAgreeError = isAgreeChecked === false;

      if (haveEmailError === false && haveAgreeError === false) {
        submitEmailForNewsletter();
        return { email: false, agree: false };
      } else {
        return { email: haveEmailError, agree: haveAgreeError };
      }
    });
  };

  const onKeyPressTextInput = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        onClickSubmit();
      }
    },
    [onClickSubmit],
  );

  const renderErrorMessage = ({ message }: { message: string }) => {
    return (
      <Flex>
        <Text
          {...typography.primaryTextFonts.smaller.regular}
          color={color.failure.default}
        >
          {message}
        </Text>
      </Flex>
    );
  };

  return (
    <>
      {newsletterState === 'introduce-email' ? (
        <Flex
          flexDirection={{ sm: 'column', md: 'column', lg: 'row' }}
          justifyContent="space-between"
          gridColumnGap={16}
          {...containerProps}
        >
          <Flex flexDirection="column" flex={1}>
            <Text
              {...typography.primaryHeadingFonts.small.semiBold}
              color={color[colorMode].text}
              textAlign={{ sm: 'center', md: 'center', lg: 'unset' }}
            >
              {TITLE}
            </Text>
            <Text
              {...typography.primaryTextFonts.medium.regular}
              color={color[colorMode].textSecondary}
              textAlign={{ sm: 'center', md: 'center', lg: 'unset' }}
            >
              {SUBTITLE}
            </Text>
          </Flex>
          <Flex flex={1} flexDirection="column" mt={{ sm: 16, lg: 0 }}>
            <Text
              {...typography.primaryTextFonts.smaller.semiBold}
              color={color[colorMode].text}
            >
              {'Email'}
            </Text>
            <Flex
              flexDirection={{ sm: 'column', md: 'column', lg: 'row' }}
              gridColumnGap={8}
              mt={4}
            >
              <Flex flexDirection="column" flex={1}>
                {/** column 1 */}

                <TextInput
                  flex={1}
                  name="email"
                  placeholder="Enter Email Address"
                  py={8}
                  value={email}
                  onChange={onChangeEmail}
                  onKeyPress={onKeyPressTextInput}
                />
                {haveError.email
                  ? renderErrorMessage({ message: EMAIL_ERROR })
                  : null}
                <Flex flexDirection="column">
                  <Checkbox
                    px={0}
                    isChecked={isAgreeChecked}
                    onChange={onChangeCheckbox}
                    label={
                      <Text
                        {...typography.primaryTextFonts.smaller.regular}
                        color={color[colorMode].text}
                      >
                        {CHECKBOX_LABEL}
                      </Text>
                    }
                  />
                  {haveError.agree
                    ? renderErrorMessage({ message: CHECKBOX_ERROR })
                    : null}
                </Flex>
              </Flex>
              <Flex flexDirection="column">
                {/** column 2 */}
                <PrimaryButton
                  size="sm"
                  type="button"
                  {...typography.primaryTextFonts.medium.semiBold}
                  borderRadius={8}
                  px={48}
                  py={8}
                  onClick={onClickSubmit}
                >
                  {'Submit'}
                </PrimaryButton>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      ) : null}
      {newsletterState === 'finished' ? (
        <Flex flexDirection="column" alignItems="center" {...containerProps}>
          <Text
            {...typography.primaryHeadingFonts.medium.semiBold}
            color={color[colorMode].text}
            textAlign="center"
          >
            {FINISHED_TITLE}
          </Text>
          <Text
            {...typography.primaryTextFonts.medium.regular}
            color={color[colorMode].textSecondary}
            textAlign="center"
          >
            {FINISHED_SUBTITLE}
          </Text>
        </Flex>
      ) : null}
    </>
  );
};

export default NewsletterSection;
