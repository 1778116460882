import React from 'react';
import {
  Flex,
  useColorMode,
  color,
  //   useIsMobileView,
  Text,
  typography,
  ArrowUpSvg,
  StyleProps,
  TextProps,
} from 'deepstash-ui';

export interface FaqItemI {
  title: string | React.ReactChild;
  description: string | React.ReactChild;
}

interface FaqItemProps {
  title: string | React.ReactChild;
  description: string | React.ReactChild;
  isSelected: boolean;
  onClick: () => void;
  titleTextProps?: TextProps;
  descriptionTextProps?: TextProps;
}

const FaqItem: React.FC<FaqItemProps & StyleProps> = ({
  title,
  description,
  isSelected,
  onClick,
  titleTextProps,
  descriptionTextProps,
  ...props
}) => {
  //   const isMobileView = useIsMobileView();
  const { colorMode } = useColorMode();

  return (
    <Flex
      flexDirection="column"
      width={'100%'}
      bgColor={color[colorMode].surface}
      borderRadius={8}
      px={16}
      py={16}
      cursor="pointer"
      _hover={{ backgroundColor: color[colorMode].underground }}
      onClick={onClick}
      {...props}
    >
      <Flex
        flexDirection="row"
        alignItems="center"
        gridColumnGap={8}
        justifyContent="space-between"
      >
        <Text
          {...typography.primaryHeadingFonts.small.semiBold}
          color={color[colorMode].text}
          {...titleTextProps}
        >
          {title}
        </Text>
        <ArrowUpSvg
          flexShrink={0}
          transition="transform 0.3s cubic-bezier(.09,1.72,.43,1)"
          transform={isSelected ? 'rotate(0deg)' : 'rotate(180deg)'}
          color={color[colorMode].text}
        />
      </Flex>
      <Flex
        overflow="hidden"
        // bgColor="red"
        height={'auto'}
        maxHeight={isSelected ? '288px' : 0}
        transition={'all 0.3s cubic-bezier(.02,.98,.23,1.01)'}
      >
        <Text
          {...typography.primaryTextFonts.large.regular}
          color={color[colorMode].textSecondary}
          mt={16}
          {...descriptionTextProps}
        >
          {description}
        </Text>
      </Flex>
    </Flex>
  );
};

export default FaqItem;
