import * as React from 'react';
import { Icon, IconProps, useColorMode } from 'deepstash-ui';

export const UnderlineMarkerDrawing = React.memo<IconProps>(props => {
  const { colorMode } = useColorMode();

  return (
    <Icon
      viewBox={'0 0 53 13'}
      width="100%"
      height="100%"
      preserveAspectRatio="none"
      {...props}
    >
      <path
        opacity={0.6}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.788 2.298c-2.867.448-3.186.783-2.335 2.356.935 1.743-1.847 2.921 3.168 2.705-5.369.423-4.103 1.534-3.378 3.56.926 2.542 6.476 1.838 33.523-.204 8.104-.594 12.62-.197 14.92-.555 3.191-.518 2.359-2.49 1.01-3.315l.726-.279c1.911-.726.663-4.262-1.827-5.13-2.19-.758-14.677-.749-26.686.02-15.885 1.075-15.848.318-19.12.842Z"
        fill={
          (props.fill as string) ??
          (colorMode === 'light' ? '#FFE34C' : '#474733')
        }
      />
    </Icon>
  );
});

export default UnderlineMarkerDrawing;
