import {
  color,
  Flex,
  FlexProps,
  PrimaryHeading,
  Text,
  typography,
  useColorMode,
  useIsMobileView,
} from 'deepstash-ui';
import React from 'react';

export interface SectionTitleProps {
  title: string;
  preTitle?: string;
  postTitle?: string;
}

const SectionTitle: React.FC<SectionTitleProps & FlexProps> = ({
  title,
  preTitle,
  postTitle,
  ...props
}) => {
  const { colorMode } = useColorMode();
  const isMobileView = useIsMobileView();
  return (
    <Flex
      width="100%"
      textAlign={{ base: 'center', md: 'left' }}
      alignItems={{ base: 'center', md: 'left' }}
      justifyContent={{ base: 'center', md: 'left' }}
      {...props}
    >
      <PrimaryHeading size="h2">
        {preTitle && (
          <Text
            {...(isMobileView
              ? typography.primaryHeadingFonts.medium.bold
              : typography.primaryHeadingFonts.large.bold)}
            color={color[colorMode].textSecondary}
          >
            {preTitle}
          </Text>
        )}
        <Text
          fontSize={{ base: '2rem', md: '2.5rem' }}
          fontWeight="bold"
          lineHeight={{ base: '2rem', md: '2.5rem' }}
          color={color[colorMode].text}
        >
          {title}
        </Text>
        {postTitle && (
          <Text
            {...(isMobileView
              ? typography.primaryHeadingFonts.medium.bold
              : typography.primaryHeadingFonts.large.bold)}
            color={color[colorMode].textSecondary}
          >
            {postTitle}
          </Text>
        )}
      </PrimaryHeading>
    </Flex>
  );
};

export default SectionTitle;
