import { Icon, IconProps } from 'deepstash-ui';
import * as React from 'react';

export const PhoneFrame = React.memo<IconProps>(props => {
  return (
    <Icon
      width={props.width ?? props.w ?? 479}
      height={props.height ?? props.h ?? 1014}
      viewBox="0 0 479 1014"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      backgroundColor="unset"
    >
      <rect
        x="8"
        y="8"
        width="463"
        height="998"
        rx="56"
        stroke="#323130"
        strokeWidth="16"
        fill={(props.backgroundColor as string) ?? ''}
      />
      <path
        d="M143 15H335V27.9632C335 39.0292 326.029 48 314.963 48H163.037C151.971 48 143 39.0292 143 27.9632V15Z"
        fill="#323130"
      />
      {/* It kinda looks better without the camera */}
      {/* <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168.985 33.9706C173.395 33.9706 176.971 30.3954 176.971 25.9853C176.971 21.5751 173.395 18 168.985 18C164.575 18 161 21.5751 161 25.9853C161 30.3954 164.575 33.9706 168.985 33.9706Z"
        fill="#605E5C"
      /> */}
      <path
        d="M191 0H287V3.94937C287 5.36722 287 6.07615 286.724 6.6177C286.481 7.09406 286.094 7.48135 285.618 7.72407C285.076 8 284.367 8 282.949 8H195.051C193.633 8 192.924 8 192.382 7.72407C191.906 7.48135 191.519 7.09406 191.276 6.6177C191 6.07615 191 5.36722 191 3.94937V0Z"
        fill="#605E5C"
      />
    </Icon>
  );
});

export default PhoneFrame;
