import {
  AppStoreColoredSvg,
  color,
  Flex,
  GooglePlayColoredSvg,
  PrimaryText,
  spacing,
  StarFullSvg,
  StarHalfSvg,
  StyleProps,
  useColorMode,
  useIsMobileView,
} from 'deepstash-ui';
import React from 'react';
import commonStrings from 'utils/strings/commonStrings';

interface AppStatsProps {
  containersStyle?: StyleProps;
}

const APP_STATS = {
  rating: commonStrings.appScore,
  installs: commonStrings.installCount,
};

const AppStats: React.FC<AppStatsProps & StyleProps> = ({
  containersStyle,
  ...props
}) => {
  const { colorMode } = useColorMode();
  const isMobileView = useIsMobileView();

  return (
    <Flex {...props}>
      <Flex
        alignItems="center"
        flexDir={'column'}
        justifyContent={isMobileView ? 'center' : 'flex-start'}
        {...containersStyle}
      >
        <Flex>
          <GooglePlayColoredSvg />
          <AppStoreColoredSvg />
        </Flex>
        <PrimaryText size="m" type="bold">
          {APP_STATS.installs}
        </PrimaryText>
      </Flex>
      <Flex
        alignItems="center"
        ml={spacing.L.rem}
        flexDir={'column'}
        justifyContent={isMobileView ? 'center' : 'flex-start'}
        {...containersStyle}
      >
        <Flex>
          <StarFullSvg color={color[colorMode].secondary.default} />
          <StarFullSvg color={color[colorMode].secondary.default} />
          <StarFullSvg color={color[colorMode].secondary.default} />
          <StarFullSvg color={color[colorMode].secondary.default} />
          <StarHalfSvg color={color[colorMode].secondary.default} />
        </Flex>
        <PrimaryText type="bold" size="m">
          {APP_STATS.rating}
        </PrimaryText>
      </Flex>
    </Flex>
  );
};

export default AppStats;
