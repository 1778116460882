import React from 'react';
import { Flex, Text, typography, color } from 'deepstash-ui';
import LandingStartTrialButton from './LandingStartTrialButton';

const TITLE = "Explore the World's Best Ideas";
// TODO No Trial Changes
// const SUBTITLE =
//   'Try Deepstash free for 7 days. We’ll remind you about your trial via email. Cancel anytime in 15 seconds.';
const SUBTITLE =
  'Join today and uncover 100+ curated journeys from 50+ topics. Unlock access to our mobile app with extensive features.';

interface LandingStartTrialSectionProps {
  isCentered?: boolean;
}

const LandingStartTrialSection: React.FC<LandingStartTrialSectionProps> = ({
  isCentered,
}) => {
  return (
    <Flex
      px={{ base: 16, md: 0 }}
      mt={{ sm: 48, md: 32 }}
      mb={32}
      justifyContent="space-between"
      gridColumnGap={32}
      gridRowGap={16}
      flexDirection={isCentered ? 'column' : { sm: 'column', md: 'row' }}
    >
      <Flex flexDirection="column" flex={1}>
        <Text
          {...typography.primaryHeadingFonts.large.semiBold}
          color={color.light.text}
          textAlign={isCentered ? 'center' : { sm: 'center', md: 'initial' }}
        >
          {TITLE}
        </Text>
        <Text
          {...typography.primaryTextFonts.large.regular}
          color={color.light.textSecondary}
          textAlign={isCentered ? 'center' : { sm: 'center', md: 'initial' }}
        >
          {SUBTITLE}
        </Text>
      </Flex>
      <Flex flexDirection="column" justifyContent="center">
        <LandingStartTrialButton landingLocation={'start-trial-section'} />
      </Flex>
    </Flex>
  );
};

export default LandingStartTrialSection;
