import { Icon, IconProps } from 'deepstash-ui';
import * as React from 'react';

export const PlayIllustration = React.memo<IconProps>(props => {
  return (
    <Icon
      width={props.width ?? props.w ?? 353}
      height={props.height ?? props.h ?? 56}
      viewBox="0 0 353 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.4">
        <path
          d="M8 22.4141H16.3789V28H8V22.4141ZM16.125 22.4141H24.5039V28H16.125V22.4141ZM24.25 22.4141H32.6289V28H24.25V22.4141ZM48.6758 22.4141H57.0547V28H48.6758V22.4141ZM56.8008 22.4141H65.1797V28H56.8008V22.4141ZM64.9258 22.4141H73.3047V28H64.9258V22.4141ZM73.0508 22.4141H81.4297V28H73.0508V22.4141ZM81.1758 22.4141H89.5547V28H81.1758V22.4141ZM89.3008 22.4141H97.6797V28H89.3008V22.4141ZM113.727 22.4141H122.105V28H113.727V22.4141ZM121.852 22.4141H130.23V28H121.852V22.4141ZM129.977 22.4141H138.355V28H129.977V22.4141ZM138.102 22.4141H146.48V28H138.102V22.4141ZM146.227 22.4141H154.605V28H146.227V22.4141ZM154.352 22.4141H162.73V28H154.352V22.4141ZM178.777 22.4141H187.156V28H178.777V22.4141ZM186.902 22.4141H195.281V28H186.902V22.4141ZM195.027 22.4141H203.406V28H195.027V22.4141ZM203.152 22.4141H211.531V28H203.152V22.4141ZM227.578 22.4141H235.957V28H227.578V22.4141ZM235.703 22.4141H244.082V28H235.703V22.4141ZM8 38.4141H16.3789V44H8V38.4141ZM16.125 38.4141H24.5039V44H16.125V38.4141ZM40.5508 38.4141H48.9297V44H40.5508V38.4141ZM48.6758 38.4141H57.0547V44H48.6758V38.4141ZM56.8008 38.4141H65.1797V44H56.8008V38.4141ZM64.9258 38.4141H73.3047V44H64.9258V38.4141ZM89.3516 38.4141H97.7305V44H89.3516V38.4141ZM97.4766 38.4141H105.855V44H97.4766V38.4141ZM105.602 38.4141H113.98V44H105.602V38.4141ZM130.027 38.4141H138.406V44H130.027V38.4141ZM138.152 38.4141H146.531V44H138.152V38.4141ZM146.277 38.4141H154.656V44H146.277V38.4141ZM154.402 38.4141H162.781V44H154.402V38.4141ZM162.527 38.4141H170.906V44H162.527V38.4141ZM170.652 38.4141H179.031V44H170.652V38.4141ZM178.777 38.4141H187.156V44H178.777V38.4141ZM186.902 38.4141H195.281V44H186.902V38.4141Z"
          fill="#605E5C"
        />
      </g>
      <rect x="281" width="72" height="48" rx="12" fill="#743C7A" />
      <path
        d="M311.887 15.2395C310.947 14.7283 309.764 15.0623 309.243 15.9856C309.084 16.2689 309 16.5875 309 16.9113V30.089C309 31.1444 309.871 32 310.945 32C311.275 32 311.599 31.9177 311.887 31.7607L323.997 25.1709C324.937 24.6596 325.277 23.4966 324.756 22.5734C324.579 22.2597 324.316 22.0012 323.997 21.8275L311.887 15.2395Z"
        fill="white"
      />
    </Icon>
  );
});

export default PlayIllustration;
