import { useIsDesktopView, useIsMobileView, useWindowSize } from 'deepstash-ui';
import { useGoogleOneTapLogin } from 'hooks/auth';
import { useMeasureElementList } from 'hooks/measurements';
import useRouter from 'hooks/useRouter';
import Router from 'next/router';
import useNavigation from 'providers/hooks/useNavigation';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  LayoutMetaContentPosition,
  LayoutSection,
  LayoutState,
} from '../../layout/Layout.types';
import { useLayoutMetaContent } from './useLayoutMetacontent';

const clearSelection = () => {
  if (window && window.getSelection) {
    window.getSelection()?.removeAllRanges();
  }
};

export const useLayoutInternals = (sections: LayoutSection[]) => {
  const router = useRouter();
  const navigationStatePreserveKey = router.asPath;
  const { width: windowWidth, height: windowHeight } = useWindowSize();
  const isMobileView = useIsMobileView();
  const isDesktopView = useIsDesktopView();
  useGoogleOneTapLogin();

  useEffect(() => {
    Router.events.on('routeChangeStart', clearSelection);

    //Prevent scroll preservation default behavior
    window.history.scrollRestoration = 'manual';

    return () => {
      Router.events.off('routeChangeStart', clearSelection);
    };
  }, []);

  const {
    layoutCache,
    preserveLayoutState,
    offScrollRestoreComplete,
    onScrollRestoreComplete,
  } = useNavigation();

  const [layoutState, _setLayoutState] = useState<LayoutState>(() => {
    // Restore the state from the navigation cache if present
    const prevState = layoutCache.current.get(router.asPath);
    return (
      prevState ?? {
        isRootScrollEnabled: true,
        metaContentPositions: sections.map(
          () => 'top' as LayoutMetaContentPosition,
        ),
      }
    );
  });

  // We also keep the state in a ref to be able to save it at unmount in the navigation provider
  const layoutStateRef = useRef(layoutState);

  const { metaContentPositions, isRootScrollEnabled } = layoutState;

  const setLayoutState = useCallback(
    (newState: LayoutState | ((prevState: LayoutState) => LayoutState)) => {
      if (typeof newState === 'function') {
        // Use the ref as prevState to keep this function referentially stable
        const newStateValue = newState(layoutStateRef.current);
        layoutStateRef.current = newStateValue;
        _setLayoutState(newStateValue);
      } else {
        layoutStateRef.current = newState;
        _setLayoutState(newState);
      }
    },
    [],
  );

  const setMetaContentPosition = (
    val: LayoutMetaContentPosition,
    index: number,
  ) =>
    setLayoutState(prevState => {
      const newState = { ...prevState };
      newState.metaContentPositions[index] = val;
      return newState;
    });

  const setIsRootScrollEnabled = (val: boolean) => {
    setLayoutState(prevState => ({
      ...prevState,
      isRootScrollEnabled: val,
    }));
  };

  const {
    measurementsList: sectionMeasurements,
    measureElement: measureSection,
  } = useMeasureElementList({ elementCount: sections.length });
  const {
    measurementsList: metaContentMeasurements,
    measureElement: measureMetaContent,
  } = useMeasureElementList({ elementCount: sections.length });
  const {
    measurementsList: contentMeasurements,
    measureElement: measureContent,
  } = useMeasureElementList({
    elementCount: sections.length,
  });

  const metaContentStickyOffsets = useMemo(
    () => sections.map(section => section.metaContentStickyOffset),
    [sections],
  );
  const metaContentTopOffsets = useMemo(
    () => sections.map(section => section.metaContentTopOffset),
    [sections],
  );
  const isMetaContentInfiniteList = useMemo(
    () => sections.map(section => !!section.infiniteScroll),
    [sections],
  );
  // This calculates the height of the section taking into account the fact that the meta content is fixed positioned
  // and thus not included in the actual div height
  // The section height on the desktop is the max between the content and the metacontent
  // On mobile/tablet, they are stacked
  const sectionDisplayedHeights = useMemo(
    () =>
      sections.map((_, sectionIndex) =>
        isDesktopView
          ? Math.max(
              contentMeasurements[sectionIndex].height,
              metaContentMeasurements[sectionIndex].height +
                (metaContentTopOffsets[sectionIndex] ?? 0),
            )
          : // On mobile and tablet the meta content is below the content
            contentMeasurements[sectionIndex].height +
            metaContentMeasurements[sectionIndex].height,
      ),
    [contentMeasurements, metaContentMeasurements],
  );
  const { updateMetaContentPosition } = useLayoutMetaContent({
    setMetaContentPosition,
    metaContentMeasurements,
    contentMeasurements,
    layoutStateRef,
    sectionMeasurements,
    stickyOffsets: metaContentStickyOffsets,
    topOffsets: metaContentTopOffsets,
    isSectionInfinite: isMetaContentInfiniteList,
    sectionDisplayedHeights,
  });

  // Use the scroll top to:
  // - Update the meta content stickiness
  const onRootScroll = useCallback(() => {
    const scrollTop = window.scrollY;

    updateMetaContentPosition(scrollTop);
  }, [updateMetaContentPosition]);

  const remeasureLayout = () => {
    measureMetaContent();
    measureContent();
    measureSection();
  };

  useEffect(() => {
    // The size of the window changed, recompute all the heights and positions of the elements that affect the layout
    remeasureLayout();
  }, [windowWidth, windowHeight]);

  // Register navigation events to enable / disable the scroll listener when needed
  useEffect(() => {
    // Enable root scroll listener after scroll was restored
    const onScrollRestore = () => setIsRootScrollEnabled(true);

    const onNavigationStart = () => setIsRootScrollEnabled(false);
    const onNavigationCancel = () => setIsRootScrollEnabled(true);
    Router.events.on('routeChangeStart', onNavigationStart);
    Router.events.on('routeChangeError', onNavigationCancel);
    onScrollRestoreComplete(onScrollRestore);
    return () => {
      Router.events.off('routeChangeStart', onNavigationStart);
      Router.events.off('routeChangeError', onNavigationCancel);
      offScrollRestoreComplete(onScrollRestore);
    };
  }, []);

  useEffect(() => {
    if (isRootScrollEnabled && !isMobileView) {
      window.addEventListener('scroll', onRootScroll);
      return () => {
        window.removeEventListener('scroll', onRootScroll);
      };
    } else {
      return () => {
        return;
      };
    }
  }, [onRootScroll, isRootScrollEnabled]);

  useEffect(() => {
    // Preserve the state on navigation

    return () => {
      preserveLayoutState(navigationStatePreserveKey, {
        ...layoutStateRef.current,
      });
    };
  }, []);

  return {
    remeasureLayout,
    metaContentMeasurements,
    measureMetaContent,
    contentMeasurement: contentMeasurements,
    measureContent,
    measureSection,
    metaContentPositions,
    sectionDisplayedHeights,
  };
};
