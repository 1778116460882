import React from 'react';
import {
  Flex,
  useColorMode,
  //   useIsMobileView,
  Text,
  color,
  typography,
  StyleProps,
} from 'deepstash-ui';
import FaqItem, { FaqItemI } from './FaqItem';

interface FaqGroupProps {
  faqItems: FaqItemI[];
  groupTitle?: string;
}

const FaqGroup: React.FC<FaqGroupProps & StyleProps> = ({
  faqItems,
  groupTitle,
  ...props
}) => {
  //   const isMobileView = useIsMobileView();
  const { colorMode } = useColorMode();

  const [selectedIndex, setSelectedIndex] = React.useState<number | undefined>(
    undefined,
  );

  return (
    <Flex flexDirection="column" width={'100%'} gridRowGap={16} {...props}>
      <Text
        {...typography.primaryHeadingFonts.small.semiBold}
        color={color[colorMode].text}
      >
        {groupTitle}
      </Text>
      {faqItems.map((faqItem, index) => {
        return (
          <FaqItem
            key={`faq-item-${index}`}
            title={faqItem.title}
            description={faqItem.description}
            isSelected={selectedIndex === index}
            onClick={() => {
              setSelectedIndex(prevIndex => {
                if (prevIndex === index) {
                  return undefined;
                }
                return index;
              });
            }}
          />
        );
      })}
    </Flex>
  );
};

export default FaqGroup;
