import React from 'react';
import {
  Flex,
  useColorMode,
  useIsMobileView,
  Text,
  color,
  typography,
  StyleProps,
} from 'deepstash-ui';
import { FaqItemI } from 'src/components/faq/FaqItem';
import FaqGroup from 'src/components/faq/FaqGroup';

const TITLE = 'Frequently asked questions';

const FAQ_ITEMS_GENERAL: FaqItemI[] = [
  {
    title: 'What is Deepstash?',
    description:
      'Deepstash is a platform where users read and curate bite-sized insights and ideas from anywhere online, may that be books, articles, podcasts, or videos. You can learn good habits, useful skills and build your knowledge, on your own time. Deepstash makes your personal growth manageable!',
  },
  {
    title: 'Why are ideas important?',
    description:
      'Ideas are important because they form the foundation for progress and innovation. The idea format used by Deepstash is important because it condenses complex concepts and profound thoughts into simple, digestible sentences that are easy to understand and remember. This format makes ideas more accessible and easier to apply in daily life, helping people to make positive changes and achieve their goals.',
  },
  {
    title: 'How can I use Deepstash?',
    description:
      'Deepstash can be found on both the Apple App Store and Google Play Store. Additionally, Deepstash can be found on the web, where you can login and use Deepstash at deepstash.com.',
  },
];

const FAQ_ITEMS_PRO: FaqItemI[] = [
  // TODO No Trial Changes
  // {
  //   title: 'How does the free 7-day trial work?',
  //   description:
  //     "Start your free 7-day trial by signing up for a Deepstash Pro yearly plan. There’s no obligation to stay subscribed, and you'll only be charged on the day the free 7-day trial ends. You have access to all the Pro features during the trial.",
  // },
  {
    title: 'What is included in my Deepstash subscription?',
    description:
      'With Pro, you get all of Deepstash amazing content, but your interaction with it is unrestrained. Save as many ideas as you want, in as many stashes you desire. You can also go offline and still have access to your content, while also listening to your ideas whenever, wherever.',
  },
  {
    title: 'Can I cancel at any time?',
    description:
      'Yes. If you have an ongoing subscription, you will keep your benefits until the next billing date.',
    // 'Yes. If you are on a trial and you cancel it, you won’t be charged. If you have an ongoing subscription, you will keep your benefits until the next billing date.',
  },
  {
    title: 'When is my Lifetime Pro subscription activated?',
    description:
      'Due to the nature of payment processing, please allow up to 24h for your Lifetime Pro subscription to become active.',
  },
];

interface OverallFaq {}

const OverallFaq: React.FC<OverallFaq & StyleProps> = ({ ...props }) => {
  const isMobileView = useIsMobileView();
  const { colorMode } = useColorMode();

  return (
    <Flex flexDirection="column" alignItems="center" width={'100%'} {...props}>
      <Text
        {...(isMobileView
          ? typography.primaryHeadingFonts.small.bold
          : typography.primaryHeadingFonts.large.bold)}
        color={color[colorMode].text}
        textAlign="center"
      >
        {TITLE}
      </Text>
      <Flex
        width={isMobileView ? '100%' : '80%'}
        mt={32}
        flexDirection="column"
      >
        <FaqGroup faqItems={FAQ_ITEMS_GENERAL} groupTitle={'General'} />
        <FaqGroup
          faqItems={FAQ_ITEMS_PRO}
          groupTitle={'Pro Subscription'}
          mt={48}
        />
      </Flex>
    </Flex>
  );
};

export default OverallFaq;
