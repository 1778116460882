import {
  BookSvg,
  CheckSvg,
  color,
  Flex,
  HeadphonesSvg,
  Icon,
  PhoneSvg,
  PrimaryButton,
  PrimaryHeading,
  PrimaryText,
  spacing,
  StarFullSvg,
  StyleProps,
  useColorMode,
  useIsMobileView,
} from 'deepstash-ui';
import { useRouter } from 'next/router';
import React from 'react';
import {
  GAP_SIZE_REM,
  getRemWidthFromColumns,
  SQUARE_WIDTH_REM,
} from 'utils/global';
import actionStrings from 'utils/strings/actionStrings';
import AppStats from '../app-stats/AppStats';
import BaseCard from '../cards/BaseCard';

const SIGN_UP_BENEFITS = [
  {
    text: (
      <>
        <b>Replace doomscrolling</b> with 200,000+ curated ideas
      </>
    ),
    icon: <BookSvg color={color.light.top} />,
  },
  {
    text: (
      <>
        Unlock <b>100+</b> tailored journeys on over <b>50</b> topics
      </>
    ),
    icon: <StarFullSvg color={color.light.top} />,
  },
  {
    text: (
      <>
        Unlock unlimited <b>listening</b> to all of our ideas
      </>
    ),
    icon: <HeadphonesSvg color={color.light.top} />,
  },
  {
    text: <>Get rid of all ads</>,
    icon: <CheckSvg color={color.light.top} />,
  },
  {
    text: (
      <>
        Get access to the <b>mobile app</b>
      </>
    ),
    icon: <PhoneSvg color={color.light.top} />,
  },
];

interface SignUpBannerProps {
  size?: 'sm' | 'lg';
}

const SignUpBanner: React.FC<SignUpBannerProps & StyleProps> = ({
  size,
  ...props
}) => {
  const isMobileView = useIsMobileView();
  const { colorMode } = useColorMode();
  // const { nsiDispatch } = useNsi();
  const router = useRouter();

  const isSmallStyle = isMobileView || size === 'sm';

  const openSignUp = (e: any) => {
    e.stopPropagation();

    router.push('/setup?from=banner');

    // TODO No Trial Changes
    // nsiDispatch({
    //   type: 'openAuth',
    //   payload: {
    //     type: 'sign-up',
    //     onAuthSuccess: () => router.push('/setup'),
    //     shouldSkipOnboarding: true,
    //   },
    // });

    // Analytics.logEvent({
    //   eventName: Events.modals.openAuthModal,
    //   properties: {
    //     location: 'feed-banner',
    //     chosenOption: 'signup',
    //   },
    //   platforms: ['amplitude'],
    // });
  };

  const title = (
    <PrimaryHeading
      size="h3"
      textSize="l"
      type="bold"
      textAlign={'left'}
      color={color[colorMode].text}
    >
      {"It's time to "}
      <br />
      <Flex w="auto" display="inline-flex" position="relative">
        <Icon
          viewBox={'0 0 53 13'}
          width="100%"
          height={13}
          fill="none"
          bottom={2}
          preserveAspectRatio="none"
          position="absolute"
        >
          <path
            opacity={0.8}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.788 2.298c-2.867.448-3.186.783-2.335 2.356.935 1.743-1.847 2.921 3.168 2.705-5.369.423-4.103 1.534-3.378 3.56.926 2.542 6.476 1.838 33.523-.204 8.104-.594 12.62-.197 14.92-.555 3.191-.518 2.359-2.49 1.01-3.315l.726-.279c1.911-.726.663-4.262-1.827-5.13-2.19-.758-14.677-.749-26.686.02-15.885 1.075-15.848.318-19.12.842Z"
            fill={colorMode === 'light' ? '#FFE34C' : '#474733'}
          />
        </Icon>
        <span
          style={{
            zIndex: 1,
            fontWeight: 900,
            color: color[colorMode].primary.default,
          }}
        >
          {'Read like a Pro.'}
        </span>
      </Flex>
    </PrimaryHeading>
  );

  const description = (
    <Flex flexDir="column" alignItems={'center'}>
      <PrimaryText
        size="s"
        color={color[colorMode].text}
        textAlign={'start'}
        my={spacing.M.rem}
      >
        {'Jump-start your'}
        <PrimaryText size="m" type="bold" display="inline">
          {' reading habits'}
        </PrimaryText>
        {', gather your '}
        <PrimaryText size="m" type="bold" display="inline">
          {'knowledge'}
        </PrimaryText>
        {', '}
        <PrimaryText size="m" type="bold" display="inline">
          {'remember what you read '}
        </PrimaryText>
        {'and stay ahead of the crowd!'}
      </PrimaryText>
    </Flex>
  );

  const benefits = (
    <Flex flexDirection="column" my={spacing.M.rem} gridGap={spacing.XS.rem}>
      {SIGN_UP_BENEFITS.map((benefit, index) => (
        <Flex
          gridGap={spacing.toRem(8)}
          alignItems="center"
          key={`paywall-included-${index}`}
        >
          <Flex
            alignItems="center"
            bgColor={color[colorMode].primary.default}
            borderRadius="full"
            boxSize={spacing.toRem(24)}
            p={spacing.toRem(4)}
          >
            {benefit.icon}
          </Flex>
          <PrimaryText size="m" color={color[colorMode].text}>
            {benefit.text}
          </PrimaryText>
        </Flex>
      ))}
    </Flex>
  );

  const renderDesktopVersion = () => (
    <BaseCard
      h="auto"
      hasHoverEffect
      alignItems={isSmallStyle ? 'center' : 'flex-start'}
      backgroundColor={color[colorMode].surface}
      p={SQUARE_WIDTH_REM}
      w={{
        base: '100%',
        md: isSmallStyle
          ? getRemWidthFromColumns(4)
          : getRemWidthFromColumns(8),
      }}
      cursor="pointer"
      onClick={() => {
        // TODO Add analytics
        router.push('/get-pro');
      }}
      gridGap={GAP_SIZE_REM}
      {...props}
    >
      <Flex
        flexDir={'column'}
        w={isSmallStyle ? '100%' : getRemWidthFromColumns(4)}
      >
        {title}
        {!isSmallStyle && description}
        {isSmallStyle && benefits}
        <PrimaryButton
          my={spacing.M.rem}
          w="100%"
          onClick={e => openSignUp(e)}
          size="md"
          backgroundColor={color[colorMode].primary.default}
          _hover={undefined}
          _active={undefined}
        >
          <PrimaryText size="l" type="semiBold">
            {actionStrings.startToday}
          </PrimaryText>
        </PrimaryButton>
        {isSmallStyle && (
          <AppStats mt={spacing.M.rem} w="100%" justifyContent="center" />
        )}
      </Flex>
      {!isSmallStyle && (
        <Flex flexDirection={'column'} w={getRemWidthFromColumns(4)}>
          {benefits}
          <AppStats mt={spacing.toRem(48)} w="100%" justifyContent="start" />
        </Flex>
      )}
    </BaseCard>
  );

  return renderDesktopVersion();
};

export default SignUpBanner;
