import {
  AppStoreColoredSvg,
  BoxProps,
  color,
  Flex,
  FullStarSvg,
  GooglePlayColoredSvg,
  Link,
  spacing,
  Text,
  TwitterSvg,
  typography,
  useColorMode,
  useIsMobileView,
} from 'deepstash-ui';
import React from 'react';
import NextLink from 'src/components/navigation/NextLink';
import { Review } from 'types/types';
import {
  BLOCK_WIDTH_REM,
  GAP_SIZE,
  GAP_SIZE_REM,
  SQUARE_WIDTH_REM,
} from 'utils/global';
import NextImage from '../image/NextImage';
import BaseCard from './BaseCard';
import CuratorsNote from './CuratorsNote';

export interface ReviewCardProps extends BoxProps {
  review: Review;
  onClick?: () => void;
}

const ReviewCard: React.FC<ReviewCardProps> = ({
  review,
  onClick,
  ...props
}) => {
  const { colorMode } = useColorMode();
  const { name, text, imageUrl, url } = review;
  const isMobileView = useIsMobileView();

  const getPlatformIcon = () => {
    switch (review.platform) {
      case 'android':
        return <GooglePlayColoredSvg />;
      case 'ios':
        return <AppStoreColoredSvg />;
      case 'twitter':
        return <TwitterSvg color="#00acee" />;
      default:
        return null;
    }
  };

  const card = (
    <BaseCard
      alignItems="top"
      p={SQUARE_WIDTH_REM}
      justifyContent="center"
      onClick={url ? undefined : onClick}
      cursor={url || onClick ? 'pointer' : 'unset'}
      px={GAP_SIZE_REM}
      gridGap={spacing.toRem(GAP_SIZE / 2)}
      flexDirection={{ base: 'column', md: 'row' }}
      height="unset"
      {...props}
    >
      <NextImage
        wrapperStyle={{
          width: BLOCK_WIDTH_REM,
          height: BLOCK_WIDTH_REM,
          borderRadius: SQUARE_WIDTH_REM,
          cursor: 'inherit',
          flexShrink: 0,
        }}
        objectFit="fill"
        imageUrl={imageUrl}
        priority
      />
      <Flex flexGrow={1} alignItems="center" flexDirection="column">
        <Flex width="100%" justifyContent="space-between" alignItems="center">
          <Flex alignItems="center" flexGrow={1}>
            <Text
              color={color[colorMode].text}
              noOfLines={1}
              textOverflow="ellipsis"
              width="auto"
              {...typography.primaryTextFonts.large.bold}
              mr={{ base: spacing.toRem(4), md: '1rem' }}
            >
              {name}
            </Text>
            {!isMobileView &&
              (review.platform === 'android' || review.platform === 'ios') &&
              Array(5)
                .fill(1)
                .map((_, index) => (
                  <FullStarSvg
                    key={index}
                    color={color[colorMode].secondary.default}
                    boxSize="1rem"
                  />
                ))}
          </Flex>
          {!isMobileView && getPlatformIcon()}
        </Flex>
        <CuratorsNote text={text} />
      </Flex>
    </BaseCard>
  );

  return url ? (
    <NextLink href={url} passHref>
      <Link
        _focus={undefined}
        _hover={undefined}
        title={name}
        onClick={onClick}
        isExternal
        rel="noopener nofollow noreferrer"
      >
        {card}
      </Link>
    </NextLink>
  ) : (
    card
  );
};

export default ReviewCard;
