import React from 'react';
import {
  Flex,
  useIsMobileView,
  Text,
  color,
  typography,
  PrimaryButton,
} from 'deepstash-ui';
import styles from './FooterStickyStartTrial.module.scss';
import { LANDING_PAGE_DEFAULT_PX } from 'src/page-components/landing/LandingPageV2';
import useAuthAndOpenPurchasePage from 'hooks/purchases/useAuthAndOpenPurchasePage';
import { Analytics, Events } from 'services/analytics';
import { useRouter } from 'next/router';

// TODO No Trial Changes
// const CTA_TEXT = 'Try it for Free';
const CTA_TEXT = 'Start Today';

interface FooterStickyStartTrialProps {}

const FooterStickyStartTrial: React.FC<FooterStickyStartTrialProps> = ({}) => {
  const isMobileView = useIsMobileView();
  const { onClickStartSubscriptionForNsi } = useAuthAndOpenPurchasePage({});
  const router = useRouter();

  const [isVisible, setIsVisible] = React.useState(false);

  // TODO No Trial Changes
  // const { stripePrices, requestStatus } = useStripePrices({
  //   requestNsi: true,
  // });

  // const freeTrialSubscription = stripePrices?.subscriptions['1 YEAR'];

  // const normalizedPrice = `$${freeTrialSubscription?.pricePerMonth}/month`;

  // const descriptionText =
  //   requestStatus === 'success'
  //     ? `Try first week for free, then ${normalizedPrice} (billed annually)`
  //     : '';

  const descriptionText =
    'Get Deepstash Pro & replace doomscrolling with personal growth';

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 0) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  return (
    <Flex
      className={`${styles.bar} ${isVisible ? styles.visible : ''}`}
      bgColor="rgba(0, 0, 0, 0.7)"
      px={LANDING_PAGE_DEFAULT_PX}
      alignItems="center"
      justifyContent="flex-end"
      gridColumnGap={{ sm: 8, md: 32 }}
      zIndex={10}
    >
      <Text
        {...(isMobileView
          ? { ...typography.primaryTextFonts.small.regular }
          : { ...typography.primaryTextFonts.medium.regular })}
        color={color.light.textInverted}
        flex={1}
        textAlign={{ sm: 'start', md: 'start', lg: 'end' }}
      >
        {descriptionText}
      </Text>
      <Flex flexDirection="column" justifyContent="center">
        <PrimaryButton
          size="md"
          type="button"
          {...typography.primaryTextFonts.medium.semiBold}
          borderRadius={16}
          noOfLines={1}
          onClick={() => {
            Analytics.logEvent({
              eventName: Events.payments.clickStartTrial,
              properties: {
                url: router.asPath,
                landingLocation: 'footer-sticky',
              },
              platforms: ['amplitude'],
            });
            onClickStartSubscriptionForNsi();
          }}
        >
          {CTA_TEXT}
        </PrimaryButton>
      </Flex>
    </Flex>
  );
};

export default FooterStickyStartTrial;
