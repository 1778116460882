import React from 'react';
import Image, { ImageProps } from 'next/image';
import { Flex, Text, typography } from 'deepstash-ui';

interface HeroCarouselCardProps {
  imageProps: ImageProps;
  title?: string;
  containerWidth: number;
  containerBorderBottomRadius: number;
}

const HeroCarouselCard: React.FC<HeroCarouselCardProps> = ({
  imageProps,
  title,
  containerWidth,
  containerBorderBottomRadius,
}) => {
  return (
    <Flex>
      <Image {...imageProps} objectFit="cover" />
      <Flex
        position="absolute"
        bottom={0}
        background={`linear-gradient(0deg, rgba(0,0,0,0.7), rgba(0,0,0,0))`}
        zIndex={1}
        w={containerWidth}
        borderBottomRadius={containerBorderBottomRadius}
        pb={16}
        px={16}
        pt={32}
        justifyContent="center"
      >
        {title !== undefined ? (
          <Text
            {...typography.primaryTextFonts.medium.regular}
            color={'white'}
            textAlign="center"
          >
            {title}
          </Text>
        ) : null}
      </Flex>
    </Flex>
  );
};

export default HeroCarouselCard;
