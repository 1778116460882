import React, { createContext, useEffect, useMemo, useRef } from 'react';
import { LayoutContextType } from './LayoutProviderTypes';

export const LayoutContext = createContext<LayoutContextType>({
  remeasureLayoutCallbackRef: undefined,
});

LayoutContext.displayName = 'LayoutContext';

interface LayoutProviderProps {
  /**
   * Callback to trigger layout remeasureing.
   * Useful for infinite scroll lists and other layout changes
   */
  remeasureLayoutCallback?: () => void;
}

const LayoutProvider: React.FC<LayoutProviderProps> = ({
  remeasureLayoutCallback,
  children,
}) => {
  const remeasureLayoutCallbackRef = useRef(remeasureLayoutCallback);
  useEffect(() => {
    remeasureLayoutCallbackRef.current = remeasureLayoutCallback;
  }, [remeasureLayoutCallback]);

  const layoutProviderState: LayoutContextType = useMemo(() => {
    return {
      remeasureLayoutCallbackRef: remeasureLayoutCallbackRef as
        | React.RefObject<() => void>
        | undefined,
    };
  }, []);

  return (
    <LayoutContext.Provider value={layoutProviderState}>
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
