import {
  Box,
  BoxProps,
  color,
  // Flex,
  // HelpSvg,
  PrimaryButton,
  // PrimaryText,
  spacing,
  typography,
  useColorMode,
  useIsMobileView,
} from 'deepstash-ui';
import { useRouter } from 'next/router';
import useAuth from 'providers/hooks/useAuth';
import useNsi from 'providers/hooks/useNsi';
import usePaywall from 'providers/hooks/usePaywall';
import useProfile from 'providers/hooks/useProfile';
import React, { useCallback, useMemo } from 'react';
import { Analytics, Events } from 'services/analytics';
import NextImage from 'src/components/image/NextImage';
import FullWidthSectionWrapper from 'src/components/layout/components/FullWidthSectionWrapper';
// import SectionHeader from 'src/components/layout/components/SectionHeader';
// import PaywallButtons from 'src/components/paywall/PaywallButtons';
import {
  PaywallAnalyticsLocation,
  // SourceType,
  UserSubscriptionType,
} from 'types/enums';
import { PriceOption, SubscriptionType } from 'types/types';
import {
  BLOCK_WIDTH_REM,
  GAP_SIZE,
  GAP_SIZE_REM,
  getRemWidthFromColumns,
  SECTION_GAP_REM,
  SQUARE_WIDTH,
} from 'utils/global';
// import { getSourceThemeColor } from 'utils/source.utils';
import actionStrings from 'utils/strings/actionStrings';
// import commonStrings from 'utils/strings/commonStrings';
import ProComparisonSection from '../value-props/ProComparisonSection';
import ProBenefitsSection from './ProBenefitsSection';
import ProTestimonialsSection from './ProTestimonialsSection';
import { STRIPE_URLS } from 'utils/constants';

interface ProAdSectionProps {
  /**
   * Whether to show the price sections or just the benefits
   * @default true
   */
  showPrices?: boolean;
  promoCode?: string;
  customCtaText?: string;
  customOnClickCta?: (subscriptionType?: SubscriptionType) => void;
  showForPro?: boolean;
  shouldAllowNsi?: boolean;
  customPrices?: PriceOption[];
  lifetimeLink?: string;
  /**
   * If true, keep only the comparison table and a CTA button
   * @default false
   */
  isShort?: boolean;
}

// This will only be visible to NSI / SI non-Pro users
const ProAdSection: React.FC<ProAdSectionProps & BoxProps> = ({
  showPrices = true,
  // promoCode,
  customCtaText,
  customOnClickCta,
  showForPro = false,
  shouldAllowNsi = false,
  lifetimeLink = STRIPE_URLS.LIFETIME_299,
  isShort,
  // customPrices,
  ...props
}) => {
  const { colorMode } = useColorMode();

  const router = useRouter();

  const isMobileView = useIsMobileView();

  const { profile } = useProfile();
  const { nsiDispatch } = useNsi();
  const { isLoggedIn } = useAuth();
  const { onPaywallModalOpen } = usePaywall();

  const hasPremiumSubscription = useMemo(() => {
    if (!profile) {
      return 0;
    }
    return profile?.subscriptionType === UserSubscriptionType.Premium;
  }, [profile]);

  const onStartPriceClick = useCallback(
    (subscriptionType: SubscriptionType | 'LIFETIME') => {
      Analytics.logEvent({
        eventName: Events.payments.claimOfferEvent,
        properties: {
          subscriptionType,
          isLoggedIn,
          location: PaywallAnalyticsLocation.ProAdCta,
        },
        platforms: ['amplitude'],
      });

      if (isLoggedIn || shouldAllowNsi) {
        if (subscriptionType === 'LIFETIME') {
          router.push(lifetimeLink);
        } else {
          onPaywallModalOpen({
            location: PaywallAnalyticsLocation.AlreadyLoggedInInfluencer,
            showCloseButton: true,
            defaultSubscriptionType: subscriptionType,
          });
        }
      } else {
        nsiDispatch({
          type: 'openAuth',
          payload: {
            type: 'sign-up',
          },
        });
      }
    },
    [hasPremiumSubscription, nsiDispatch, isLoggedIn],
  );

  const ctaButton = useMemo(() => {
    return (
      <PrimaryButton
        size="lg"
        // TODO No Trial Changes
        onClick={() =>
          // customOnClickCta ? customOnClickCta() : onStartPriceClick('LIFETIME')
          customOnClickCta ? customOnClickCta() : router.push('/setup')
        }
        _hover={{
          backgroundColor: color[colorMode === 'light' ? 'dark' : 'light'].top,
        }}
        width={{ base: '100%', md: getRemWidthFromColumns(4) }}
        minHeight={spacing.toRem((isMobileView ? 1.5 : 2) * SQUARE_WIDTH)}
        transition="background-color 0.3s ease-out"
        backgroundColor={color[colorMode].text}
        {...(isMobileView
          ? typography.primaryHeadingFonts.small.semiBold
          : typography.primaryHeadingFonts.medium.semiBold)}
      >
        {/* TODO No Trial Changes */}
        {customCtaText ?? actionStrings.startToday}
        {/* {customCtaText ?? actionStrings.getDeepstashPro} */}
      </PrimaryButton>
    );
  }, [onStartPriceClick, isMobileView, colorMode, customOnClickCta]);

  const ctaButtonSection = (
    <FullWidthSectionWrapper
      pb={SECTION_GAP_REM}
      backgroundColor={color[colorMode].surface}
      containerProps={{ flexDirection: 'column', alignItems: 'center' }}
    >
      {ctaButton}
    </FullWidthSectionWrapper>
  );

  return isLoggedIn &&
    profile?.subscriptionType === UserSubscriptionType.Premium &&
    !showForPro ? null : (
    <Box
      {...props}
      backgroundColor={color[colorMode].surface}
      w="100%"
      pt={isShort ? GAP_SIZE_REM : 0}
    >
      {showPrices && (
        <FullWidthSectionWrapper
          py={{
            base: spacing.toRem(GAP_SIZE * 2),
            md: spacing.toRem(GAP_SIZE * 4),
          }}
          backgroundColor={color[colorMode].surface}
          containerProps={{
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
          }}
        >
          {/* <SectionHeader
            title={actionStrings.readLikeAPro}
            preTitle={commonStrings.paywall.incite}
            underlineColor={getSourceThemeColor(SourceType.VIDEO, colorMode)}
          />
          <PaywallButtons
            mb={GAP_SIZE_REM}
            promoCode={promoCode}
            shouldAllowNsi={shouldAllowNsi}
            customOnClickCta={customOnClickCta}
            customPrices={customPrices}
          />
          {ctaButton} */}
          {!isMobileView && (
            <NextImage
              imageUrl="https://static.deepstash.com/illustrations/stashy_superman.svg"
              wrapperStyle={{
                width: spacing.toRem(353),
                height: spacing.toRem(200),
                position: 'absolute',
                right: 0,
                bottom: `-${spacing.toRem(GAP_SIZE * 6)}`,
              }}
              alt="stash-superman-illustration"
            />
          )}
        </FullWidthSectionWrapper>
      )}

      {!isShort && (
        <ProBenefitsSection
          mt={{ base: 0, md: BLOCK_WIDTH_REM }}
          backgroundColor={color[colorMode].surface}
          mb={{ base: GAP_SIZE_REM, md: SECTION_GAP_REM }}
          ctaButton={ctaButton}
        />
      )}

      {!isMobileView && !isShort && ctaButtonSection}

      {!isShort && (
        <ProTestimonialsSection
          mt={{ base: SECTION_GAP_REM, md: BLOCK_WIDTH_REM }}
          backgroundColor={color[colorMode].surface}
        />
      )}
      {!isShort && ctaButtonSection}

      <ProComparisonSection
        mt={{ base: 0, md: BLOCK_WIDTH_REM }}
        pb={{ base: GAP_SIZE_REM, md: SECTION_GAP_REM }}
      />
      {isShort && ctaButtonSection}

      {/* {showPrices ? (
        <FullWidthSectionWrapper
          pt={GAP_SIZE_REM}
          pb={SECTION_GAP_REM}
          backgroundColor={color[colorMode].surface}
          containerProps={{
            flexDirection: 'column',
            alignItems: 'center',
            gridGap: 0,
            position: 'relative',
          }}
        >
          <Flex
            cursor="pointer"
            onClick={() => {
              router.push('/get-pro#faq');
            }}
            position="absolute"
            alignItems="center"
            right={{ base: spacing.toRem(32), md: spacing.M.rem }}
            top={{ base: 'unset', md: spacing.toRem(80) }}
            bottom={{ base: spacing.toRem(-80), md: 'unset' }}
            gridGap={spacing.toRem(4)}
          >
            <HelpSvg fill={color[colorMode].textDisabled} />
            <PrimaryText color={color[colorMode].textDisabled} type="semiBold">
              {commonStrings.faq}
            </PrimaryText>
          </Flex>
          <SectionHeader
            title={
              customCtaText ??
              (isMobileView
                ? actionStrings.getPro
                : actionStrings.getDeepstashPro)
            }
            preTitle={actionStrings.claimYourLimitedOffer}
            underlineColor={getSourceThemeColor(SourceType.VIDEO, colorMode)}
          />
          <PaywallButtons
            my={GAP_SIZE_REM}
            promoCode={promoCode}
            shouldAllowNsi={shouldAllowNsi}
            customOnClickCta={customOnClickCta}
            customPrices={customPrices}
          />
          <PrimaryButton
            size="lg"
            onClick={() =>
              customOnClickCta
                ? customOnClickCta()
                : onStartPriceClick('LIFETIME')
            }
            _hover={{
              backgroundColor:
                color[colorMode === 'light' ? 'dark' : 'light'].top,
            }}
            mt={GAP_SIZE_REM}
            width={{ base: '100%', md: getRemWidthFromColumns(4) }}
            minHeight={spacing.toRem((isMobileView ? 1.5 : 2) * SQUARE_WIDTH)}
            transition="background-color 0.3s ease-out"
            backgroundColor={color[colorMode].text}
            {...(isMobileView
              ? typography.primaryHeadingFonts.small.semiBold
              : typography.primaryHeadingFonts.medium.semiBold)}
          >
            {actionStrings.getDeepstashPro}
          </PrimaryButton>
        </FullWidthSectionWrapper>
      ) : (
        ctaButtonSection
      )} */}
    </Box>
  );
};

export default ProAdSection;
