import React from 'react';
import { Flex, useIsMobileView, Text, color, typography } from 'deepstash-ui';
import NewCollectionCarousel from 'src/components/collection/NewCollectionCarousel';
import { COLLECTIONS_LANDING_DATA } from './collections_landing_data';

const TITLE = 'Top Collections';
const SUBTITLE =
  'Discover the most popular ideas and learn something new in less than 5 minutes a day';

interface LandingCollectionsSectionsProps {}

const LandingCollectionsSections: React.FC<
  LandingCollectionsSectionsProps
> = ({}) => {
  const isMobileView = useIsMobileView();

  return (
    <Flex
      flexDirection={'column'}
      width={'100%'}
      alignItems="center"
      mt={isMobileView ? 68 : 120}
    >
      <Text
        {...typography.primaryHeadingFonts.large.semiBold}
        color={color.light.text}
        textAlign="center"
      >
        {TITLE}
      </Text>
      <Text
        {...typography.primaryTextFonts.large.regular}
        color={color.light.textSecondary}
        textAlign="center"
        mt={8}
      >
        {SUBTITLE}
      </Text>
      <NewCollectionCarousel collections={COLLECTIONS_LANDING_DATA} mt={32} />
    </Flex>
  );
};

export default LandingCollectionsSections;
