import {
  ArrowLeftSvg,
  Box,
  BoxProps,
  CheckFillSvg,
  color,
  Flex,
  Link,
  PrimaryHeading,
  PrimaryText,
  spacing,
  StyleProps,
  useColorMode,
} from 'deepstash-ui';
import React, { useCallback, useMemo } from 'react';
import ProgressBar from 'src/page-components/collection/old/ProgressBar';
import { Analytics, Events } from 'src/services/analytics';
import { BORDER_RADIUS_M } from 'theme/size';
import { CollectionData, UserStash } from 'types/models';
import { getCollectionStatus } from 'utils/collection.utils';
import { createGradient } from 'utils/constants';
import { getRemWidthFromColumns, slugify } from 'utils/global';
import actionStrings from 'utils/strings/actionStrings';
import IdeaCounter from '../counter/IdeaCounter';
import NextImage from '../image/NextImage';
import NextLink from '../navigation/NextLink';

const IMAGE_WRAPPER_STYLE: BoxProps = {
  height: '50%',
  width: '100%',
  position: 'relative',
};

const IMAGE_FALLBACK_COLORS: Record<string, string[]> = {
  light: [
    color.light.semantic.cyan,
    color.light.semantic.purple,
    color.light.semantic.yellow,
    color.light.semantic.green,
    color.light.semantic.grey,
    color.light.semantic.red,
  ],
  dark: [
    color.dark.semantic.cyan,
    color.dark.semantic.purple,
    color.dark.semantic.yellow,
    color.dark.semantic.green,
    color.dark.semantic.grey,
    color.dark.semantic.red,
  ],
};

interface GroupCardProps<T extends UserStash> {
  data: T;
  dataType: 'stash' | 'collection';
  onClick?: () => void;
  /**
   * Whether or not clicking the card redirect you to the collection page
   * @default True
   */
  withLink?: boolean;
}

const GroupCardSvg = <T extends UserStash>({
  data,
  dataType,
  onClick,
  withLink = true,
  ...props
}: GroupCardProps<T> & StyleProps): React.ReactElement => {
  const { colorMode } = useColorMode();

  //The link the user is redirected to when clicking the card
  const url = useMemo(
    () => `/${dataType}/${data.id}/${slugify(data.name)}`,
    [dataType, data.id, data.name],
  );

  const logTapEvent = () => {
    Analytics.logEvent({
      eventName: Events.consumption.groupCardTap,
      properties: {
        id: data.id,
        dataType: dataType,
        ...(dataType === 'collection'
          ? {
              status: getCollectionStatus({
                collection: data as unknown as CollectionData,
              }),
            }
          : {}),
      },
      platforms: ['amplitude'],
    });
  };

  const renderProgressBar = () => {
    const collection = data as unknown as CollectionData;
    if (!collection?.progress) return null;

    return (
      collection.progress.totalReadIdeas > 0 && (
        <ProgressBar
          currentValue={collection.progress.totalReadIdeas}
          totalValue={collection.ideaCount}
          flexGrow={1}
          left={0}
          width="100%"
          bottom={0}
          height={spacing.toRem(4)}
          borderRadius={spacing.toRem(4)}
          position="absolute"
          bgColor={color[colorMode].underground}
          isPadded
        />
      )
    );
  };

  const renderImage = useCallback(() => {
    if (!data.imageUrl) {
      return (
        <Box
          {...IMAGE_WRAPPER_STYLE}
          backgroundColor={IMAGE_FALLBACK_COLORS[colorMode][data.id % 6]}
        />
      );
    }

    return (
      <NextImage
        alt={data.name}
        imageUrl={data.imageUrl}
        wrapperStyle={{
          width: '100%',
          height: '100%',
        }}
      />
    );
  }, [data.imageUrl]);

  const onLinkClickHandler = (e: React.MouseEvent) => {
    logTapEvent();
    e.preventDefault();
  };

  const renderTitle = () => {
    return (
      <NextLink href={url} passHref>
        <Link
          _hover={undefined}
          _active={undefined}
          _focus={undefined}
          backgroundColor={undefined}
          title={data.name}
          mx={spacing.M.rem}
          mt={`-${spacing.toRem(16)}`}
          mb={spacing.S.rem}
          zIndex={3}
          // min height for placing the button lower when the title is on one line
          flexGrow={1}
          onClick={onClick ? onLinkClickHandler : logTapEvent}
        >
          <PrimaryHeading
            overflow="hidden"
            size="h2"
            textAlign="left"
            lineHeight="1.3"
            paddingBottom={spacing.XXS.rem}
          >
            {data.emoji}
          </PrimaryHeading>
          <PrimaryHeading
            WebkitLineClamp={2} /* number of lines to show */
            WebkitBoxOrient="vertical"
            overflow="hidden"
            textOverflow="ellipsis"
            display="-webkit-box"
            type="bold"
            size="h2"
            color={color[colorMode].text}
            textAlign="left"
            wordBreak="break-word"
            lineHeight="1.3"
            height={52}
          >
            {data.name}
          </PrimaryHeading>
        </Link>
      </NextLink>
    );
  };

  const startReadingStatus = (
    <Flex
      mx={spacing.M.rem}
      alignItems="center"
      justifyContent="space-between"
      mb={spacing.M.rem}
    >
      <NextLink
        h={spacing.toRem(20)}
        href={url}
        color={color[colorMode].primary}
        fontWeight="bold"
      >
        <Flex align="center" cursor="pointer">
          <PrimaryText fontWeight="bold">{actionStrings.start}</PrimaryText>
          <ArrowLeftSvg
            color={color[colorMode].primary}
            transform={'rotate(180deg)'}
            w="1rem"
            h="1rem"
            ml={spacing.XXS.rem}
          />
        </Flex>
      </NextLink>
      <IdeaCounter ideaCount={data.ideaCount} fontSize="0.875rem" />
    </Flex>
  );

  const doneReadingStatus = (
    <Flex
      mx={spacing.M.rem}
      alignItems="center"
      justifyContent="space-between"
      mb={spacing.M.rem}
    >
      <CheckFillSvg color={color.success} />
      <IdeaCounter ideaCount={data.ideaCount} fontSize="0.875rem" />
    </Flex>
  );

  const getContinueReadingStatus = (collection: CollectionData) => (
    <Flex
      mx={spacing.M.rem}
      alignItems="center"
      justifyContent="space-between"
      mb={spacing.M.rem}
    >
      <NextLink
        h={spacing.toRem(20)}
        href={url}
        color={color[colorMode].primary}
        fontWeight="bold"
      >
        <Flex align="center" cursor="pointer">
          <PrimaryText fontWeight="bold">{actionStrings.continue}</PrimaryText>
          <ArrowLeftSvg
            color={color[colorMode].primary}
            transform={'rotate(180deg)'}
            w="1rem"
            h="1rem"
            ml={spacing.XXS.rem}
          />
        </Flex>
      </NextLink>
      <IdeaCounter
        ideaCount={collection.ideaCount}
        readCount={collection.progress.totalReadIdeas}
        fontSize="0.875rem"
      />
    </Flex>
  );

  const renderStatus = () => {
    if (dataType === 'stash') {
      return startReadingStatus;
    } else if (dataType === 'collection') {
      const collection = data as unknown as CollectionData;
      if (!collection?.progress || collection.progress.totalReadIdeas === 0) {
        return startReadingStatus;
      } else if (collection.progress.totalReadIdeas === collection.ideaCount) {
        return doneReadingStatus;
      } else return getContinueReadingStatus(collection);
    } else return null;
  };

  const onClickHandler = (e: React.MouseEvent) => {
    logTapEvent();
    e.stopPropagation();
    onClick?.();
  };

  const content = (
    <Flex
      width={getRemWidthFromColumns(3)}
      aspectRatio={'9/16'}
      zIndex={2}
      pos="relative"
      cursor="pointer"
      onClick={onClick ? onClickHandler : logTapEvent}
      flexShrink={0}
      borderRadius={BORDER_RADIUS_M}
      overflow="hidden"
      transition="all 0.3s cubic-bezier(.11,.57,.44,.8)"
      boxShadow="0px 6px 16px rgba(0, 0, 0, 0.1); 0px 8px 4px rgba(0, 0, 0, 0.05);"
      _hover={{
        transform: 'scale(1.01)',
        boxShadow:
          '0px 6px 16px rgba(0, 0, 0, 0.2); 0px 8px 4px rgba(0, 0, 0, 0.1);',
      }}
      {...props}
    >
      <Flex w="100%" flexDir="column" position="absolute" bottom={0} zIndex={2}>
        {renderTitle()}
        {renderStatus()}
        {dataType === 'collection' && renderProgressBar()}
      </Flex>
      <Box
        w="100%"
        h="50%"
        position="absolute"
        bottom={0}
        background={createGradient(color[colorMode].top, -90)}
        zIndex={1}
      />
      {renderImage()}
    </Flex>
  );

  return onClick || !withLink ? (
    content
  ) : (
    <NextLink href={url}>{content}</NextLink>
  );
};
export default GroupCardSvg;
