import useRouter from 'hooks/useRouter';
import Head from 'next/head';
import React from 'react';
import useProfile from 'src/providers/hooks/useProfile';
import { PageMetadata } from 'types';
import { Box } from 'deepstash-ui';

/**
 * SEO Usage | All <head> tags & metadata are to be sent to <HeadMetadata /> only.
 * ----_app.js
 *    |
 *    |---- /specific-page | sending getSpecificPageMetadata({optional_params}) to
 *    |    |                 the 'pageMetadata' prop of <HeadMetadata/>
 *    |    |
 *    |    |---- Layout.tsx | passing 'pageMetadata' object down to always-used HeadMetadata
 *    |    |   |
 *    |    |   |---- HeadMetadata.tsx | populating <head> based on 'pageMetadata'
 *    |    |
 *    |    |---- HeadMetadata.tsx | for custom layouts, ALWAYS import <HeadMetadata />
 *    |                                at the top level and populate it by generating a
 *    |                                getNewPageMetadata({optional_params}) function and
 *    |                                passing its output to <HeadMetadata />
 *    |
 *    |---- document.tsx | populating head with [favicon, font preloading, default image preloading]
 */

/**
 * @param type - page type
 * @param data - contains any relevant metadata such as image links
 * @param pageTitle - [bot & users must-have]. Used to show the page title for: the tab bar, Google search results
 * @param pageDescription - [bot must-have]. Used by Google under the title for search results to provide more context.
 *
 * For reference, check https://www.notion.so/deepstash/SEO-Structure-Checklists-1e36b2b3f8314351b6c67ae184b3e85a
 */
interface OwnProps {
  pageMetadata: PageMetadata;
}

const HeadMetadata: React.FC<OwnProps> = ({ pageMetadata }) => {
  const router = useRouter();
  const routerPath = router.asPath.split('?')[0].split('#')[0];
  const { profile } = useProfile();

  /**
   * The robots tag [bot must-have] is used by bots to know whether to index the page in their search results.
   * User-only pages, such as search results or settings, are not usually up for indexing. [noindex]
   * Traffic-driving public pages, such as articles and ideas, definitely are. [index - default]
   */
  const robotsTag = (
    <meta name="robots" content={pageMetadata.robotsTag} key="robots" />
  );

  /**
   * Canonical tag [bot must-have]. Used by Google to know what page is the reference one when multiple versions are available.
   * Ex.: https://deepstash.com/article/123 will have the canonical url https://deepstash.com/article/123/the-correct-slug.
   * Our URLs have NO TRAILING SLASH. The trailing slash version redirects (301, permanently) to the slash-less one.**
   */
  const canonicalTag = <link rel="canonical" href={pageMetadata.canonical} />;

  /**
   * We use this function for inserting the description tag
   */
  const pageDescription = (
    <meta name="description" content={pageMetadata.pageDescription} />
  );

  /**
   * Based on the page type, we add additional metadata, such as OpenGraph tags (og:),
   * Twitter tags (for generating Twitter card previews), or Structured Data tags
   * (JSON-LD).
   *
   * For reference, check https://www.notion.so/deepstash/ad9f401a37504c1bae460e6fbafd534e?v=b4e4a2c7d48a449f92e6dc395727524d
   */
  const renderOpenGraphTags = () => {
    if (pageMetadata.openGraphTags) {
      return (
        <>
          <meta property="og:url" content={pageMetadata.canonical} />
          <meta property="og:type" content={pageMetadata.openGraphTags.type} />
          <meta
            property="og:title"
            content={pageMetadata.openGraphTags.title}
          />
          <meta
            property="og:description"
            content={pageMetadata.openGraphTags.description}
          />
          {pageMetadata.openGraphTags.image && (
            <meta
              property="og:image"
              content={pageMetadata.openGraphTags.image}
            />
          )}
          <meta property="og:site_name" content="Deepstash" />
          <meta
            property="author"
            content={pageMetadata.authorTag ?? 'Deepstash'}
          />
        </>
      );
    }
    return null;
  };

  const renderTwitterTags = () => {
    if (pageMetadata.openGraphTags) {
      return (
        <>
          <meta name="twitter:domain" defaultValue="deepstash.com" />
          <meta
            name="twitter:title"
            defaultValue={pageMetadata.openGraphTags.title}
          />
          <meta
            name="twitter:description"
            content={pageMetadata.openGraphTags.description}
          />
          {pageMetadata.openGraphTags.image && (
            <meta
              name="twitter:image"
              content={pageMetadata.openGraphTags.image}
            />
          )}
          <meta
            name="twitter:card"
            content={pageMetadata.twitterTags?.card ?? 'summary_large_image'}
          />
          <meta name="twitter:label1" defaultValue="Author" />
          <meta
            name="twitter:data1"
            defaultValue={pageMetadata.authorTag ?? 'Deepstash'}
          />
        </>
      );
    }
    return null;
  };

  /**
   * All pages should have a title. If no particular page title is given via the prop,
   * we append ' - Deepstash' to the end of the title. E.g.: My page - Deepstash.
   */
  const renderTitleTag = () => {
    if (router.asPath === '/') {
      return <title>{pageMetadata.pageTitle}</title>;
    } else {
      return (
        <title>
          {profile?.newNotificationsCount
            ? `(${profile?.newNotificationsCount}) `
            : ''}
          {pageMetadata.pageTitle ? pageMetadata.pageTitle + ' - ' : ''}
          Deepstash
        </title>
      );
    }
  };

  /**
   *  Schema Structured data
   *  JSON-LD
   */
  const getStrucutredData = () => {
    if (pageMetadata.structuredDataObjects) {
      return (
        <>
          {pageMetadata.structuredDataObjects.map((dataObject, index) => (
            <script key={index} {...dataObject} />
          ))}
        </>
      );
    }
    return null;
  };

  const iconTags = (
    <>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`https://static.deepstash.com/apple-touch-icon${
          !!profile?.newNotificationsCount ? '-unread-notifications' : ''
        }.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`https://static.deepstash.com/favicon-32x32${
          !!profile?.newNotificationsCount ? '-unread-notifications' : ''
        }.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`https://static.deepstash.com/favicon-16x16${
          !!profile?.newNotificationsCount ? '-unread-notifications' : ''
        }.png`}
      />
    </>
  );

  /**
   * https://developers.facebook.com/docs/applinks/metadata-reference/
   */
  const appLinkMetadata = (
    <>
      {/* Tag used for iOS app linking */}
      <meta
        property="al:ios:url"
        content={'https://deepstash.com' + routerPath}
      />
      <meta property="al:ios:app_store_id" content="1445023295" />
      <meta
        property="al:ios:app_name"
        content="Deepstash: Key Ideas for Work"
      />
      {/* Tag used for Android app linking */}
      <meta
        property="al:android:url"
        content={'https://deepstash.com' + routerPath}
      />
      <meta
        property="al:android:app_name"
        content="Deepstash: Key Ideas for Work"
      />
      <meta property="al:android:package" content="com.deepstash" />
      <meta
        property="al:web:url"
        content={'https://deepstash.com' + routerPath}
      />
    </>
  );

  return (
    <Box display="none">
      <Head>
        {renderTitleTag()}
        {pageDescription}
        {canonicalTag}
        {robotsTag}
        {getStrucutredData()}
        {renderOpenGraphTags()}
        {renderTwitterTags()}
        {iconTags}
        {appLinkMetadata}
      </Head>
    </Box>
  );
};

export default HeadMetadata;
