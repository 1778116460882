import {
  Box,
  BoxProps,
  color,
  Flex,
  PrimaryText,
  spacing,
  StyleProps,
  Text,
  typography,
  useColorMode,
  useIsMobileView,
} from 'deepstash-ui';
import dynamic from 'next/dynamic';
import React, { useMemo } from 'react';
import AnimateInView from 'src/components/animation/AnimateInView';
import FullWidthSectionWrapper from 'src/components/layout/components/FullWidthSectionWrapper';
import SectionHeader from 'src/components/layout/components/SectionHeader';
import { ProBadgeSvg } from 'theme/icons/ProBadgeSvg';
import { DeepstashBenefit } from 'types/types';
import { PRO_BENEFITS_LIST, PRO_COLOR } from 'utils/constants';
import {
  BLOCK_WIDTH_REM,
  GAP_SIZE_REM,
  getRemWidthFromColumns,
} from 'utils/global';
import commonStrings from 'utils/strings/commonStrings';
import pageStrings from 'utils/strings/pageStrings';

const ProComparisonTableDesktop = dynamic(
  () => import('./ProComparisonTableDesktop'),
);
ProComparisonTableDesktop.displayName = 'ProComparisonTableDesktop';

const HEADING_TEXT_STYLE: StyleProps = {
  fontSize: spacing.toRem(20),
  lineHeight: '1.3em',
  textAlign: 'center',
};

const ProComparisonSection: React.FC<BoxProps> = ({ ...props }) => {
  const { colorMode } = useColorMode();
  const isMobileView = useIsMobileView();

  const BENEFIT_LIST: DeepstashBenefit[] = useMemo(
    () =>
      PRO_BENEFITS_LIST.map(benefit => {
        return {
          name: (
            <Text
              {...typography.primaryTextFonts.large.regular}
              color={color[colorMode].textSecondary}
            >
              {benefit.name.map((text, index) =>
                index % 2 === 0 ? (
                  text
                ) : (
                  <span style={{ fontWeight: 800 }}>{text}</span>
                ),
              )}
            </Text>
          ),
          icon: benefit.renderIcon({
            color: PRO_COLOR[colorMode],
            height: spacing.toRem(32),
            width: spacing.toRem(32),
          }),
          accessLevel: benefit.accessLevel,
        };
      }),
    [colorMode],
  );

  const mobileLayout = (
    <Flex gridGap={spacing.M.rem} flexDirection="column" alignItems="start">
      <Flex
        mb={spacing.M.rem}
        flex={1}
        w="100%"
        justifyContent="center"
        textAlign="center"
      >
        <PrimaryText
          {...HEADING_TEXT_STYLE}
          textAlign="center"
          color={color[colorMode].text}
          width={{ base: '100%', md: getRemWidthFromColumns(1.5) }}
        >
          {commonStrings.with} deep<b>stash</b>
        </PrimaryText>
      </Flex>
      {BENEFIT_LIST.slice(0, 2).map((benefit, index) => (
        <Flex
          key={index}
          flexGrow={{ base: 0, md: 1 }}
          alignItems="center"
          gridGap={spacing.toRem(16)}
        >
          {benefit.icon}
          {benefit.name}
        </Flex>
      ))}
      <Flex
        mt={GAP_SIZE_REM}
        mb={spacing.M.rem}
        flex={1}
        flexDirection="column"
        alignItems="center"
        w="100%"
      >
        <Flex alignItems={'center'} gridGap={spacing.toRem(4)}>
          <PrimaryText {...HEADING_TEXT_STYLE} color={color[colorMode].text}>
            {commonStrings.with} deep<b>stash</b>
          </PrimaryText>
          <ProBadgeSvg height={20} />
        </Flex>
      </Flex>
      {BENEFIT_LIST.slice(2).map((benefit, index) => (
        <Flex
          key={index}
          flexGrow={{ base: 0, md: 1 }}
          alignItems="center"
          gridGap={spacing.toRem(16)}
        >
          {benefit.icon}
          {benefit.name}
        </Flex>
      ))}
    </Flex>
  );

  return (
    <Box w="100%" {...props}>
      <FullWidthSectionWrapper
        containerProps={{
          flexDirection: 'column',
          gridGap: { base: GAP_SIZE_REM, md: BLOCK_WIDTH_REM },
          alignItems: 'center',
        }}
      >
        <SectionHeader
          preTitle={pageStrings.landing.benefitsHeader.preTitle}
          title={pageStrings.landing.benefitsHeader.title}
          underlineColor={PRO_COLOR[colorMode]}
        />
        <AnimateInView
          variants={{
            visible: { opacity: 1, translateY: 0 },
            hidden: { opacity: 0, scale: 1, translateY: 32 },
          }}
        >
          {isMobileView ? (
            mobileLayout
          ) : (
            <ProComparisonTableDesktop benefits={BENEFIT_LIST} />
          )}
        </AnimateInView>
      </FullWidthSectionWrapper>
    </Box>
  );
};

export default ProComparisonSection;
