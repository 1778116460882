import { motion, useAnimation, Variants } from 'framer-motion';
import React, { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

interface AnimateInViewProps {
  /**
   * Duration of the animation, in seconds
   * @default 1 second
   */
  duration?: number;

  /**
   * Variants from hidden to visible
   * @default animates the opacity
   */
  variants?: Variants;
}

const AnimateInView: React.FC<AnimateInViewProps> = ({
  children,
  duration = 1,
  variants,
}) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: duration }}
      variants={
        variants ?? {
          visible: { opacity: 1 },
          hidden: { opacity: 0, scale: 1 },
        }
      }
    >
      {children}
    </motion.div>
  );
};

export default AnimateInView;
