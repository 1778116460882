import * as PurchasesApi from 'api/Purchases';
import { useEffect, useState } from 'react';
import { UserSubscriptionDetails } from 'types/models';
import { normalizeCurrentSubscription } from 'utils/normalizers/purchases';

const useCurrentSubscription = () => {
  const [currentSubscription, setCurrentSubscription] = useState<
    UserSubscriptionDetails | undefined
  >();
  const fetchCurrentSubscription = () => {
    PurchasesApi.getCurrentUserSubscription()
      .then(response => {
        const normalizedResponse = normalizeCurrentSubscription(response);

        setCurrentSubscription(normalizedResponse.subscriptionDetails);
      })
      .catch(error => {
        console.log(
          '[useCurrentSubscription]Error getCurrentUserSubscription',
          error,
        );
      });
  };

  useEffect(() => {
    fetchCurrentSubscription();
  }, []);

  return {
    currentSubscription,
    fetchCurrentSubscription,
  };
};

export default useCurrentSubscription;
