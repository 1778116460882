import { useRouter } from 'next/router';
import useAuth from 'providers/hooks/useAuth';

export const SKU_1YEAR_FREE_TRIAL = 'com.deepstash.pro_yearly_subscription';

/**
 * When a NSI user press a button which should start a subscription (eg: 'Start Trial')
 *
 * It will log in the user and then open the page /purchase/subscription
 */
const useAuthAndOpenPurchasePage = ({ sku }: { sku?: string }) => {
  const { isLoggedIn } = useAuth();
  const router = useRouter();

  const navigateToPurchaseSubscriptionPage = () => {
    router.push(`/purchase/subscription/?sku=${sku ?? SKU_1YEAR_FREE_TRIAL}`);
  };

  const onClickStartSubscriptionForNsi = () => {
    if (isLoggedIn) {
      navigateToPurchaseSubscriptionPage();
    } else {
      // nsiDispatch({
      //   type: 'openAuth',
      //   payload: {
      //     type: 'sign-up',
      //     onAuthSuccess: async profile => {
      //       if (profile?.subscription_type !== UserSubscriptionType.Premium) {
      //         navigateToPurchaseSubscriptionPage();
      //       }
      //     },
      //     shouldSkipOnboarding: true,
      //   },
      // });
      router.push('/setup');
    }
  };

  return {
    onClickStartSubscriptionForNsi,
  };
};

export default useAuthAndOpenPurchasePage;
