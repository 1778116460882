import {
  color,
  PrimaryHeading,
  PrimaryText,
  StyleProps,
  typography,
  useColorMode,
} from 'deepstash-ui';
import React from 'react';
import { HeadingSize } from 'types/globalTypes';
import { SQUARE_WIDTH_REM } from 'utils/global';

interface SubsectionHeaderProps {
  headingSize?: HeadingSize;
}

const SubsectionHeader: React.FC<SubsectionHeaderProps & StyleProps> = ({
  headingSize,
  children,
  ...props
}) => {
  const { colorMode } = useColorMode();

  return headingSize ? (
    <PrimaryHeading
      size={headingSize}
      color={color[colorMode].text}
      opacity={0.6}
      {...typography.primaryTextFonts.medium.bold}
      height={SQUARE_WIDTH_REM}
      {...props}
    >
      {children}
    </PrimaryHeading>
  ) : (
    <PrimaryText
      color={color[colorMode].text}
      opacity={0.6}
      {...typography.primaryTextFonts.medium.bold}
      height={SQUARE_WIDTH_REM}
      {...props}
    >
      {children}
    </PrimaryText>
  );
};

export default SubsectionHeader;
