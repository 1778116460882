import {
  AppStoreButton,
  Box,
  color,
  FacebookSvg,
  Flex,
  InstagramSvg,
  Link,
  spacing,
  StyleProps,
  Text,
  TikTokSvg,
  TwitterSvg,
  typography,
  useColorMode,
  useDeviceDetect,
  useIsMobileView,
  YouTubeSvg,
} from 'deepstash-ui';
import { useRouter } from 'next/router';
// import useProfile from 'providers/hooks/useProfile';
// import FooterStickyStartTrial from 'src/components/footer/FooterStickyStartTrial';
// import { UserSubscriptionType } from 'types/enums';
import React from 'react';
import NextLink from 'src/components/navigation/NextLink';
import NewsletterSection from 'src/components/newsletter/NewsletterSection';
import { Analytics, Events } from 'src/services/analytics';
import { getRemWidthFromColumns, SQUARE_WIDTH } from 'utils/global';
import FullWidthSectionWrapper from './FullWidthSectionWrapper';
import FooterStickyStartTrial from 'src/components/footer/FooterStickyStartTrial';
import useProfile from 'providers/hooks/useProfile';
import { UserSubscriptionType } from 'types/enums';
import LogoWithVersion from './LogoWithVersion';

export const FOOTER_SECTIONS = [
  {
    id: 0,
    title: 'Content',
    links: [
      { title: 'Ideas', href: '/articles/1' },
      { title: 'Collections', href: '/collections/1' },
      { title: 'Stories', href: '/stories' },
      { title: 'Explore', href: '/explore' },
    ],
  },
  {
    id: 1,
    title: 'Product',
    links: [
      // { title: 'How It Works', href: '/how-it-works' },
      { title: 'Pricing', href: '/get-pro' },
      { title: 'Businesses', href: '/for-teams' },
    ],
  },
  {
    id: 2,
    title: 'Resources',
    links: [
      { title: 'Terms', href: '/terms-of-service' },
      { title: 'Privacy', href: '/privacy-policy' },
      { title: 'Press Kit', href: '/press-kit' },
      { title: 'Sitemap', href: 'https://deepstash.com/sitemap.xml' },
    ],
  },
  {
    id: 3,
    title: 'Company',
    links: [
      { title: 'About', href: '/about' },
      { title: 'Contact', href: '/press-kit' },
    ],
  },
];

const SOCIAL_NETWORKS = [
  {
    icon: <InstagramSvg />,
    link: 'https://instagram.com/deepstash',
  },
  {
    icon: <FacebookSvg />,
    link: 'https://www.facebook.com/deepstash/',
  },
  {
    icon: <TwitterSvg />,
    link: 'https://twitter.com/deepstash',
  },
  {
    icon: <TikTokSvg />,
    link: 'https://www.tiktok.com/@deepstash.app',
  },
  {
    icon: <YouTubeSvg />,
    link: 'https://www.youtube.com/c/Deepstash',
  },
];

interface FooterProps {
  disableNewsletter?: boolean;
  disableStickyStartTrial?: boolean;
}

const Footer = React.forwardRef<HTMLDivElement, FooterProps & StyleProps>(
  ({ disableNewsletter, disableStickyStartTrial, ...props }, ref) => {
    const { colorMode } = useColorMode();
    const isMobileView = useIsMobileView();
    const { isIos } = useDeviceDetect();
    const router = useRouter();
    const { profile } = useProfile();

    const isProUser =
      profile?.subscriptionType === UserSubscriptionType.Premium;

    return (
      <>
        {isProUser === false &&
        (disableStickyStartTrial === undefined ||
          disableStickyStartTrial === false) ? (
          <FooterStickyStartTrial />
        ) : null}
        {disableNewsletter === true || profile ? null : (
          <FullWidthSectionWrapper>
            <NewsletterSection
              containerProps={{
                mt: 64,
                mb: 32,
                width: '100%',
              }}
            />
          </FullWidthSectionWrapper>
        )}
        <Box w="100%" ref={ref} zIndex={3}>
          <FullWidthSectionWrapper
            {...props}
            backgroundColor={color[colorMode].underground}
            pt={16}
            pb={60}
            containerProps={{
              color: color[colorMode].text,
              flexDirection: { base: 'column', md: 'row' },
              paddingX: { base: 16, md: 0 },
            }}
          >
            <Flex
              flexDirection="column"
              alignItems="left"
              width={{
                base: '100%',
                md: getRemWidthFromColumns(2),
                lg: getRemWidthFromColumns(4),
              }}
              gridGap="1rem"
            >
              <LogoWithVersion />
              <Flex gridGap="1rem">
                {SOCIAL_NETWORKS.map((sn, index) => (
                  <Link
                    key={index}
                    target="_blank"
                    href={sn.link}
                    rel="noopener nofollow noreferrer"
                  >
                    {sn.icon}
                  </Link>
                ))}
              </Flex>
              <Flex
                gridGap={{ base: 0, lg: '1rem' }}
                flexDirection={{ base: 'column', lg: 'row' }}
              >
                {((isMobileView && isIos) || !isMobileView) && (
                  <AppStoreButton
                    _hover={undefined}
                    pr={0}
                    pb={0}
                    pl="1rem"
                    pt={spacing.XXS.rem}
                    store="Apple"
                    w={getRemWidthFromColumns(2)}
                    h={spacing.toRem(SQUARE_WIDTH * 1.5)}
                    {...(router.pathname === '/producthunt'
                      ? {
                          url: 'https://apps.apple.com/us/app/deepstash-smarter-every-day/id1445023295?ppid=84e3bc79-ff76-4783-a01c-217fd55d891f',
                        }
                      : {})}
                  />
                )}
                {((isMobileView && !isIos) || !isMobileView) && (
                  <AppStoreButton
                    _hover={undefined}
                    pr={0}
                    pb={0}
                    pl="1rem"
                    pt={spacing.XXS.rem}
                    store="GooglePlay"
                    w={getRemWidthFromColumns(2)}
                    h={spacing.toRem(SQUARE_WIDTH * 1.5)}
                  />
                )}
              </Flex>
            </Flex>
            {FOOTER_SECTIONS.map(section => (
              <Flex
                flexDirection="column"
                key={section.id}
                alignItems="left"
                width={{
                  base: '100%',
                  md: getRemWidthFromColumns(1.5),
                  lg: getRemWidthFromColumns(2),
                }}
                gridGap="1rem"
              >
                <Text {...typography.primaryHeadingFonts.small.bold}>
                  {section.title}
                </Text>
                {section.links.map((link, index) =>
                  link.href.startsWith('https') ? (
                    <Link href={link.href}>{link.title}</Link>
                  ) : (
                    <NextLink key={index} href={link.href} passHref>
                      <Link _focus={undefined}>
                        <Text
                          onClick={() => {
                            Analytics.logEvent({
                              eventName: Events.consumption.footerTap,
                              properties: {
                                button: '',
                              },
                              platforms: ['amplitude'],
                            });
                          }}
                        >
                          {link.title}
                        </Text>
                      </Link>
                    </NextLink>
                  ),
                )}
              </Flex>
            ))}
          </FullWidthSectionWrapper>
        </Box>
      </>
    );
  },
);

export default Footer;
