import React from 'react';
import {
  Flex,
  Heading,
  Text,
  //   useIsMobileView,
  typography,
  color,
} from 'deepstash-ui';
import Lottie from 'src/components/animation/Lottie';

interface ValuePropItemProps {
  preTitle: string;
  title: string;
  subtitle: React.ReactChild;
  startingIllustration: React.ReactElement;
  lottieAnimationData: any;
  direction: 'left' | 'right';
}

const ValuePropItem: React.FC<ValuePropItemProps> = ({
  preTitle,
  title,
  subtitle,
  startingIllustration,
  lottieAnimationData,
  direction = 'left',
}) => {
  //   const isMobileView = useIsMobileView();

  return (
    <Flex
      justifyContent="space-between"
      gridColumnGap={{ sm: 16, md: 64 }}
      flexDirection={{
        sm: 'column',
        md: direction === 'left' ? 'row' : 'row-reverse',
      }}
      gridRowGap={16}
    >
      <Flex flexDirection="column" flex={1.3} gridRowGap={16}>
        <Flex flexDirection="column">
          <Text
            {...typography.primaryTextFonts.large.bold}
            color={color.light.text}
            textTransform="uppercase"
            letterSpacing={2}
            textAlign={{ sm: 'center', md: 'unset' }}
          >
            {preTitle}
          </Text>
          <Heading
            size="h4"
            {...typography.primaryHeadingFonts.large.semiBold}
            color={color.light.text}
            textAlign={{ sm: 'center', md: 'unset' }}
          >
            {title}
          </Heading>
        </Flex>
        <Text
          {...typography.primaryTextFonts.large.regular}
          color={color.light.textSecondary}
          fontSize={{ sm: '1rem', md: '1.3rem' }}
          lineHeight={{ sm: '1.3rem', md: '2.1rem' }}
          textAlign={{ sm: 'center', md: 'unset' }}
        >
          {subtitle}
        </Text>
      </Flex>
      <Flex flex={1}>
        <Flex
          position="relative"
          alignItems="center"
          borderRadius={16}
          overflow="hidden"
          mx={{ sm: 48, md: 0 }}
        >
          {startingIllustration}
          <Lottie
            style={{
              position: 'relative',
              // width: '100%',
              // height: '100%',
              cursor: 'default',
              bgColor: 'blue',
            }}
            options={{
              loop: true,
              autoplay: false,
              animationData: lottieAnimationData,
            }}
            isClickToPauseDisabled={true}
            speed={1}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ValuePropItem;
