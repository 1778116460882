import { CollectionApiResponse } from 'api/api.types';
import { Flex, StyleProps } from 'deepstash-ui';
import React from 'react';
import CollectionCard from './CollectionCard';
import { GAP_SIZE_REM } from 'utils/global';

interface NewCollectionCarouselProps {
  collections: Partial<CollectionApiResponse>[];
}

const NewCollectionCarousel: React.FC<
  NewCollectionCarouselProps & StyleProps
> = ({ collections, ...props }) => {
  return (
    <Flex
      gridGap={GAP_SIZE_REM}
      justifyContent="left"
      width="100%"
      overflowX="scroll"
      alignItems="flex-start"
      {...props}
    >
      {collections.map((collection, index) => {
        return (
          <CollectionCard collection={collection} key={`collection-${index}`} />
        );
      })}
    </Flex>
  );
};

export default NewCollectionCarousel;
