import {
  Box,
  color,
  Flex,
  FlexProps,
  Grid,
  Heading,
  IconProps,
  PrimaryHeading,
  spacing,
  Text,
  typography,
  useColorMode,
  useIsMobileView,
} from 'deepstash-ui';
import React from 'react';
import ParallaxEffect from 'src/components/animation/ParallaxEffect';
import BaseCard from 'src/components/cards/BaseCard';
import GroupCardSvg from 'src/components/cards/GroupCardSvg';
import PreviewCardSmall from 'src/components/cards/PreviewCardSmall';
import FullWidthSectionWrapper from 'src/components/layout/components/FullWidthSectionWrapper';
import SectionTitle from 'src/components/layout/components/SectionTitle';
import PlayIllustration from 'src/page-components/public-pages/get-pro-page/PlayIllustration';
import SpottyConnectionIllustraton from 'src/page-components/public-pages/get-pro-page/SpottyConnectionIllustration';
import PhoneFrame from 'src/page-components/public-pages/illustrations/PhoneFrame';
import { UserStash } from 'types/models';
import {
  BLOCK_WIDTH,
  BLOCK_WIDTH_REM,
  formatStringToHashtagCase,
  GAP_SIZE,
  GAP_SIZE_REM,
  getPixelWidthFromColumns,
  getRemWidthFromColumns,
  SECTION_GAP_REM,
  SQUARE_WIDTH,
  SQUARE_WIDTH_REM,
} from 'utils/global';
import { LANDING_PAGE_COLLECTIONS } from 'utils/landingPageData';
import { METATOPICS } from 'utils/metatopics.constants';
import pageStrings from 'utils/strings/pageStrings';

const SOUND_WAVE_HEIGHTS = [
  '60%',
  '20%',
  '40%',
  '30%',
  '90%',
  '80%',
  '25%',
  '50%',
  '20%',
];

const PHONE_FRAME_PROPS: IconProps = {
  position: 'absolute',
  w: {
    base: '100%',
    md: getPixelWidthFromColumns(4),
    lg: getPixelWidthFromColumns(6),
  },
  h: {
    base: 'calc((100vw - 64) * 2.12)',
    md: getPixelWidthFromColumns(4) * 2.12,
    lg: getPixelWidthFromColumns(6) * 2.12,
  },
};

interface ProBenefitsSectionProps extends FlexProps {
  ctaButton?: React.ReactNode;
}

const ProBenefitsSection: React.FC<ProBenefitsSectionProps> = ({
  ctaButton,
  ...props
}) => {
  const { colorMode } = useColorMode();
  const isMobileView = useIsMobileView();

  const renderCardHeader = ({
    title,
    description,
    extraIcon,
  }: {
    title: string;
    description: string;
    extraIcon?: React.ReactElement;
  }) => (
    <Flex
      width="100%"
      height="auto"
      p={{ base: SQUARE_WIDTH_REM, md: BLOCK_WIDTH_REM }}
      pb={{ base: BLOCK_WIDTH_REM }}
      color={color[colorMode].textSecondary}
      flexDirection="column"
      textAlign={{ base: 'center', md: 'left' }}
    >
      <PrimaryHeading
        size="h4"
        {...typography.primaryHeadingFonts.medium.regular}
        mb={spacing.M.rem}
      >
        {title}
      </PrimaryHeading>
      {extraIcon ? (
        <Flex
          alignItems="center"
          gridGap={GAP_SIZE_REM}
          flexDirection={{ base: 'column', md: 'row' }}
        >
          <Text>{description}</Text>
          {extraIcon}
        </Flex>
      ) : (
        <Text>{description}</Text>
      )}
    </Flex>
  );

  const renderCollectionCard = (collection: UserStash) => (
    <GroupCardSvg data={collection} dataType={'collection'} />
  );

  const exploreSection = (
    <FullWidthSectionWrapper
      pb={{ base: SECTION_GAP_REM, md: spacing.toRem(GAP_SIZE * 6) }}
      containerProps={{
        alignItems: 'top',
        flexDirection: { base: 'column', md: 'row' },
      }}
      backgroundColor={props.backgroundColor ?? color[colorMode].background}
    >
      <Flex
        justifyContent="center"
        width={{
          base: '100%',
          md: getRemWidthFromColumns(4),
          lg: getRemWidthFromColumns(6),
        }}
        flexDirection="column"
        textAlign={{ base: 'center', md: 'left' }}
      >
        <SectionTitle
          preTitle={
            pageStrings['get-pro'].conceptExplanationTexts[0].headers[0]
              .preTitle
          }
          title={
            pageStrings['get-pro'].conceptExplanationTexts[0].headers[0].title
          }
          mb={GAP_SIZE_REM}
        />
        <Heading
          w={{ base: '100%', md: getRemWidthFromColumns(4) }}
          mb={{ base: GAP_SIZE_REM, md: spacing.toRem(GAP_SIZE * 3) }}
          size="h3"
          color={color[colorMode].text}
          {...typography.primaryTextFonts.large.regular}
        >
          <b>{pageStrings['get-pro'].conceptExplanationTexts[0].texts[0]}</b>
          {pageStrings['get-pro'].conceptExplanationTexts[0].texts[1]}
        </Heading>
        <BaseCard width="100%" height="auto" flexDirection="column">
          {renderCardHeader(
            pageStrings['get-pro'].conceptExplanationTexts[0].cardTexts[0],
          )}
          <ParallaxEffect offset={16}>
            <Flex h={spacing.toRem(384)} position="relative">
              <Box
                w="100%"
                h="100%"
                zIndex={2}
                p={GAP_SIZE_REM}
                pt={spacing.toRem(128)}
              >
                <ParallaxEffect offset={32}>
                  <Grid
                    gridGap={spacing.XL.rem}
                    w="100%"
                    h="100%"
                    gridTemplateColumns={{
                      base: '1fr 1fr',
                      md: '1fr 1fr 1fr 1fr',
                    }}
                  >
                    {METATOPICS.map((metatopic, index) => (
                      <PreviewCardSmall
                        key={metatopic.id}
                        w="auto"
                        h="auto"
                        name={metatopic.name}
                        imageUrl={metatopic.image}
                        gridColumn={{
                          base: (index + 1) % 2,
                          md: (index + 1) % 4,
                        }}
                        targetUrl={
                          '/topic/' + formatStringToHashtagCase(metatopic.name)
                        }
                        hasShadow
                        hasText={false}
                        p={spacing.M.rem}
                      />
                    ))}
                  </Grid>
                </ParallaxEffect>
              </Box>
              <Text
                position="absolute"
                w="auto"
                h="auto"
                top={{ base: spacing.toRem(64), md: spacing.toRem(72) }}
                left={{ base: spacing.toRem(32), md: spacing.toRem(64) }}
                {...typography.primaryHeadingFonts.small.bold}
                zIndex={1}
              >
                {pageStrings['get-pro'].phoneTexts.knowledgeLibrary}
              </Text>
              <PhoneFrame
                {...PHONE_FRAME_PROPS}
                backgroundColor={color[colorMode].background}
              />
            </Flex>
          </ParallaxEffect>
        </BaseCard>
      </Flex>
      <BaseCard
        width={{
          base: '100%',
          md: getRemWidthFromColumns(4),
          lg: getRemWidthFromColumns(6),
        }}
        height={spacing.toRem(824)}
        flexDirection="column"
      >
        {renderCardHeader(
          pageStrings['get-pro'].conceptExplanationTexts[0].cardTexts[1],
        )}
        <ParallaxEffect offset={16}>
          <Flex position="relative">
            <Box
              w="100%"
              h="100%"
              zIndex={2}
              p={GAP_SIZE_REM}
              pt={spacing.toRem(128)}
            >
              <ParallaxEffect offset={32}>
                <Grid
                  gridGap={spacing.XL.rem}
                  w="100%"
                  h="100%"
                  gridTemplateColumns={{
                    base: '1fr',
                    md: '1fr 1fr',
                  }}
                  gridRowGap={spacing.toRem(2 * SQUARE_WIDTH)}
                >
                  {LANDING_PAGE_COLLECTIONS.slice(6, 8).map(collection =>
                    renderCollectionCard(collection),
                  )}
                </Grid>
              </ParallaxEffect>
            </Box>
            <Text
              position="absolute"
              w="auto"
              h="auto"
              top={{ base: spacing.toRem(64), md: spacing.toRem(72) }}
              left={{
                base: spacing.toRem(32),
                md: spacing.toRem(64 + BLOCK_WIDTH),
              }}
              {...typography.primaryHeadingFonts.small.bold}
              zIndex={1}
            >
              {pageStrings['get-pro'].phoneTexts.personalGrowth}
            </Text>
            <PhoneFrame
              left={{ base: 0, md: BLOCK_WIDTH_REM }}
              {...PHONE_FRAME_PROPS}
              backgroundColor={color[colorMode].background}
            />
          </Flex>
        </ParallaxEffect>
      </BaseCard>
      {isMobileView && ctaButton}
    </FullWidthSectionWrapper>
  );

  const takeAnywhereSection = (
    <FullWidthSectionWrapper
      containerProps={{
        alignItems: 'top',
        flexDirection: { base: 'column', md: 'row' },
      }}
      backgroundColor={props.backgroundColor ?? color[colorMode].background}
    >
      <Flex
        justifyContent="center"
        width={{
          base: '100%',
          md: getRemWidthFromColumns(4),
          lg: getRemWidthFromColumns(6),
        }}
        flexDirection="column"
      >
        <SectionTitle
          preTitle={
            pageStrings['get-pro'].conceptExplanationTexts[1].headers[0]
              .preTitle
          }
          title={
            pageStrings['get-pro'].conceptExplanationTexts[1].headers[0].title
          }
          mb={GAP_SIZE_REM}
        />
        <Heading
          w={{ base: '100%', md: getRemWidthFromColumns(4) }}
          mb={{ base: GAP_SIZE_REM, md: spacing.toRem(GAP_SIZE * 3) }}
          size="h3"
          color={color[colorMode].text}
          {...typography.primaryTextFonts.large.regular}
          textAlign={{ base: 'center', md: 'left' }}
        >
          {pageStrings['get-pro'].conceptExplanationTexts[1].texts[0]}
        </Heading>
        <BaseCard
          width="100%"
          height="auto"
          mb={GAP_SIZE_REM}
          overflow="hidden"
          flexDirection="column"
        >
          {renderCardHeader(
            pageStrings['get-pro'].conceptExplanationTexts[1].cardTexts[0],
          )}
          <ParallaxEffect offset={16}>
            <Flex h={spacing.toRem(160)} position="relative">
              <Flex
                zIndex={1}
                width="40%"
                height={spacing.toRem(96)}
                transform="translateX(-50%) translateY(-50%)"
                left="75%"
                position="absolute"
                top="50%"
                justifyContent="center"
                gridGap="0.5rem"
                alignItems="center"
              >
                {SOUND_WAVE_HEIGHTS.map((height, index) => (
                  <Box
                    key={index}
                    w="0.5rem"
                    h={height}
                    backgroundColor={color.light.primary.default}
                    borderRadius="0.25rem"
                  />
                ))}
              </Flex>
              <PhoneFrame
                left="-50%"
                {...PHONE_FRAME_PROPS}
                backgroundColor={color[colorMode].background}
              />
              <PlayIllustration
                position="absolute"
                w="100%"
                left={{ base: '-70%', md: '-43%' }}
                top={64}
              />
            </Flex>
          </ParallaxEffect>
        </BaseCard>
        <BaseCard width="100%" height="auto">
          {renderCardHeader({
            extraIcon: (
              <SpottyConnectionIllustraton
                w={spacing.toRem(78)}
                h={spacing.toRem(78)}
                flexShrink={0}
              />
            ),
            ...pageStrings['get-pro'].conceptExplanationTexts[1].cardTexts[1],
          })}
        </BaseCard>
      </Flex>
      <BaseCard
        width={{
          base: '100%',
          md: getRemWidthFromColumns(4),
          lg: getRemWidthFromColumns(6),
        }}
        height={spacing.toRem(824)}
        flexDirection="column"
      >
        {renderCardHeader(
          pageStrings['get-pro'].conceptExplanationTexts[1].cardTexts[2],
        )}
        <ParallaxEffect offset={16}>
          <Flex position="relative">
            <Box
              w="100%"
              h="100%"
              zIndex={2}
              p={GAP_SIZE_REM}
              pt={spacing.toRem(128)}
            >
              <ParallaxEffect offset={32}>
                <Grid
                  gridGap={spacing.XXXL.rem}
                  w="100%"
                  h="100%"
                  gridTemplateColumns={{
                    base: '1fr',
                    md: '1fr 1fr',
                  }}
                >
                  {LANDING_PAGE_COLLECTIONS.slice(8, 10).map(stash =>
                    renderCollectionCard(stash),
                  )}
                </Grid>
              </ParallaxEffect>
              <Text
                position="absolute"
                w="auto"
                h="auto"
                top={{ base: spacing.toRem(64), md: spacing.toRem(72) }}
                left={{
                  base: spacing.toRem(32),
                  md: spacing.toRem(64 + BLOCK_WIDTH),
                }}
                {...typography.primaryHeadingFonts.small.bold}
                zIndex={1}
              >
                {pageStrings['get-pro'].phoneTexts.myStashes}
              </Text>
            </Box>
            <PhoneFrame
              left={{ base: 0, md: BLOCK_WIDTH_REM }}
              {...PHONE_FRAME_PROPS}
              backgroundColor={color[colorMode].background}
            />
          </Flex>
        </ParallaxEffect>
      </BaseCard>
      {isMobileView && ctaButton}
    </FullWidthSectionWrapper>
  );

  return (
    <Box w="100%" {...props}>
      {exploreSection}
      {takeAnywhereSection}
    </Box>
  );
};

export default ProBenefitsSection;
