import {
  LayoutMetaContentPosition,
  LayoutState,
} from '../../layout/Layout.types';
import { ElementMeasurement } from 'hooks/measurements';
import React, { useCallback, useMemo } from 'react';

export const useLayoutMetaContent = ({
  setMetaContentPosition,
  sectionMeasurements,
  layoutStateRef,
  contentMeasurements,
  metaContentMeasurements,
  stickyOffsets,
  topOffsets,
  isSectionInfinite,
  sectionDisplayedHeights,
}: {
  setMetaContentPosition: (
    val: LayoutMetaContentPosition,
    index: number,
  ) => void;
  sectionMeasurements: ElementMeasurement[];
  layoutStateRef: React.RefObject<LayoutState>;
  contentMeasurements: ElementMeasurement[];
  metaContentMeasurements: ElementMeasurement[];
  /**
   * The sticky offset of the sections
   */
  stickyOffsets: (number | undefined)[];
  /**
   * The top offsets of the sections
   */
  topOffsets: (number | undefined)[];
  isSectionInfinite: boolean[];

  sectionDisplayedHeights: number[];
}) => {
  // The scroll position when the meta content should be fixed
  const stickThresholds = useMemo(() => {
    const thresholds: number[] = [];
    for (let i = 0; i < sectionMeasurements.length; i++) {
      thresholds.push(
        sectionMeasurements[i].y -
          (stickyOffsets?.[i] ?? 0) +
          (topOffsets?.[i] ?? 0),
      );
    }
    return thresholds;
  }, [sectionMeasurements, stickyOffsets, topOffsets]);

  const bottomThresholds = useMemo(() => {
    const thresholds: number[] = [];
    for (let i = 0; i < sectionMeasurements.length; i++) {
      thresholds.push(
        sectionMeasurements[i].y +
          sectionDisplayedHeights[i] -
          metaContentMeasurements[i].height -
          (stickyOffsets?.[i] ?? 0),
      );
    }
    return thresholds;
  }, [contentMeasurements, metaContentMeasurements, stickyOffsets]);

  const updateMetaContentPosition = useCallback(
    (scrollTop: number) => {
      for (
        let sectionIndex = 0;
        sectionIndex < sectionMeasurements.length;
        sectionIndex++
      ) {
        // If the meta content is bigger than the content, it will scroll with the content from the start
        if (
          metaContentMeasurements[sectionIndex].height >=
            contentMeasurements[sectionIndex].height &&
          layoutStateRef.current?.metaContentPositions[sectionIndex] !== 'top'
        ) {
          setMetaContentPosition('top', sectionIndex);
        }

        // The scroll position when the meta content should be placed at the bottom of the section and start scrolling with the section
        const bottomThreshold = bottomThresholds[sectionIndex];

        const topThreshold = stickThresholds[sectionIndex];

        if (
          layoutStateRef.current?.metaContentPositions[sectionIndex] !==
            'fixed' &&
          scrollTop >= topThreshold &&
          scrollTop <= bottomThreshold
        ) {
          setMetaContentPosition('fixed', sectionIndex);
        }

        if (
          layoutStateRef.current?.metaContentPositions[sectionIndex] !==
            'top' &&
          scrollTop < topThreshold
        ) {
          setMetaContentPosition('top', sectionIndex);
        }

        if (
          layoutStateRef.current?.metaContentPositions[sectionIndex] !==
            'bottom' &&
          scrollTop > bottomThreshold &&
          !isSectionInfinite[sectionIndex]
        ) {
          setMetaContentPosition('bottom', sectionIndex);
        }
      }
    },
    [
      setMetaContentPosition,
      sectionMeasurements,
      layoutStateRef,
      contentMeasurements,
      metaContentMeasurements,
      stickyOffsets,
    ],
  );
  return { updateMetaContentPosition, bottomThresholds, stickThresholds };
};
