import {
  BookSvg,
  color,
  DownloadSvg,
  Flex,
  HeadphonesSvg,
  LibrarySvg,
  PhoneSvg,
  PrimaryText,
  spacing,
  StashedSvg,
  StyleProps,
  Text,
  typography,
  useColorMode,
  useIsMobileView,
} from 'deepstash-ui';
import dynamic from 'next/dynamic';
import React, { useMemo } from 'react';
import { HistorySvg } from 'theme/icons/HistorySvg';
import { ProBadgeSvg } from 'theme/icons/ProBadgeSvg';
import { DeepstashBenefit } from 'types/types';
import { GAP_SIZE_REM, getRemWidthFromColumns } from 'utils/global';
import commonStrings from 'utils/strings/commonStrings';

const ProComparisonTableDesktop = dynamic(
  () =>
    import(
      '../../../components/page-sections/value-props/ProComparisonTableDesktop'
    ),
);
ProComparisonTableDesktop.displayName = 'ProComparisonTableDesktop';

const HEADING_TEXT_STYLE: StyleProps = {
  fontSize: spacing.toRem(20),
  lineHeight: '1.3em',
  textAlign: 'center',
};

interface ProComparisonProps {}

const ProComparison: React.FC<ProComparisonProps> = ({}) => {
  const { colorMode } = useColorMode();
  const isMobileView = useIsMobileView();

  const BENEFIT_LIST: DeepstashBenefit[] = useMemo(
    () => [
      {
        name: (
          <Text
            {...typography.primaryTextFonts.large.regular}
            color={color[colorMode].textSecondary}
          >
            {commonStrings.benefits[0][0]}
            <span style={{ fontWeight: 800 }}>
              {commonStrings.benefits[0][1]}
            </span>
          </Text>
        ),
        icon: (
          <LibrarySvg
            color={color.light.pro.primary}
            height={spacing.toRem(32)}
            width={spacing.toRem(32)}
          />
        ),
        accessLevel: 'si',
      },
      {
        name: (
          <Text
            {...typography.primaryTextFonts.large.regular}
            color={color[colorMode].textSecondary}
          >
            {commonStrings.benefits[1][0]}
            <span style={{ fontWeight: 800 }}>
              {commonStrings.benefits[1][1]}
            </span>
          </Text>
        ),
        icon: (
          <PhoneSvg
            color={color.light.pro.primary}
            height={spacing.toRem(32)}
            width={spacing.toRem(32)}
          />
        ),
        accessLevel: 'si',
      },
      {
        name: (
          <Text
            {...typography.primaryTextFonts.large.regular}
            color={color[colorMode].textSecondary}
          >
            {commonStrings.benefits[2][0]}
            <span style={{ fontWeight: 800 }}>
              {commonStrings.benefits[2][1]}
            </span>
            {commonStrings.benefits[2][2]}
          </Text>
        ),
        icon: (
          <StashedSvg
            color={color.light.pro.primary}
            height={spacing.toRem(32)}
            width={spacing.toRem(32)}
          />
        ),
        accessLevel: 'pro',
      },
      {
        name: (
          <Text
            {...typography.primaryTextFonts.large.regular}
            color={color[colorMode].textSecondary}
          >
            {commonStrings.benefits[3][0]}
            <span style={{ fontWeight: 800 }}>
              {commonStrings.benefits[3][1]}
            </span>
          </Text>
        ),
        icon: (
          <HistorySvg
            color={color.light.pro.primary}
            height={spacing.toRem(32)}
            width={spacing.toRem(32)}
          />
        ),
        accessLevel: 'pro',
      },
      {
        name: (
          <Text
            {...typography.primaryTextFonts.large.regular}
            color={color[colorMode].textSecondary}
          >
            {commonStrings.benefits[4][0]}
            <span style={{ fontWeight: 800 }}>
              {commonStrings.benefits[4][1]}
            </span>
            {commonStrings.benefits[4][2]}
          </Text>
        ),
        icon: (
          <HeadphonesSvg
            color={color.light.pro.primary}
            height={spacing.toRem(32)}
            width={spacing.toRem(32)}
          />
        ),
        accessLevel: 'pro',
      },
      {
        name: (
          <Text
            {...typography.primaryTextFonts.large.regular}
            color={color[colorMode].textSecondary}
          >
            {commonStrings.benefits[5][0]}
            <span style={{ fontWeight: 800 }}>
              {commonStrings.benefits[5][1]}
            </span>
            {commonStrings.benefits[5][2]}
          </Text>
        ),
        icon: (
          <DownloadSvg
            color={color.light.pro.primary}
            height={spacing.toRem(32)}
            width={spacing.toRem(32)}
          />
        ),
        accessLevel: 'pro',
      },
      {
        name: (
          <Text
            {...typography.primaryTextFonts.large.regular}
            color={color[colorMode].textSecondary}
          >
            {commonStrings.benefits[6][0]}
            <span style={{ fontWeight: 800 }}>
              {commonStrings.benefits[6][1]}
            </span>
          </Text>
        ),
        icon: (
          <BookSvg
            color={color.light.pro.primary}
            height={spacing.toRem(32)}
            width={spacing.toRem(32)}
          />
        ),
        accessLevel: 'pro',
      },
    ],
    [colorMode],
  );

  const mobileLayout = (
    <Flex gridGap={spacing.M.rem} flexDirection="column" alignItems="start">
      <Flex
        mb={spacing.M.rem}
        flex={1}
        w="100%"
        justifyContent="center"
        textAlign="center"
      >
        <PrimaryText
          {...HEADING_TEXT_STYLE}
          textAlign="center"
          color={color[colorMode].text}
          width={{ base: '100%', md: getRemWidthFromColumns(1.5) }}
        >
          {commonStrings.with} deep<b>stash</b>
        </PrimaryText>
      </Flex>
      {BENEFIT_LIST.slice(0, 2).map((benefit, index) => (
        <Flex
          key={index}
          flexGrow={{ base: 0, md: 1 }}
          alignItems="center"
          gridGap={spacing.toRem(16)}
        >
          {benefit.icon}
          {benefit.name}
        </Flex>
      ))}
      <Flex
        mt={GAP_SIZE_REM}
        mb={spacing.M.rem}
        flex={1}
        flexDirection="column"
        alignItems="center"
        w="100%"
      >
        <Flex alignItems={'center'} gridGap={spacing.toRem(4)}>
          <PrimaryText {...HEADING_TEXT_STYLE} color={color[colorMode].text}>
            {commonStrings.with} deep<b>stash</b>
          </PrimaryText>
          <ProBadgeSvg height={20} />
        </Flex>
      </Flex>
      {BENEFIT_LIST.slice(2).map((benefit, index) => (
        <Flex
          key={index}
          flexGrow={{ base: 0, md: 1 }}
          alignItems="center"
          gridGap={spacing.toRem(16)}
        >
          {benefit.icon}
          {benefit.name}
        </Flex>
      ))}
    </Flex>
  );

  return (
    <Flex w="100%" justifyContent="center" mt={32}>
      {isMobileView ? (
        mobileLayout
      ) : (
        <ProComparisonTableDesktop benefits={BENEFIT_LIST} />
      )}
    </Flex>
  );
};

export default ProComparison;
