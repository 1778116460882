import React from 'react';
import { Flex, useIsMobileView, Text, typography, color } from 'deepstash-ui';
import NextImage from 'src/components/image/NextImage';
import { LANDING_PAGE_DEFAULT_PX } from '../LandingPageV2';
import LandingStartTrialButton from './LandingStartTrialButton';

const TITLE_PHONE = 'Got 5 minutes? Plenty for:';
// TODO No Trial Changes
// const CTA_TEXT = 'Start my free week';
const CTA_TEXT = 'Start today';

interface LandingMobileAppSectionProps {}

const LandingMobileAppSection: React.FC<
  LandingMobileAppSectionProps
> = ({}) => {
  const isMobileView = useIsMobileView();

  return (
    <Flex
      width={'100%'}
      flexDirection={{ md: 'column-reverse', lg: 'row' }}
      gridColumnGap={64}
      paddingX={LANDING_PAGE_DEFAULT_PX}
      mt={88}
      alignItems={{ md: 'center', lg: 'initial' }}
    >
      <Flex flex={1} width={'100%'} justifyContent="flex-end">
        <Flex flexDirection="column">
          <Text
            {...(isMobileView
              ? { ...typography.primaryHeadingFonts.medium.semiBold }
              : { ...typography.primaryHeadingFonts.large.semiBold })}
            color={color.light.text}
          >
            {TITLE_PHONE}
          </Text>
          <Text
            {...(isMobileView
              ? { ...typography.primaryHeadingFonts.medium.regular }
              : { ...typography.primaryHeadingFonts.medium.semiBold })}
            color={color.light.textSecondary}
            mt={16}
          >
            <ul style={{ marginLeft: 32 }}>
              <li>a Book</li>
              <li>a Podcast</li>
              <li>an Article</li>
            </ul>
          </Text>
          <Flex mt={32}>
            <LandingStartTrialButton
              text={CTA_TEXT}
              landingLocation={'mobile-app-section'}
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex flex={1.3}>
        <NextImage
          wrapperStyle={{
            width: 500,
            height: 408,
            pointerEvents: 'none',
          }}
          objectFit="contain"
          imageUrl={'https://static.deepstash.com/phone-landing-image.png'}
        />
      </Flex>
    </Flex>
  );
};

export default LandingMobileAppSection;
