import {
  Flex,
  StyleProps,
  Text,
  TextProps,
  color,
  typography,
} from 'deepstash-ui';
import React from 'react';
import LandingStartTrialButton from './LandingStartTrialButton';
import ProComparison from './ProComparison';

const TITLE_PRO = 'A small investment, an incredible growth opportunity';
const SUBTITLE_PRO =
  'Get access to 200,000+ powerful ideas from top books, podcasts, and more.';

// TODO No Trial Changes
// const CTA_TEXT = 'Start my free week';
const CTA_TEXT = 'Get Deepstash Pro today';

interface LandingProSectionProps {
  showProComparison: boolean;
  boxTextProps?: TextProps;
  landingLocation?: string;
  trialInfoStyleProps?: StyleProps;
}

const LandingProSection: React.FC<LandingProSectionProps & StyleProps> = ({
  showProComparison,
  boxTextProps,
  landingLocation = 'pro-section',
  trialInfoStyleProps,
  ...props
}) => {
  // TODO No Trial Changes
  // const { stripePrices, requestStatus } = useStripePrices({
  //   requestNsi: true,
  // });

  // const freeTrialSubscription = stripePrices?.subscriptions['1 YEAR'];

  // const normalizedPrice = `$${freeTrialSubscription?.pricePerMonth}/month`;

  // const seventhDayFormatted = React.useMemo(() => {
  //   return getSeventhDateFromToday();
  // }, []);

  return (
    <Flex
      px={{ base: 16, md: 0 }}
      flexDirection="column"
      alignItems="center"
      mt={{ sm: 16, md: 88 }}
      {...props}
    >
      {showProComparison ? <ProComparison /> : null}
      <Flex
        justifyContent="space-between"
        flexDirection="column"
        alignItems="center"
        mt={64}
        width="100%"
      >
        <Text
          {...typography.primaryHeadingFonts.large.semiBold}
          color={color.light.text}
          textAlign="center"
        >
          {TITLE_PRO}
        </Text>
        <Text
          {...typography.primaryTextFonts.large.regular}
          color={color.light.textSecondary}
          textAlign="center"
          mt={8}
        >
          {SUBTITLE_PRO}
        </Text>
        {/* TODO No Trial Changes */}
        {/* <LandingTrialSteps /> */}
        <Flex
          flexDirection={{ lg: 'row', md: 'column', sm: 'column' }}
          width={'100%'}
          bgColor={'#CCE2EF'}
          borderRadius={8}
          mt={{ sm: 16, md: 64 }}
          py={32}
          px={{ sm: 16, md: 48, lg: 64 }}
          justifyContent="space-between"
          gridColumnGap={32}
          gridRowGap={16}
          {...trialInfoStyleProps}
        >
          {/* TODO No Trial Changes */}
          <Text
            {...typography.primaryTextFonts.large.regular}
            color={color.light.text}
            {...boxTextProps}
          >
            Enjoy unlimited access to 100+ tailored journeys & all mobile app
            features.
          </Text>
          {/* {requestStatus === 'success' ? (
            <Text
              {...typography.primaryTextFonts.large.regular}
              color={color.light.text}
              {...boxTextProps}
            >
              Enjoy unlimited access for 7 days. Love it and keep investing in
              yourself for only <strong>{normalizedPrice}</strong>, or{' '}
              <strong>
                simply cancel before {seventhDayFormatted} and you will never be
                charged.
              </strong>
            </Text>
            
          ) : null} */}
          <Flex flexDirection="column" justifyContent="center" width={'100%'}>
            <LandingStartTrialButton
              text={CTA_TEXT}
              landingLocation={landingLocation}
              bgColor={color.light.pro.primary}
              _active={{ bgColor: '#082E4E' }}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LandingProSection;
