import React from 'react';
import {
  Flex,
  useIsMobileView,
  Text,
  typography,
  color,
  PrimaryButton,
} from 'deepstash-ui';
import useRouter from 'hooks/useRouter';
import LandingStartTrialButton from './LandingStartTrialButton';

const TITLE = 'Start your journey today';
// TODO No Trial Changes
// const SUBTITLE1 =
//   'Embark on the journey of becoming better & smarter with a 7-day free trial.';
// const SUBTITLE2 = "Cancel anytime if Deepstash doesn't work for you.";
const SUBTITLE1 = 'Embark on the journey of becoming better & smarter.';
const SUBTITLE2 = 'Get the mobile app & never stop exploring.';

// TODO No Trial Changes
// const CTA_TEXT = 'Start my free week';
const CTA_TEXT = 'Start today';

interface LandingStartJourneySectionProps {}

const LandingStartJourneySection: React.FC<
  LandingStartJourneySectionProps
> = ({}) => {
  const isMobileView = useIsMobileView();
  const router = useRouter();

  return (
    <Flex
      paddingX={{ base: 16, md: 0 }}
      justifyContent="space-between"
      flexDirection="column"
      alignItems="center"
      mt={24}
      width={'100%'}
    >
      <Text
        {...typography.primaryHeadingFonts.large.semiBold}
        color={color.light.text}
        textAlign="center"
      >
        {TITLE}
      </Text>
      <Text
        {...typography.primaryTextFonts.large.regular}
        color={color.light.textSecondary}
        textAlign="center"
        mt={8}
      >
        {SUBTITLE1}
        <br />
        {SUBTITLE2}
      </Text>
      <Flex
        mt={32}
        gridColumnGap={32}
        flexDirection={{ sm: 'column', md: 'row' }}
        gridRowGap={16}
        flex={1}
        w="100%"
        justifyContent="center"
      >
        <LandingStartTrialButton
          text={CTA_TEXT}
          landingLocation={'start-journey'}
        />
        <PrimaryButton
          size="lg"
          type="button"
          borderRadius={16}
          bgColor={color.light.top}
          color={color.light.text}
          _active={{ bgColor: color.light.surface }}
          onClick={() => {
            router.push('/collections/1');
          }}
          {...(isMobileView
            ? typography.primaryTextFonts.medium.semiBold
            : typography.primaryHeadingFonts.small.semiBold)}
        >
          {'Explore Library'}
        </PrimaryButton>
      </Flex>
    </Flex>
  );
};

export default LandingStartJourneySection;
