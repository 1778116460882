import { useEffect, useRef, useState } from 'react';
import { ElementMeasurement } from 'hooks/measurements/useMeasureElement';

/**
 * Get a ref function to measure the width and height of an element
 * Also get the position of the element
 * @param elementCount This will init the measurementsList to have at least this many elements so we don't get any undefined elements accessing
 * @param relativeTo Whether the position measurement is relative to the whole document or the viewport
 */

const DEFAULT_MEASUREMENT = {
  x: 0,
  y: 0,
  width: 0,
  height: 0,
};

export const useMeasureElementList = <TType extends HTMLElement>({
  elementCount,
  relativeTo = 'document',
  debugId,
  initialState,
}: {
  elementCount: number;
  relativeTo?: 'document' | 'viewport';
  debugId?: string;
  initialState?: ElementMeasurement[];
}) => {
  const [measurementsList, setMeasurementsList] = useState<
    ElementMeasurement[]
  >(() => {
    // Init the state with elementCount elements
    const state = initialState ? [...initialState] : [];

    const elementsLeftToPush = elementCount - state.length;

    for (let i = 0; i < elementsLeftToPush; i++) {
      state.push(DEFAULT_MEASUREMENT);
    }

    return state;
  });
  const elementRefs = useRef<TType[]>([]);

  useEffect(() => {
    if (measurementsList.length < elementCount) {
      setMeasurementsList(prevState => {
        const newState = [...prevState];
        // Push the remaining elements
        for (let i = 0; i < elementCount - measurementsList.length; i++) {
          newState.push(DEFAULT_MEASUREMENT);
        }
        return newState;
      });
    }
  }, [elementCount]);

  const getElementSizeAndPosition = (element: TType, index: number) => {
    const incomingX =
      element.getBoundingClientRect().x +
      (relativeTo === 'document' ? window.scrollX : 0);
    const incomingY =
      element.getBoundingClientRect().y +
      (relativeTo === 'document' ? window.scrollY : 0);

    if (
      incomingX !== measurementsList[index]?.x ||
      incomingY !== measurementsList[index]?.y ||
      element.clientWidth !== measurementsList[index].width ||
      element.clientHeight !== measurementsList[index].height
    ) {
      //Debug
      if (debugId) {
        console.log(
          `Hook ${debugId} - element ${index} :  X changed: ${
            incomingX !== measurementsList[index]?.x
          }; Y changed: ${
            incomingY !== measurementsList[index]?.y
          }; Width changed:${
            element.clientWidth !== measurementsList[index].width
          }; Height changed:${
            element.clientHeight !== measurementsList[index].height
          }`,
        );
        console.log(`New state for Hook ${debugId} - element ${index}:`, {
          width: element.clientWidth,
          height: element.clientHeight,
          x: incomingX,
          y: incomingY,
        });
      }

      // The dimensions of the element changed
      setMeasurementsList(prevState => {
        const newState = [...prevState];
        newState[index] = {
          width: element.clientWidth,
          height: element.clientHeight,
          x: incomingX,
          y: incomingY,
        };
        return newState;
      });
    }
  };

  const measureElement = (element?: TType | null, index?: number) => {
    if (element === null) {
      // The ref prop of the JSX element is still not set.
      // Nothing to do yet
      return;
    }

    if (element === undefined || index === undefined) {
      // Recalculate the size and position of the saved elements
      for (let i = 0; i < elementRefs.current.length; i++) {
        const refElement = elementRefs.current[i];
        getElementSizeAndPosition(refElement, i);
      }
    }

    if (element && index !== undefined) {
      elementRefs.current[index] = element;
      getElementSizeAndPosition(element, index);
    }
  };

  return { measurementsList, measureElement };
};
