import * as React from 'react';
import { Icon, IconProps } from 'deepstash-ui';

export const WorkIcon = React.memo<IconProps>(props => {
  return (
    <Icon width="48px" height="48px" viewBox="0 0 48 48" fill="none" {...props}>
      <path
        d="M23.8079 19.6917C23.8991 19.6917 27.2353 19.7489 27.2478 19.8383C28.0537 19.8455 29.11 21.6594 29.2298 22.3382C29.2423 22.4366 30.0223 27.5392 30.1207 27.5267C30.177 27.7315 30.0375 27.9927 30.1359 28.092C30.3854 27.8979 30.5339 27.583 30.4874 27.2503L29.7665 21.4697C29.5912 20.0825 28.407 19.0378 27.0108 19.0378H23.1067V19.5289L23.8079 19.6917Z"
        fill="#FECA9A"
      />
      <path
        d="M26.1179 20.9106C26.4828 20.9106 27.5982 21.1799 27.6482 21.5376C27.8495 21.5394 28.6375 26.1787 28.667 26.3487C28.7171 26.7422 29.2403 28.2797 29.6338 28.2296C29.7752 28.2672 29.8548 28.286 29.9666 28.2368C30.2098 28.0471 30.3547 27.7404 30.3091 27.4157L29.6052 21.7782C29.4335 20.425 28.2788 19.4062 26.9175 19.4062H23.0993L23.093 20.8373L26.1179 20.9106Z"
        fill="#FCA640"
      />
      <path
        d="M24.1904 19.6917C24.0992 19.6917 20.763 19.7489 20.7505 19.8383C19.9446 19.8455 18.8883 21.6594 18.7685 22.3382C18.7559 22.4366 17.976 27.5392 17.8776 27.5267C17.8213 27.7315 17.9608 27.9927 17.8624 28.092C17.6129 27.8979 17.4644 27.583 17.5109 27.2503L18.2318 21.4697C18.4071 20.0825 19.5913 19.0378 20.9875 19.0378H24.8916V19.5289L24.1904 19.6917Z"
        fill="#FECA9A"
      />
      <path
        d="M21.8805 20.9106C21.5155 20.9106 20.4002 21.1799 20.3501 21.5376C20.1489 21.5394 19.3609 26.1787 19.3314 26.3487C19.2813 26.7422 18.7581 28.2797 18.3645 28.2296C18.2232 28.2672 18.1436 28.286 18.0318 28.2368C17.7885 28.0471 17.6437 27.7404 17.6893 27.4157L18.3932 21.7782C18.5649 20.425 19.7196 19.4062 21.0809 19.4062H24.8991L24.9054 20.8373L21.8805 20.9106Z"
        fill="#FCA640"
      />
      <path
        d="M28.8094 21.9223C28.6458 20.6307 27.5224 20.2014 26.2318 20.2041C26.1817 20.2041 26.0001 20.205 25.95 20.205H24.5494H23.3446L23.1067 20.459V20.6164L23.8428 21.3168H24.5181H25.5055H26.5886C26.7022 21.3168 27.1074 21.3534 27.1924 21.4169C27.4374 21.4983 27.6333 21.6924 27.7227 21.9428C27.754 21.9903 27.797 22.2264 27.8059 22.2854L28.119 24.7889L28.1431 24.9838L28.4275 27.2619C28.4445 27.3987 28.5832 27.9336 28.6985 27.9873C28.7808 28.176 28.8577 28.2118 28.9848 28.1894C29.0974 28.1697 29.2298 28.1098 29.4015 28.0526C29.5259 27.9703 29.5795 27.6885 29.5357 27.3138C29.5017 26.9873 29.2853 25.2996 29.2146 24.8094C29.093 23.9633 28.8094 21.9223 28.8094 21.9223Z"
        fill="#F58319"
      />
      <path
        d="M19.2972 21.9223C19.4609 20.6307 20.5843 20.2014 21.8749 20.2041C21.925 20.2041 22.1066 20.205 22.1567 20.205H23.5573H24.7621L25 20.459V20.6164L24.2639 21.3168H23.5886H22.6012H21.5181C21.4045 21.3168 20.9993 21.3534 20.9143 21.4169C20.6693 21.4983 20.4734 21.6924 20.3839 21.9428C20.3526 21.9903 20.3097 22.2264 20.3008 22.2854L19.9877 24.7889L19.9636 24.9838L19.6792 27.2619C19.6622 27.3987 19.5235 27.9336 19.4081 27.9873C19.3259 28.176 19.2489 28.2118 19.1219 28.1894C19.0092 28.1697 18.8769 28.1098 18.7051 28.0526C18.5808 27.9703 18.5272 27.6885 18.571 27.3138C18.605 26.9873 18.8214 25.2996 18.8921 24.8094C19.0137 23.9633 19.2972 21.9223 19.2972 21.9223Z"
        fill="url(#paint0_radial_210_88259)"
      />
      <path
        d="M24.0001 42.7825C29.4338 42.7825 33.8386 38.3776 33.8386 32.944C33.8386 27.5103 29.4338 23.1055 24.0001 23.1055C18.5665 23.1055 14.1616 27.5103 14.1616 32.944C14.1616 38.3776 18.5665 42.7825 24.0001 42.7825Z"
        fill="url(#paint1_linear_210_88259)"
      />
      <path
        d="M24.0003 41.0717C28.489 41.0717 32.1278 37.4328 32.1278 32.9442C32.1278 28.4555 28.489 24.8167 24.0003 24.8167C19.5116 24.8167 15.8728 28.4555 15.8728 32.9442C15.8728 37.4328 19.5116 41.0717 24.0003 41.0717Z"
        fill="url(#paint2_linear_210_88259)"
      />
      <path
        d="M24.0001 40.2157C28.016 40.2157 31.2716 36.9601 31.2716 32.9442C31.2716 28.9282 28.016 25.6726 24.0001 25.6726C19.9841 25.6726 16.7285 28.9282 16.7285 32.9442C16.7285 36.9601 19.9841 40.2157 24.0001 40.2157Z"
        fill="url(#paint3_linear_210_88259)"
      />
      <path
        d="M13.8125 5.21729L22.2557 17.0324C22.5777 17.4886 22.7566 18.0342 22.7566 18.5887V20.4223H25.4747V18.5887C25.4747 18.0342 25.2958 17.4886 24.9738 17.0324L16.5306 5.21729H13.8125Z"
        fill="url(#paint4_linear_210_88259)"
      />
      <path
        d="M11.4783 5.21729L19.9215 17.0324C20.2435 17.4886 20.4224 18.0342 20.4224 18.5887V20.4223H22.8373V18.5887C22.8373 18.0342 22.6584 17.4886 22.3364 17.0324L13.8932 5.21729H11.4783Z"
        fill="url(#paint5_linear_210_88259)"
      />
      <path
        d="M16.3889 5.21729L24.8321 17.0324C25.1541 17.4886 25.333 18.0342 25.333 18.5887V20.4223H27.578V18.5619C27.578 17.9984 27.3991 17.4528 27.0771 16.9967L18.6339 5.21729H16.3889Z"
        fill="url(#paint6_linear_210_88259)"
      />
      <path
        d="M34.1874 5.21729L25.7441 17.0324C25.4221 17.4886 25.2433 18.0342 25.2433 18.5887V20.4223H22.5251V18.5887C22.5251 18.0342 22.704 17.4886 23.026 17.0324L31.4692 5.21729H34.1874Z"
        fill="url(#paint7_linear_210_88259)"
      />
      <path
        d="M36.5216 5.21729L33.9636 8.79492L30.7705 13.267L29.4915 15.0558L28.0784 17.0324C27.7564 17.4886 27.5775 18.0342 27.5775 18.5887V20.4223H25.1626V18.5887C25.1626 18.0342 25.3415 17.4886 25.6635 17.0324L27.0766 15.0558L28.3556 13.267L31.5487 8.79492L34.1067 5.21729H36.5216Z"
        fill="url(#paint8_linear_210_88259)"
      />
      <path
        d="M36.5216 5.21729L33.9636 8.79492L30.7705 13.267L29.4915 15.0558L28.0784 17.0324C27.7564 17.4886 27.5775 18.0342 27.5775 18.5887V20.4223H25.1626V18.5887C25.1626 18.0342 25.3415 17.4886 25.6635 17.0324L27.0766 15.0558L28.3556 13.267L31.5487 8.79492L34.1067 5.21729H36.5216Z"
        fill="url(#paint9_linear_210_88259)"
      />
      <path
        d="M31.6114 5.21729L29.0534 8.79492L25.8604 13.267L24.5814 15.0558L23.1682 17.0324C22.8462 17.4886 22.6673 18.0342 22.6673 18.5887V20.4223H20.4224V18.5619C20.4224 17.9984 20.6012 17.4528 20.9232 16.9967L22.3185 15.0558L23.5975 13.267L26.7995 8.79492L28.0875 7.0061L29.3665 5.21729H31.6114Z"
        fill="url(#paint10_linear_210_88259)"
      />
      <path
        d="M22.8373 18.6335V22.2112H21.3168C20.8248 22.2112 20.4224 21.8087 20.4224 21.3168V19.528C20.4224 19.036 20.8248 18.6335 21.3168 18.6335H22.8373Z"
        fill="url(#paint11_linear_210_88259)"
      />
      <path
        d="M25.2434 18.6335H22.6675V22.2112H25.2434V18.6335Z"
        fill="url(#paint12_linear_210_88259)"
      />
      <path
        d="M27.5775 19.528V21.3168C27.5775 21.8087 27.175 22.2112 26.6831 22.2112H25.1626V18.6335H26.6831C27.175 18.6335 27.5775 19.036 27.5775 19.528Z"
        fill="url(#paint13_linear_210_88259)"
      />
      <path
        d="M27.5775 19.528V21.3168C27.5775 21.8087 27.175 22.2112 26.6831 22.2112H25.1626V18.6335H26.6831C27.175 18.6335 27.5775 19.036 27.5775 19.528Z"
        fill="url(#paint14_linear_210_88259)"
      />
      <path
        opacity="0.6"
        d="M35.2428 7.00635L33.9638 8.79517L30.7708 13.2672L29.4918 15.056H22.3186L23.5976 13.2672L26.7996 8.79517L28.0875 7.00635H35.2428Z"
        fill="url(#paint15_linear_210_88259)"
      />
      <path
        d="M26.4041 27.083H22.9346L20.187 29.0623V32.7876L22.9346 31.0408V38.7479H26.4041V27.083Z"
        fill="url(#paint16_linear_210_88259)"
      />
      <path
        d="M24.0001 42.7825C29.4338 42.7825 33.8386 38.3776 33.8386 32.944C33.8386 27.5103 29.4338 23.1055 24.0001 23.1055C18.5665 23.1055 14.1616 27.5103 14.1616 32.944C14.1616 38.3776 18.5665 42.7825 24.0001 42.7825Z"
        fill="url(#paint17_radial_210_88259)"
      />
      <path
        d="M24.0003 41.0717C28.489 41.0717 32.1278 37.4328 32.1278 32.9442C32.1278 28.4555 28.489 24.8167 24.0003 24.8167C19.5116 24.8167 15.8728 28.4555 15.8728 32.9442C15.8728 37.4328 19.5116 41.0717 24.0003 41.0717Z"
        fill="url(#paint18_radial_210_88259)"
      />
      <path
        d="M24.0003 41.0717C28.489 41.0717 32.1278 37.4328 32.1278 32.9442C32.1278 28.4555 28.489 24.8167 24.0003 24.8167C19.5116 24.8167 15.8728 28.4555 15.8728 32.9442C15.8728 37.4328 19.5116 41.0717 24.0003 41.0717Z"
        fill="url(#paint19_radial_210_88259)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_210_88259"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.8374 25.1513) rotate(180) scale(3.47568 3.61714)"
        >
          <stop stopColor="#F58319" />
          <stop offset="0.856" stopColor="#F5A919" />
          <stop offset="0.997" stopColor="#F5A919" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_210_88259"
          x1="14.6822"
          y1="22.1368"
          x2="30.9721"
          y2="41.0303"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFB46E" />
          <stop offset="0.352" stopColor="#FFA826" />
          <stop offset="0.453" stopColor="#FDA623" />
          <stop offset="0.579" stopColor="#F6911B" />
          <stop offset="0.663" stopColor="#F09813" />
          <stop offset="1" stopColor="#F08D13" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_210_88259"
          x1="31.1869"
          y1="40.1307"
          x2="15.3728"
          y2="24.3167"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFBC6E" />
          <stop offset="0.352" stopColor="#FFA826" />
          <stop offset="0.453" stopColor="#FD8C23" />
          <stop offset="0.579" stopColor="#F6911B" />
          <stop offset="0.663" stopColor="#F07D13" />
          <stop offset="1" stopColor="#F08D13" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_210_88259"
          x1="17.7884"
          y1="25.5823"
          x2="30.1196"
          y2="40.1969"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFDECB" />
          <stop offset="0.359375" stopColor="#FFCD92" />
          <stop offset="1" stopColor="#F99132" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_210_88259"
          x1="15.079"
          y1="6.18056"
          x2="22.4149"
          y2="16.6559"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9EAE7" />
          <stop offset="0.616" stopColor="#F0D7D5" />
          <stop offset="0.69" stopColor="#E0C5C3" />
          <stop offset="0.896" stopColor="#B89896" />
          <stop offset="1" stopColor="#A88685" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_210_88259"
          x1="12.2179"
          y1="6.00258"
          x2="20.6299"
          y2="17.6111"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.614" stopColor="#FEA032" />
          <stop offset="0.751" stopColor="#E2772A" />
          <stop offset="0.907" stopColor="#C85E22" />
          <stop offset="1" stopColor="#BF591F" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_210_88259"
          x1="16.8746"
          y1="5.83174"
          x2="23.4351"
          y2="14.8063"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BB5F1D" />
          <stop offset="0.638" stopColor="#AE6A03" />
          <stop offset="0.746" stopColor="#985C02" />
          <stop offset="0.906" stopColor="#7D3D01" />
          <stop offset="1" stopColor="#733801" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_210_88259"
          x1="28.3558"
          y1="6.43011"
          x2="28.3558"
          y2="21.5447"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF8F5" />
          <stop offset="0.278" stopColor="#FFF2EF" />
          <stop offset="0.427" stopColor="#FFF8F5" />
          <stop offset="0.508" stopColor="#FBEEEC" />
          <stop offset="0.63" stopColor="#F0D7D5" />
          <stop offset="1" stopColor="#B59997" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_210_88259"
          x1="30.8421"
          y1="4.81748"
          x2="30.8421"
          y2="19.8158"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6C47" />
          <stop offset="0.2" stopColor="#FF6744" />
          <stop offset="0.445" stopColor="#FE583C" />
          <stop offset="0.657" stopColor="#FE4532" />
          <stop offset="1" stopColor="#D43829" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_210_88259"
          x1="25.9023"
          y1="6.00258"
          x2="34.3142"
          y2="17.6111"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.614" stopColor="#FEA032" />
          <stop offset="0.751" stopColor="#E2772A" />
          <stop offset="0.907" stopColor="#C85E22" />
          <stop offset="1" stopColor="#BF591F" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_210_88259"
          x1="20.908"
          y1="5.83174"
          x2="27.4685"
          y2="14.8063"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BB5F1D" />
          <stop offset="0.638" stopColor="#AE6A03" />
          <stop offset="0.746" stopColor="#985C02" />
          <stop offset="0.906" stopColor="#7D3D01" />
          <stop offset="1" stopColor="#733801" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_210_88259"
          x1="21.5"
          y1="18.5"
          x2="21.7864"
          y2="21.9764"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BB5F1D" />
          <stop offset="0.25" stopColor="#AE6A03" />
          <stop offset="0.536458" stopColor="#985C02" />
          <stop offset="0.734375" stopColor="#7D3D01" />
          <stop offset="1" stopColor="#733801" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_210_88259"
          x1="23.9554"
          y1="18.9189"
          x2="23.9554"
          y2="22.475"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF8F5" />
          <stop offset="0.274" stopColor="#FBEEEC" />
          <stop offset="0.689" stopColor="#F0D7D5" />
          <stop offset="1" stopColor="#B59997" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_210_88259"
          x1="26.3701"
          y1="18.5396"
          x2="26.3701"
          y2="22.0681"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF6C47" />
          <stop offset="0.2" stopColor="#FF6744" />
          <stop offset="0.445" stopColor="#FE583C" />
          <stop offset="0.657" stopColor="#FE4532" />
          <stop offset="1" stopColor="#D43829" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_210_88259"
          x1="26.5"
          y1="18.5"
          x2="26.8348"
          y2="21.9677"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FEA032" />
          <stop offset="0.583333" stopColor="#E2772A" />
          <stop offset="0.776042" stopColor="#C85E22" />
          <stop offset="1" stopColor="#BF591F" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_210_88259"
          x1="28.7807"
          y1="7.72545"
          x2="28.7807"
          y2="14.5364"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFF8F5" stopOpacity="0" />
          <stop offset="0.54" stopColor="#FFF8F5" stopOpacity="0.6" />
          <stop offset="1" stopColor="#FFF8F5" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_210_88259"
          x1="9.46751"
          y1="13.2384"
          x2="35.0485"
          y2="46.3504"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD494" />
          <stop offset="0.352" stopColor="#FFA826" />
          <stop offset="0.581" stopColor="#F38A2A" />
          <stop offset="1" stopColor="#F07D13" />
        </linearGradient>
        <radialGradient
          id="paint17_radial_210_88259"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.0833 34.1541) scale(13.6487)"
        >
          <stop stopColor="#FFDFC2" stopOpacity="0" />
          <stop offset="0.725" stopColor="#FFDFC2" stopOpacity="0" />
          <stop offset="0.83" stopColor="#FFDCC2" />
          <stop offset="0.962" stopColor="#FFDFC2" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint18_radial_210_88259"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24.3616 31.2984) scale(12.2382)"
        >
          <stop stopColor="#FFE477" stopOpacity="0" />
          <stop offset="0.725" stopColor="#FFE477" stopOpacity="0" />
          <stop offset="0.83" stopColor="#FFEEAB" />
          <stop offset="0.962" stopColor="#FFE477" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint19_radial_210_88259"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24.3616 31.2984) scale(12.2382)"
        >
          <stop stopColor="#FFE3C2" stopOpacity="0" />
          <stop offset="0.725" stopColor="#FFD8C2" stopOpacity="0" />
          <stop offset="0.83" stopColor="#FFE3C2" />
          <stop offset="0.962" stopColor="#FFDFC2" stopOpacity="0" />
        </radialGradient>
      </defs>
    </Icon>
  );
});

export default WorkIcon;
