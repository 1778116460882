import {
  AppStoreColoredSvg,
  color,
  Flex,
  FullStarSvg,
  GooglePlayColoredSvg,
  HalfStarSvg,
  spacing,
  StyleProps,
  Text,
  typography,
  useColorMode,
} from 'deepstash-ui';
import * as React from 'react';
import { SQUARE_WIDTH_REM } from 'utils/global';
import commonStrings from 'utils/strings/commonStrings';

interface StoreRatingCardProps {
  store: 'ios' | 'android';
}

const StoreRatingCard: React.FC<StoreRatingCardProps & StyleProps> = ({
  store,
  ...props
}) => {
  const { colorMode } = useColorMode();

  return (
    <Flex
      height="100%"
      width="100%"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      cursor="pointer"
      py={SQUARE_WIDTH_REM}
      onClick={() => {
        window
          ?.open(
            store === 'android'
              ? 'https://play.google.com/store/apps/details?id=com.deepstash'
              : ' https://apps.apple.com/us/app/deepstash-smarter-every-day/id1445023295',
            '_blank',
          )
          ?.focus();
      }}
      gridGap={spacing.M.rem}
      {...props}
    >
      <Flex alignItems="center">
        {Array(store === 'ios' ? 5 : 4)
          .fill(1)
          .map((_, index) => (
            <FullStarSvg
              key={index}
              color={color[colorMode].secondary.default}
              boxSize="1.5rem"
            />
          ))}
        {store === 'android' && (
          <HalfStarSvg
            color={color[colorMode].secondary.default}
            boxSize="1.5rem"
          />
        )}{' '}
        <Flex ml={spacing.XS.rem} alignItems="baseline" gridGap="4px">
          <Text {...typography.primaryHeadingFonts.medium.regular}>
            {commonStrings.paywall.ratings[store].rating}
          </Text>
          {/* <Text
            {...typography.primaryTextFonts.medium.bold}
            color={color[colorMode].textSecondary}
          >
            {commonStrings.paywall.ratings.stars}
          </Text> */}
        </Flex>
      </Flex>

      <Text
        {...typography.primaryHeadingFonts.small.semiBold}
        color={color[colorMode].text}
      >
        {commonStrings.paywall.ratings[store].reviewCount}
      </Text>
      <Flex
        alignItems="center"
        gridGap={spacing.XS.rem}
        height={spacing.toRem(32)}
      >
        {store === 'ios' ? <AppStoreColoredSvg /> : <GooglePlayColoredSvg />}
        <Text
          {...typography.primaryTextFonts.large.regular}
          color={color[colorMode].textSecondary}
        >
          {commonStrings.paywall.ratings[store].title}
        </Text>
      </Flex>
    </Flex>
  );
};

export default StoreRatingCard;
