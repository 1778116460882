import { FlexProps, useToast } from 'deepstash-ui';
import Cookie from 'js-cookie';
import useAuth from 'src/providers/hooks/useAuth';
import React, { useEffect } from 'react';
import CookieAgreementToast from 'src/components/toasts/CookieAgreementToast';

export default function useCookieAgreementToast(flexProps?: FlexProps): void {
  const toast = useToast();
  const { authDispatch } = useAuth();

  useEffect(() => {
    setTimeout(() => {
      const consentCookie = Cookie.get('cookieConsent');
      if (!consentCookie) {
        toast({
          id: 'consent',
          // eslint-disable-next-line react/display-name
          render: onClose => {
            return (
              <CookieAgreementToast
                {...flexProps}
                setHasAgreed={() => {
                  authDispatch({
                    type: 'agree-cookies',
                  });
                }}
                onClose={onClose}
              />
            );
          },
        });
      }
    }, 15000);
  }, []);

  return;
}
