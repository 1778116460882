export const LANDING_HERO_CARDS: { imageUrl: string; name: string }[] = [
  {
    imageUrl:
      'https://audio.deepstash.com/covers/collection/202304/productivity_hacks.png',
    name: 'Productivity Hacks',
  },
  {
    imageUrl:
      'https://audio.deepstash.com/covers/collection/202304/think_outside_the_box.png',
    name: 'Think Outside the Box',
  },
  {
    imageUrl:
      'https://audio.deepstash.com/covers/collection/202304/managing_time_like_a_pro.png',
    name: 'Manage Time Like a Pro',
  },
  {
    imageUrl:
      'https://audio.deepstash.com/covers/collection/202304/how_to_be_more_mindful.png',
    name: 'How to be More Mindful',
  },
  {
    imageUrl:
      'https://audio.deepstash.com/covers/collection/202304/the_glorification_of_busy.png',
    name: 'Be Productive',
  },
  {
    imageUrl:
      'https://audio.deepstash.com/covers/collection/202304/top_7_ted_talks_in_customer_success.png',
    name: 'TED Talks',
  },
  {
    imageUrl:
      'https://audio.deepstash.com/covers/collection/202304/7_days_with_seth_godin.png',
    name: 'Marketing with Seth Godin',
  },
  {
    imageUrl:
      'https://audio.deepstash.com/covers/collection/202304/top_7_books_for_product_managers.png',
    name: 'Stoic Life',
  },
  {
    imageUrl:
      'https://audio.deepstash.com/covers/collection/202304/how_to_start_investing_today.png',
    name: 'Learn to Invest',
  },
  {
    imageUrl:
      'https://audio.deepstash.com/covers/collection/202304/managing_email_efficiently.png',
    name: 'Managing Email Efficiently',
  },
  {
    imageUrl:
      'https://audio.deepstash.com/covers/collection/202304/sleep_better.png',
    name: 'Sleep Better',
  },
  {
    imageUrl:
      'https://audio.deepstash.com/covers/collection/202304/how_to_be_happy.png',
    name: 'How to be Happy',
  },
  {
    imageUrl:
      'https://audio.deepstash.com/covers/collection/202304/elon_musk.png',
    name: 'Inside the Mind of Elon Musk',
  },
  {
    imageUrl:
      'https://audio.deepstash.com/covers/collection/202304/da_vinci.png',
    name: 'The Genius of DaVinci',
  },
  {
    imageUrl:
      'https://audio.deepstash.com/covers/collection/202304/charisma.png',
    name: 'Learn Charisma',
  },
  {
    imageUrl:
      'https://audio.deepstash.com/covers/collection/202304/vulnerable.png',
    name: 'Avoid Bad Habits',
  },
  {
    imageUrl:
      'https://audio.deepstash.com/covers/collection/202304/recover_burnout.png',
    name: 'Recover from Burnout',
  },
  {
    imageUrl:
      'https://audio.deepstash.com/covers/collection/202304/joining_a_new_team.png',
    name: 'Joining a New Team',
  },
];
