import { useRef, useState } from 'react';

export interface ElementMeasurement {
  width: number;
  height: number;
  x: number;
  y: number;
}

/**
 * Get a ref function to measure the width and height of an element
 * Also get the position of the element
 * @param relativeTo Whether the position measurement is relative to the whole document or the viewport
 */
export const useMeasureElement = <TType extends HTMLElement>(
  relativeTo: 'document' | 'viewport' = 'document',
) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  const elementRef = useRef<TType | undefined>();

  const getElementPosition = (element: TType) => {
    const incomingX =
      element.getBoundingClientRect().x +
      (relativeTo === 'document' ? window.scrollX : 0);
    const incomingY =
      element.getBoundingClientRect().y +
      (relativeTo === 'document' ? window.scrollY : 0);

    if (incomingX !== x) {
      setX(incomingX);
    }
    if (incomingY !== y) {
      setY(incomingY);
    }
  };

  const getElementSize = (element: TType) => {
    const newWidth = element.getBoundingClientRect().width;
    const newHeight = element.getBoundingClientRect().height;

    if (newHeight !== height) {
      setHeight(newHeight);
    }
    if (newWidth !== width) {
      setWidth(newWidth);
    }
  };

  const measureElement = (element?: TType | null) => {
    if (element === null) {
      // The ref prop of the JSX element is still not set.
      // Nothing to do yet
      return;
    }

    if (element === undefined && elementRef.current) {
      // Recalculate the size and position of the saved element
      getElementPosition(elementRef.current);
      getElementSize(elementRef.current);
    }

    if (element) {
      elementRef.current = element;

      getElementPosition(element);
      getElementSize(element);
    }
  };

  return { width, height, x, y, measureElement };
};
