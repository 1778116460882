import React from 'react';
import { color, Flex, Heading, Text, typography } from 'deepstash-ui';
import styles from './LandingHeroSection.module.scss';
import { LANDING_PAGE_DEFAULT_PX } from '../LandingPageV2';
import HeroCarouselCard from './HeroCarouselCard';
import { LANDING_HERO_CARDS } from '../landing-hero-cards';
import LandingGoalQuestion from './LandingGoalQuestion';

const IMAGE_SIZE_XL = { WIDTH: 200, HEIGHT: 280, BORDER_RADIUS: 20 };
// const SECTION_HEIGHT_XL = IMAGE_SIZE_XL.HEIGHT * 2 + 16 + 300;

const SUBTITLE =
  'Unlimited access to 200,000+ powerful ideas from top books, podcasts, and more.';

interface LandingHeroSectionProps {}

const LandingHeroSection: React.FC<LandingHeroSectionProps> = ({}) => {
  const renderHeroCarouselCard = ({
    card,
    index,
  }: {
    card: { imageUrl: string; name: string };
    index: number;
  }) => {
    return (
      <HeroCarouselCard
        key={`first-row-${index}`}
        title={card.name}
        imageProps={{
          src: card.imageUrl,
          width: IMAGE_SIZE_XL.WIDTH,
          height: IMAGE_SIZE_XL.HEIGHT,
          layout: 'fixed',
          style: { borderRadius: IMAGE_SIZE_XL.BORDER_RADIUS },
          priority: true,
        }}
        containerWidth={IMAGE_SIZE_XL.WIDTH}
        containerBorderBottomRadius={IMAGE_SIZE_XL.BORDER_RADIUS}
      />
    );
  };

  const renderImages = () => {
    return (
      <Flex overflow="hidden" flexDirection="column" gridRowGap={16}>
        <Flex
          className={`${styles.carousel}`}
          gridColumnGap={16}
          flexWrap="nowrap"
          height={IMAGE_SIZE_XL.HEIGHT}
        >
          {LANDING_HERO_CARDS.slice(0, 13).map((card, index) => {
            return renderHeroCarouselCard({ card, index });
          })}
        </Flex>
        <Flex
          className={`${styles.carousel} ${styles.carousel2}`}
          gridColumnGap={16}
          flexWrap="nowrap"
          height={IMAGE_SIZE_XL.HEIGHT}
        >
          {LANDING_HERO_CARDS.slice(5, 20).map((card, index) => {
            return renderHeroCarouselCard({ card, index });
          })}
        </Flex>
      </Flex>
    );
  };

  return (
    <Flex flexDirection="column" mb={32}>
      {renderImages()}
      <Flex
        zIndex={1}
        w={'100%'}
        position="absolute"
        // height={SECTION_HEIGHT_XL}
      >
        <Flex
          background={`linear-gradient(to right, rgba(241,239,235,1) 60%, rgba(241,239,235,0))`}
          // bgColor="red"
          w={{ lg: '90%', xl: '70%' }}
          flexDirection="column"
          // height={SECTION_HEIGHT_XL}
          justifyContent="center"
          paddingX={LANDING_PAGE_DEFAULT_PX}
          gridRowGap={16}
        >
          <Heading
            size="h1"
            color={color.light.text}
            {...typography.primaryHeadingFonts.xl.bold}
            lineHeight="1em"
            fontSize={{ lg: '3rem', xl: '4rem' }}
            fontWeight="800"
          >
            BECOME
            <br />
            THE SMARTEST
            <br />
            IN THE ROOM
          </Heading>
          <Text
            maxW={500}
            {...typography.primaryHeadingFonts.small.regular}
            color={color.light.textSecondary}
          >
            {SUBTITLE}
          </Text>
          <Flex
            width={128}
            height={4}
            bgColor={color.light.underground}
            borderRadius={4}
          />
          <LandingGoalQuestion />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LandingHeroSection;
