import * as React from 'react';
import { Icon, IconProps } from 'deepstash-ui';

export const LandingIllustration2 = React.memo<IconProps>(props => {
  return (
    <Icon
      width="1700"
      height="1080"
      viewBox="0 0 1700 1080"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_251_19494)">
        <rect width="1700" height="1080" fill="#CCE2EF" />
        <rect x="-84" y="-50" width="4695" height="626" fill="#CCE2EF" />
        <path
          d="M-129 1865.98C128.85 2039.48 463.949 2111.89 779.133 2090.47C1242.48 2059.24 1629.88 2060.45 2051.58 1865.98L2387.79 2117.04C2762.72 1944.15 3171.45 1766.89 3575.05 1854.24C3905.93 1925.9 4171.32 1579.04 4459.5 1756.75C4978.37 2076.44 5609.19 2206.63 6213 2129.24L6213 433.513C5650.23 183.831 5004.68 150.392 4415.95 332.487C3860.72 529.082 3495.02 -175.68 2920.32 -89.6871C2395.87 -11.2372 1721.82 463.856 1295.44 123.938C1113.33 -10.4826 940.572 242.217 747.599 123.938C484.335 -35.7466 178.221 -110.347 -129 -89.6874L-129 1865.98Z"
          fill="#99C6DF"
        />
        <path
          d="M-129 1945.4C57.3001 2070.76 299.413 2123.09 527.138 2107.61C861.911 2085.04 1141.81 2085.91 1446.5 1945.4L1689.41 2126.81C1960.31 2001.88 2255.62 1873.79 2547.22 1936.91C2786.29 1988.69 2978.04 1738.06 3186.25 1866.47C3561.14 2097.47 4016.91 2191.54 4453.17 2135.62L4453.17 910.335C4046.56 729.921 3580.15 705.759 3154.78 837.336C2753.62 979.391 2489.4 470.148 2074.17 532.285C1695.25 588.97 1208.24 932.26 900.175 686.645C768.598 589.516 643.779 772.109 504.354 686.645C314.142 571.261 92.971 517.357 -129 532.285L-129 1945.4Z"
          fill="#66A9CE"
        />
        <g
          style={{ mixBlendMode: 'lighten' }}
          opacity="0.6"
          filter="url(#filter0_f_251_19494)"
        >
          <path
            d="M594.324 -63.6133C663.615 -6.80759 760.135 96.831 1033.37 74.7026"
            stroke="#F7C76D"
            strokeWidth="19.3739"
          />
          <path
            d="M487.169 14.2607C611.863 91.7201 718.537 179.794 1050.44 165.374"
            stroke="#F7C76D"
            strokeWidth="19.3739"
          />
          <path
            d="M417 135.693C562.11 189.478 880.963 314.033 1123.45 227.45"
            stroke="#F7C76D"
            strokeWidth="19.3739"
          />
          <path
            d="M744.146 306.14C864.616 311.99 986.081 320.509 1109.86 316.971C1413.29 308.298 1098.52 308.367 1372.84 288.17"
            stroke="#F7C76D"
            strokeWidth="19.3739"
          />
          <path
            d="M993.541 384.828C1236.04 374.589 1472.87 364.94 1660.17 316.486"
            stroke="#F7C76D"
            strokeWidth="19.3739"
          />
          <path
            d="M1258.1 440.097C1529.82 441.919 1679.29 425.827 1844.34 366.613C1877 354.896 1909.82 343.92 1949.39 334.182"
            stroke="#F7C76D"
            strokeWidth="19.3739"
          />
          <path
            d="M1426.89 473.042C1545.75 458.387 1664.95 445.026 1777.12 426.301C2080.77 375.61 1737.43 428.629 1997.75 377.745"
            stroke="#F7C76D"
            strokeWidth="19.3739"
          />
        </g>
        <path
          d="M1196.32 234.022C1187.25 233.591 1176.68 232.186 1172.1 224.342C1169.88 220.025 1169.4 215.015 1170.77 210.354C1175.16 192.581 1199.31 183.15 1214.52 193.465C1208.81 183.377 1202.89 171.747 1206.4 160.706C1210.12 149.008 1223.9 142.57 1236.06 144.338C1248.21 146.107 1258.73 154.041 1266.66 163.313C1265 154.134 1266.45 144.661 1270.78 136.4C1275.12 128.14 1282.09 121.565 1290.59 117.72C1299.09 113.875 1308.63 112.98 1317.7 115.178C1326.76 117.376 1334.84 122.541 1340.63 129.852C1354.98 147.988 1351.97 174.241 1344.26 195.959C1352.69 192.898 1361.53 190.019 1370.49 190.767C1379.44 191.515 1388.62 196.82 1391.25 205.412C1394.34 215.432 1387.45 226.337 1378.24 231.37C1369.04 236.403 1358.16 236.992 1347.68 237.468C1297.33 239.735 1246.91 234.022 1196.32 234.022Z"
          fill="white"
          stroke="#323130"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M410 142.889C413.963 138.681 418.318 134.228 424.292 132.925C432.943 131.024 442.441 137.948 442.305 145.944C443.439 140.758 446.268 135.477 451.455 133.006C456.643 130.536 464.401 132.219 466.216 137.242"
          stroke="#323130"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M527.031 82.0997C532.986 80.7056 539.279 81.0233 545.022 83.0081C550.766 84.9929 555.672 88.5447 559.048 93.1635C559.602 89.196 561.662 85.5212 564.883 82.7589C568.104 79.9965 572.288 78.3158 576.728 78"
          stroke="#323130"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M511.831 158.121C513.635 155.256 516.014 152.716 518.848 150.628C521.873 148.727 525.957 147.912 529.163 149.46C530.461 150.191 531.549 151.186 532.338 152.366C533.126 153.545 533.593 154.875 533.7 156.248C533.914 158.974 533.539 161.712 532.596 164.311C533.222 160.893 534.556 157.61 536.528 154.632C538.706 151.781 542.487 149.65 546.283 150.193C550.079 150.736 553.119 154.754 551.365 157.836"
          stroke="#323130"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M623.825 109.033C628.997 111.422 631.523 116.798 632.687 121.916C633.852 127.034 634.2 132.409 636.68 137.12C638.576 133.845 641.233 130.974 644.471 128.7C647.708 126.426 651.452 124.803 655.449 123.938"
          stroke="#323130"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M731.69 142.414C737.111 141.948 742.548 143.146 747.114 145.811C751.679 148.476 755.103 152.452 756.826 157.089C758.768 153.487 761.764 150.427 765.493 148.234C769.223 146.041 773.545 144.799 778 144.641"
          stroke="#323130"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M328.528 307.649C335.485 307.8 343.041 307.8 348.647 303.787C357.51 297.522 357.222 284.626 353.721 274.628C350.774 266.174 345.634 257.87 337.369 253.922C329.104 249.974 317.427 252.162 313.394 260.209C306.902 238.623 297.507 216.952 280.357 201.782C263.207 186.612 236.728 179.553 216.099 189.874C195.47 200.194 187.095 230.641 203.646 246.498C194.783 235.362 178.409 228.517 165.114 234.267C151.819 240.018 147.698 261.045 160.129 268.405C154.499 266.433 148.404 266.064 142.565 267.342C136.726 268.619 131.387 271.49 127.18 275.615C122.748 280.12 119.757 286.622 121.508 292.608C124.344 302.35 136.863 305.311 147.255 306.341C207.48 312.22 268.103 306.319 328.528 307.649Z"
          fill="white"
          stroke="#323130"
          strokeWidth="6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1576.38 36.1715C1588.2 30.6349 1601.51 29.1564 1614.25 31.966C1620.57 33.4256 1626.5 36.2289 1631.63 40.185C1636.77 44.1411 1640.99 49.1573 1644.02 54.8922C1645.82 58.6313 1647.14 62.582 1647.96 66.6513C1652.22 87.291 1644.91 110.258 1628.62 123.65C1612.33 137.043 1587.53 139.574 1569.42 128.672C1542.43 112.34 1541.06 72.5104 1558.99 49.0534C1563.56 43.3134 1569.56 38.8719 1576.38 36.1715V36.1715Z"
          fill="#F7C76D"
          stroke="#323130"
          strokeWidth="8.16605"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1476 121.772C1483.59 119.075 1491.31 116.772 1499.13 114.872"
          stroke="#323130"
          strokeWidth="8.16605"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1476 22.4111C1483.36 24.8055 1490.86 26.8497 1498.46 28.5356"
          stroke="#323130"
          strokeWidth="8.16605"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1525.56 195.898L1537.87 181.139"
          stroke="#323130"
          strokeWidth="8.16605"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1633.62 180.795C1634.76 187.943 1636.28 195.022 1638.2 202"
          stroke="#323130"
          strokeWidth="8.16605"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1689.87 139.268L1707.3 152.273"
          stroke="#323130"
          strokeWidth="8.16605"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1690.36 4.0376L1706.69 -10.253"
          stroke="#323130"
          strokeWidth="8.16605"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_251_19494"
          x="349.633"
          y="-535.051"
          width="3816.72"
          height="1118.76"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="32"
            result="effect1_foregroundBlur_251_19494"
          />
        </filter>
        <clipPath id="clip0_251_19494">
          <rect width="1700" height="1080" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
});

export default LandingIllustration2;
