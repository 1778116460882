import * as React from 'react';
import { Icon, IconProps } from 'deepstash-ui';

export const LandingIllustration3 = React.memo<IconProps>(props => {
  return (
    <Icon
      width="1700"
      height="1080"
      viewBox="0 0 1700 1080"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_251_19371)">
        <rect width="1700" height="1080" fill="#CDB9CF" />
        <rect x="1137" y="196" width="3468" height="1283" fill="#CDB9CF" />
        <path
          d="M2956.22 -795.237C2815.02 -786.808 2430.34 -550.805 2312.11 -451.229C2181.82 -341.611 1980.8 -138.129 1871.36 -1.80814C1748.58 151.085 1602.85 315.932 1445.42 384.524C1277.31 457.795 1110.05 455.893 944.698 359.783C779.347 263.672 683.836 57.1113 675.36 -174.781C668.058 -373.36 806.229 -692.727 948.123 -803.522L960.26 -820.107L-25.0109 -831.307L-48.1602 -802.486C-536.43 -240.907 -430.484 981.808 106.288 1472.15C413.053 1751.93 761.681 1775.67 1124.9 1703.31C1432.8 1641.96 1672.18 1437.46 1930.76 1236.66C2206.22 1022.69 2466.03 787.446 2750.65 586.95C2997.93 413.369 3365.59 307.904 3724.48 374.658C4083.38 441.413 4239.39 633.684 4325.35 849.88L4330.14 859.532L4348.79 -781.589L2961.86 -797.354L2956.22 -795.237Z"
          fill="#FBC262"
        />
        <path
          d="M3252.22 -1738.24C3111.02 -1729.81 2726.34 -1493.81 2608.11 -1394.23C2477.82 -1284.61 2276.8 -1081.13 2167.36 -944.808C2044.58 -791.915 1898.85 -627.068 1741.42 -558.476C1573.31 -485.205 1406.05 -487.107 1240.7 -583.217C1075.35 -679.328 979.836 -885.889 971.36 -1117.78C964.058 -1316.36 1102.23 -1635.73 1244.12 -1746.52L1256.26 -1763.11L270.989 -1774.31L247.84 -1745.49C-240.43 -1183.91 -134.484 38.8085 402.288 529.152C709.053 808.933 1057.68 832.673 1420.9 760.312C1728.8 698.956 1968.18 494.456 2226.76 293.665C2502.22 79.687 2762.03 -155.554 3046.65 -356.05C3293.93 -529.631 3661.59 -635.096 4020.48 -568.342C4379.38 -501.587 4535.39 -309.316 4621.35 -93.1201L4626.14 -83.468L4644.79 -1724.59L3257.86 -1740.35L3252.22 -1738.24Z"
          fill="#FBB75E"
        />
      </g>
      <defs>
        <clipPath id="clip0_251_19371">
          <rect width="1700" height="1080" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
});

export default LandingIllustration3;
