import { color, Flex, useColorMode } from 'deepstash-ui';
import useCookieAgreementToast from 'hooks/useCookieAgreementToast';
import React from 'react';
import OverallFaq from 'src/components/faq/OverallFaq';
import Footer from 'src/components/layout/components/Footer';
import FullWidthSectionWrapper from 'src/components/layout/components/FullWidthSectionWrapper';
import Navbar from 'src/components/navbar/Navbar';
import LandingCollectionsSections from './components-v2/LandingCollectionsSections';
import LandingHeroSection from './components-v2/LandingHeroSection';
import LandingMobileAppSection from './components-v2/LandingMobileAppSection';
import LandingProSection from './components-v2/LandingProSection';
import LandingReviewsSection from './components-v2/LandingReviewsSection';
import LandingStartJourneySection from './components-v2/LandingStartJourneySection';
import LandingStartTrialSection from './components-v2/LandingStartTrialSection';
import LandingTopicsSection from './components-v2/LandingTopicsSection';
import LandingValuePropsSection from './components-v2/LandingValuePropsSection';
import SpecialOfferBannerWrapper from '../special-offer/SpecialOfferBannerWrapper';

export const LANDING_PAGE_DEFAULT_PX = { base: 16, md: 100, lg: 160 };

const LandingPageV2: React.FC = ({}) => {
  const { colorMode, setColorMode } = useColorMode();

  React.useEffect(() => {
    if (colorMode === 'dark') {
      setColorMode('light');
    }
  }, [colorMode]);

  useCookieAgreementToast();

  return (
    <>
      <Flex width="100%" flexDirection="column" pb={32}>
        <Flex paddingX={LANDING_PAGE_DEFAULT_PX}>
          <Navbar />
        </Flex>
        <LandingHeroSection />
        <FullWidthSectionWrapper mt={100}>
          <LandingStartTrialSection />
        </FullWidthSectionWrapper>
        <LandingValuePropsSection />
        <FullWidthSectionWrapper containerProps={{ flexDirection: 'column' }}>
          <LandingStartJourneySection />
          <LandingCollectionsSections />
          <LandingTopicsSection />
          <LandingMobileAppSection />
          <LandingProSection showProComparison={true} />
          <LandingReviewsSection />
          <OverallFaq mt={80} mb={40} />
        </FullWidthSectionWrapper>
      </Flex>

      {/* Manually enabled during sales */}
      <SpecialOfferBannerWrapper />

      <Footer
        backgroundColor={color[colorMode].surface}
        disableStickyStartTrial={true}
      />
    </>
  );
};

export default LandingPageV2;
