import React from 'react';
import {
  PrimaryButton,
  useIsMobileView,
  typography,
  StyleProps,
} from 'deepstash-ui';
import useAuthAndOpenPurchasePage from 'hooks/purchases/useAuthAndOpenPurchasePage';
import { Analytics, Events } from 'services/analytics';
import { useRouter } from 'next/router';

// TODO No Trial Changes
// const CTA_TEXT = 'Try it for Free';
const CTA_TEXT = 'Start Today';

interface LandingStartTrialButtonProps {
  text?: string;
  landingLocation: string;
}

const LandingStartTrialButton: React.FC<
  LandingStartTrialButtonProps & StyleProps
> = ({ text = CTA_TEXT, landingLocation, ...props }) => {
  const isMobileView = useIsMobileView();
  const router = useRouter();

  const { onClickStartSubscriptionForNsi } = useAuthAndOpenPurchasePage({});

  return (
    <PrimaryButton
      size="lg"
      type="button"
      borderRadius={16}
      {...(isMobileView
        ? typography.primaryTextFonts.medium.semiBold
        : typography.primaryHeadingFonts.small.semiBold)}
      onClick={() => {
        Analytics.logEvent({
          eventName: Events.payments.clickStartTrial,
          properties: {
            url: router.asPath,
            landingLocation: landingLocation,
          },
          platforms: ['amplitude'],
        });
        onClickStartSubscriptionForNsi();
      }}
      {...props}
    >
      {text}
    </PrimaryButton>
  );
};

export default LandingStartTrialButton;
