import { Text, color, typography, useColorMode } from 'deepstash-ui';
import React, { useState } from 'react';

const LogoWithVersion: React.FC = () => {
  const [isHovering, setIsHovering] = useState(false);
  const { colorMode } = useColorMode();

  return (
    <Text
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      color={color[colorMode].text}
      mb="1rem"
      {...typography.primaryHeadingFonts.medium.regular}
    >
      deep<span style={{ fontWeight: 700 }}>stash</span>
      {isHovering && (
        <span
          style={{
            fontSize: '1.25rem',
            lineHeight: '1.25rem',
            color: color[colorMode].textDisabled,
          }}
        >
          {' ' + process.env.WEBSITE_VERSION}
        </span>
      )}
    </Text>
  );
};

export default LogoWithVersion;
