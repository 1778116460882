import {
  Box,
  color,
  Flex,
  PrimaryButton,
  PrimaryHeading,
  PrimaryText,
  spacing,
  StyleProps,
  Text,
  typography,
  useColorMode,
  useIsDesktopView,
} from 'deepstash-ui';
import useCurrentSubscription from 'hooks/purchases/useCurrentSubscription';
import { useRouter } from 'next/router';
import useProfile from 'providers/hooks/useProfile';
import React from 'react';
import { ProBadgeSvg } from 'theme/icons/ProBadgeSvg';
import { ProShieldSvg } from 'theme/icons/ProShieldSvg';
import { UserSubscriptionType } from 'types/enums';
import { getRemWidthFromColumns, SQUARE_WIDTH_REM } from 'utils/global';
import { PAYWALL_INCLUDED_LIST } from '../paywall/WhatsIncludedDropdown';
import { STRIPE_URLS } from 'utils/constants';

interface GetTheAppBannerProps {
  isNsi?: boolean;
}

const GetTheAppBanner: React.FC<GetTheAppBannerProps & StyleProps> = ({
  isNsi,
  ...props
}) => {
  const isDesktopView = useIsDesktopView();
  const { colorMode } = useColorMode();
  const router = useRouter();
  const { profile } = useProfile();
  const { currentSubscription } = useCurrentSubscription();
  const isPro = profile?.subscriptionType === UserSubscriptionType.Premium;
  const subscriptionLoaded =
    !!profile &&
    (profile.subscriptionType !== UserSubscriptionType.Premium ||
      !!currentSubscription);
  const isLifetime =
    isPro && currentSubscription
      ? Date.parse(currentSubscription.endDate) > new Date(2050, 1, 1).getTime()
      : false;

  const title = (
    <PrimaryHeading
      size="h3"
      textSize="l"
      type="bold"
      textAlign={'left'}
      color={color[colorMode].text}
    >
      {isPro ? 'Special Sale' : 'Read like a Pro'}
    </PrimaryHeading>
  );

  const renderCard = () => (
    <Flex
      flexDir="column"
      alignItems={{ base: 'flex-start', md: 'center' }}
      backgroundColor={color[colorMode].surface}
      w={{ base: '100%', md: getRemWidthFromColumns(4) }}
      background={`linear-gradient(180deg, ${color[colorMode].top} 0%, ${color[colorMode].background} 100%)`}
      color={color[colorMode].text}
      onClick={() =>
        isPro
          ? router.push(STRIPE_URLS.LIFETIME_199)
          : router.push('/special-offer')
      }
      cursor="pointer"
      position="relative"
      boxShadow="0px 6px 16px rgba(0, 0, 0, 0.1); 0px 8px 4px rgba(0, 0, 0, 0.05);"
      _hover={{
        transform: 'scale(1.01)',
        boxShadow:
          '0px 6px 16px rgba(0, 0, 0, 0.2); 0px 8px 4px rgba(0, 0, 0, 0.1);',
      }}
      transition="all 0.3s cubic-bezier(.11,.57,.44,.8)"
      borderRadius="1rem"
      overflow="hidden"
      {...props}
    >
      {isPro && (
        <Flex
          w={'100%'}
          height={spacing.toRem(48)}
          backgroundColor={color.failure.darker}
          color={color.light.textInverted}
          alignItems="center"
          justifyContent="center"
          position="absolute"
          zIndex={1}
          top={48}
          right={-104}
          transform={{ base: '', md: 'rotate(45deg)' }}
        >
          <PrimaryText fontSize={'2rem'} type="bold">
            {'-$100 OFF'}
          </PrimaryText>
        </Flex>
      )}
      <Box
        position="absolute"
        w={spacing.toRem(192)}
        right={spacing.toRem(-72)}
        top={spacing.toRem(16)}
      >
        <ProShieldSvg width={192} height={192} />
      </Box>
      <Flex flexDir={'column'} alignItems={'start'}>
        <Flex flexDir={'column'} mx={SQUARE_WIDTH_REM} my={SQUARE_WIDTH_REM}>
          {title}
          {isPro && (
            <PrimaryHeading
              size="h4"
              textSize="s"
              type="bold"
              textAlign={'left'}
              color={color[colorMode].textSecondary}
            >
              {'Lifetime Plan'}
            </PrimaryHeading>
          )}
        </Flex>

        <Flex
          w={getRemWidthFromColumns(4)}
          overflow="hidden"
          mb={SQUARE_WIDTH_REM}
        >
          <Flex
            flexDir="column"
            ml={SQUARE_WIDTH_REM}
            mr={spacing.toRem(8)}
            gridGap={spacing.toRem(8)}
            width="230px"
            minWidth="144px"
          >
            {PAYWALL_INCLUDED_LIST.map((included, index) => (
              <Flex
                gridGap={spacing.toRem(8)}
                alignItems="center"
                key={`paywall-included-${index}`}
              >
                <Flex
                  alignItems="center"
                  borderRadius="full"
                  boxSize={spacing.toRem(32)}
                  p={spacing.toRem(4)}
                >
                  {included.renderIcon(color[colorMode].text)}
                </Flex>
                <PrimaryText {...typography.primaryTextFonts.medium.regular}>
                  {included.text}
                </PrimaryText>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Flex>

      <Flex
        w="100%"
        flexDirection="column"
        paddingX={SQUARE_WIDTH_REM}
        alignItems="center"
        mb={isPro ? spacing.toRem(24) : spacing.M.rem}
        zIndex={0}
      >
        <PrimaryButton
          h={spacing.toRem(48)}
          w="100%"
          backgroundColor={color[colorMode].text}
          _hover={undefined}
          _active={undefined}
          mb={isPro ? spacing.toRem(8) : spacing.M.rem}
        >
          <Flex
            alignItems="center"
            gridGap={spacing.XXS.rem}
            justifyContent="center"
          >
            <Text fontWeight={400} color={color[colorMode].textInverted}>
              {isPro ? (
                <>Claim Lifetime</>
              ) : (
                <>
                  Get deep<b>stash</b>
                </>
              )}
            </Text>{' '}
            <ProBadgeSvg height={20} />
            {isPro && (
              <Text fontWeight={400} color={color[colorMode].textInverted}>
                {' '}
                Offer
              </Text>
            )}
          </Flex>
        </PrimaryButton>
        {isPro && (
          <Text
            {...typography.primaryTextFonts.medium.semiBold}
            textAlign={'left'}
            color={color.failure.darker}
          >
            Only <span style={{ textDecoration: 'line-through' }}>$299.99</span>{' '}
            <b>$199.99</b>. Pay once.
          </Text>
        )}
      </Flex>
      {/* <AppStats /> */}
    </Flex>
  );

  return isNsi || (isDesktopView && !isLifetime && subscriptionLoaded)
    ? renderCard()
    : null;
};

export default GetTheAppBanner;
