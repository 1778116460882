import React from 'react';
import {
  Flex,
  useColorMode,
  Text,
  typography,
  color,
  Link,
} from 'deepstash-ui';
import NextImage from '../image/NextImage';
import { CollectionApiResponse } from 'api/api.types';
import { slugify } from 'utils/global';
import NextLink from '../navigation/NextLink';
import { useRouter } from 'next/router'; // Use it instead of own useRouter for fixing scroll problems

const COLLECTION_IMAGE_SIZE = { WIDTH: 200, HEIGHT: 300, BORDER_RADIUS: 16 };

interface CollectionCardProps {
  collection: Partial<CollectionApiResponse>;
  shouldOpenInNewTab?: boolean;
}

const CollectionCard: React.FC<CollectionCardProps> = ({
  collection,
  shouldOpenInNewTab,
}) => {
  const { colorMode } = useColorMode();
  const router = useRouter();

  const collectionSlug = React.useMemo(() => {
    return slugify(collection.name);
  }, [collection.name]);

  const collectionPageRoute = `/collection/${collection.id}/${collectionSlug}`;

  const link = (
    <Link
      cursor="pointer"
      _focus={undefined}
      _hover={undefined}
      href={collectionPageRoute}
      target={shouldOpenInNewTab ? '_blank' : ''}
    >
      <Text
        textAlign="center"
        {...typography.primaryHeadingFonts.small.semiBold}
        color={color[colorMode].text}
      >{`${collection.name}`}</Text>
    </Link>
  );

  if (!collection.id) return null;

  return (
    <Flex
      w={COLLECTION_IMAGE_SIZE.WIDTH}
      flexDirection="column"
      gridRowGap={16}
      alignItems="center"
    >
      <NextImage
        wrapperStyle={{
          width: COLLECTION_IMAGE_SIZE.WIDTH,
          height: COLLECTION_IMAGE_SIZE.HEIGHT,
          borderRadius: COLLECTION_IMAGE_SIZE.BORDER_RADIUS,
          cursor: 'pointer',
        }}
        imageUrl={collection.image}
        blurhash={collection.image_blurhash}
        alt={`${collection.name}`}
        priority={false}
        onClick={() => {
          shouldOpenInNewTab
            ? window.open(collectionPageRoute)
            : router.push(collectionPageRoute);
        }}
      />
      {shouldOpenInNewTab ? (
        link
      ) : (
        <NextLink href={collectionPageRoute} passHref>
          {link}
        </NextLink>
      )}
    </Flex>
  );
};

export default CollectionCard;
