import {
  BoxProps,
  color,
  Flex,
  PrimaryText,
  spacing,
  Text,
  typography,
  useColorMode,
  useIsMobileView,
} from 'deepstash-ui';
import React from 'react';
import { GAP_SIZE_REM } from 'utils/global';
import commonStrings from 'utils/strings/commonStrings';
import SubsectionHeader from '../metacontent/components/SubsectionHeader';

export interface CuratorsNoteProps extends BoxProps {
  text: string;
  quoteColor?: string;
  showLabel?: boolean;
}

const CuratorsNote: React.FC<CuratorsNoteProps> = ({
  text,
  quoteColor,
  showLabel,
  ...props
}) => {
  const { colorMode } = useColorMode();
  const isMobileView = useIsMobileView();

  return (
    <Flex width="100%" {...props} flexDirection="column">
      {showLabel &&
        (isMobileView ? (
          <SubsectionHeader pb={GAP_SIZE_REM}>
            {commonStrings.curatorsNote}
          </SubsectionHeader>
        ) : (
          <PrimaryText
            {...typography.primaryTextFonts.large.bold}
            color={color[colorMode].textSecondary}
            type="bold"
          >
            {commonStrings.curatorsNote}
          </PrimaryText>
        ))}
      <Flex position="relative">
        <Text
          color={color[colorMode].text}
          {...typography.primaryTextFonts.medium.regular}
          zIndex={1}
          mt={spacing.XXS.rem}
        >
          {text}
        </Text>
        <Text
          position="absolute"
          fontSize="8rem"
          fontWeight={900}
          color={quoteColor ?? color[colorMode].background}
          left="-1rem"
          top="2rem"
          lineHeight="1rem"
          opacity={0.6}
        >
          “
        </Text>
      </Flex>
    </Flex>
  );
};

export default CuratorsNote;
