import {
  ProductivityIcon,
  HabitsIcon,
  MoneyIcon,
  HappinessIcon,
  SocializingIcon,
} from 'theme/color-icons';
import WorkIcon from 'theme/color-icons/WorkIcon';
import { SetupLikeEntity } from './setup.utils';
import { QuizQuestion } from 'utils/quiz.constants';
import { ArticleApiResponse, CollectionApiResponse } from 'api/api.types';
import { ArticleStatus, SourceType } from 'types/enums';

const EXTRA_SOURCE_DATA = {
  ideas: [],
  suggestedTopics: [],
  sourceSubtype: 0,
  status: ArticleStatus.PUBLISHED,
  totalComments: 0,
  totalReactions: 0,
  topReactions: [],
  userData: { firstName: '', lastName: '', id: -1 },
};

export const SETUP_LIKE_ENTITIES: SetupLikeEntity[] = [
  {
    title: 'Atomic Habits',
    sourceType: SourceType.BOOK,
    id: 118080,
    deepstashArticles: [118080],
    description: 'Written by James Clear',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1655988385l/40121378.jpg',
    imageColor: '#EFE9DF',
    ...EXTRA_SOURCE_DATA,
  },
  {
    title: 'The Compound Effect',
    sourceType: SourceType.BOOK,
    id: 127082,
    deepstashArticles: [127082],
    description: 'Written by Darren Hardy',
    image:
      'https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1337205071i/9420697.jpg',
    imageColor: '#A41527',
    ...EXTRA_SOURCE_DATA,
  },
  {
    title: 'Ultralearning',
    sourceType: SourceType.BOOK,
    id: 43722,
    deepstashArticles: [43722],
    description: 'Written by Scott H. Young',
    image:
      'https://book2action-5d2aff0.divio-media.net/filer_public/8e/e8/8ee8d5a0-004c-4c95-bdcd-abba9e6c877e/ultralearning__master_hard_skills_outsmart_the_competition_and_accelerate_your_career.png',
    imageColor: '#00989D',
    ...EXTRA_SOURCE_DATA,
  },
  {
    title: 'How to Win Friends and Influence People',
    sourceType: SourceType.BOOK,
    id: 62746,
    deepstashArticles: [62746],
    description: 'Written by Dan Carnegie',
    image:
      'https://i.gr-assets.com/images/S/compressed.photo.goodreads.com/books/1442726934l/4865._SY475_.jpg',
    imageColor: '#EB363A',
    ...EXTRA_SOURCE_DATA,
  },
  {
    title: 'Deep Work',
    sourceType: SourceType.BOOK,
    id: 50623,
    deepstashArticles: [50623],
    description: 'Written by Cal Newport',
    image:
      'https://images-na.ssl-images-amazon.com/images/S/compressed.photo.goodreads.com/books/1448550444i/27985224.jpg',
    imageColor: '#C8A906',
    ...EXTRA_SOURCE_DATA,
  },
  {
    title: 'Huberman Lab',
    sourceType: SourceType.PODCAST,
    id: 102347,
    deepstashArticles: [102347, 122526, 151730],
    description: 'Podcast on Science',
    image: 'https://i.scdn.co/image/ab6765630000ba8a163bf8f74577a2246184654b',
    imageColor: '#4B9CC2',
    ...EXTRA_SOURCE_DATA,
  },
  {
    title: 'The Joe Rogan Experience',
    sourceType: SourceType.PODCAST,
    id: 1701,
    deepstashArticles: [1701, 144802],
    description: 'Podcast on Life & Everything',
    image: 'https://i.scdn.co/image/9af79fd06e34dea3cd27c4e1cd6ec7343ce20af4',
    imageColor: '#A51D21',
    ...EXTRA_SOURCE_DATA,
  },
  {
    title: 'WorkLife with Adam Grant',
    sourceType: SourceType.PODCAST,
    id: 61970,
    deepstashArticles: [61970],
    description: 'Podcast on Work',
    image:
      'https://pi.tedcdn.com/r/pb-assets.tedcdn.com/system/baubles/files/000/009/379/original/TED_WLS2_Logo_3000x3000_.png?1634320261?w=320',
    imageColor: '#223746',
    ...EXTRA_SOURCE_DATA,
  },
];

type SetupGoalInformation = {
  title: string;
  collections?: Pick<CollectionApiResponse, 'name' | 'image' | 'id'>[];
  sources?: Pick<ArticleApiResponse, 'title' | 'image_compressed' | 'id'>[];
};

export const SETUP_GOALS: Record<string, SetupGoalInformation> = {
  productivity: {
    title: 'Increase Productivity',
    collections: [
      {
        id: 4,
        name: 'Productivity hacks',
        image:
          'https://audio.deepstash.com/covers/collection/202304/productivity_hacks.png',
      },
      {
        id: 5,
        name: 'Beat Procrastination',
        image:
          'https://audio.deepstash.com/covers/collection/202304/beat_procrastination.png',
      },
      {
        id: 96,
        name: 'Productivity Systems',
        image:
          'https://audio.deepstash.com/covers/collection/202304/productivity_systems.png',
      },
      {
        id: 91,
        name: 'Music and Productivity',
        image:
          'https://audio.deepstash.com/covers/collection/202304/music_productivity.png',
      },
      {
        id: 69,
        name: 'The Imposter Cure',
        image:
          'https://audio.deepstash.com/covers/collection/202304/the_imposter_cure.png',
      },
    ],
  },
  work: {
    title: 'Win at Work',
    collections: [
      {
        id: 103,
        name: 'How to Run an Effective Meeting',
        image:
          'https://audio.deepstash.com/covers/collection/202304/meeting.png',
      },
      {
        id: 124,
        name: 'How To Stop Wasting Time',
        image:
          'https://audio.deepstash.com/covers/collection/202304/wasting_time.png',
      },
      {
        id: 49,
        name: 'Managing Email Effectively',
        image:
          'https://audio.deepstash.com/covers/collection/202304/managing_email_efficiently.png',
      },
      {
        id: 104,
        name: 'Business Writing',
        image:
          'https://audio.deepstash.com/covers/collection/202304/writing_b.png',
      },
      {
        id: 40,
        name: 'The Art of Leadership',
        image:
          'https://audio.deepstash.com/covers/collection/202304/the_art_of_leadership.png',
      },
    ],
  },
  habits: {
    title: 'Build Better Habits',
    collections: [
      {
        id: 106,
        name: 'The Psychology of Willpower',
        image:
          'https://audio.deepstash.com/covers/collection/202304/willpower.png',
      },
      {
        id: 51,
        name: 'Managing Work Stress',
        image:
          'https://audio.deepstash.com/covers/collection/202304/managing_work_stress.png',
      },
      {
        id: 111,
        name: 'How To Break Bad Habits',
        image:
          'https://audio.deepstash.com/covers/collection/202304/bad_habits.png',
      },
      {
        id: 23,
        name: 'Unlocking your Creative Potential',
        image:
          'https://audio.deepstash.com/covers/collection/202304/unlocking_your_creative_potential.png',
      },
      {
        id: 101,
        name: '7 Books on Habits',
        image:
          'https://audio.deepstash.com/covers/collection/202304/habit_book.png',
      },
    ],
  },
  money: {
    title: 'Earn More Money',
    collections: [
      {
        id: 37,
        name: 'How to Succeed at Investing',
        image:
          'https://audio.deepstash.com/covers/collection/202304/how_to_succeed_at_investing.png',
      },
      {
        id: 38,
        name: 'How to Start Investing Today',
        image:
          'https://audio.deepstash.com/covers/collection/202304/how_to_start_investing_today.png',
      },
      {
        id: 21,
        name: 'No More Broke',
        image:
          'https://audio.deepstash.com/covers/collection/202304/no_more_broke.png',
      },
      {
        id: 12,
        name: 'Lessons From Self Made Billionaires',
        image:
          'https://audio.deepstash.com/covers/collection/202304/lessons_from_self_made_billionaires.png',
      },
      {
        id: 134,
        name: 'The Psychology of Money',
        image:
          'https://audio.deepstash.com/covers/collection/202304/money_psychology.png',
      },
    ],
  },
  happiness: {
    title: 'Be Happy',
    collections: [
      {
        id: 63,
        name: 'How to be happy',
        image:
          'https://audio.deepstash.com/covers/collection/202304/how_to_be_happy.png',
      },
      {
        id: 17,
        name: 'Managing Perfectionism',
        image:
          'https://audio.deepstash.com/covers/collection/202304/managing_perfectionism.png',
      },
      {
        id: 52,
        name: 'Happiness At Work',
        image:
          'https://audio.deepstash.com/covers/collection/202304/happiness_at_work.png',
      },
      {
        id: 61,
        name: 'Sleep Better',
        image:
          'https://audio.deepstash.com/covers/collection/202304/sleep_better.png',
      },
      {
        id: 18,
        name: 'The glorification of busy',
        image:
          'https://audio.deepstash.com/covers/collection/202304/the_glorification_of_busy.png',
      },
    ],
  },
  social: {
    title: 'Improve Social Life',
    collections: [
      {
        id: 126,
        name: 'Conversation Starters',
        image:
          'https://audio.deepstash.com/covers/collection/202304/conversation_starters.png',
      },
      {
        id: 7,
        name: 'Think Outside The Box',
        image:
          'https://audio.deepstash.com/covers/collection/202304/think_outside_the_box.png',
      },
      {
        id: 102,
        name: 'How To Be Effortlessly Charismatic',
        image:
          'https://audio.deepstash.com/covers/collection/202304/charisma.png',
      },
      {
        id: 98,
        name: 'Master Public Speaking',
        image:
          'https://audio.deepstash.com/covers/collection/202304/public_speaking.png',
      },
      {
        id: 55,
        name: 'Giving Effective Feedback',
        image:
          'https://audio.deepstash.com/covers/collection/202304/giving_effective_feedback.png',
      },
    ],
  },
};

export const SETUP_ACHIEVEMENT_GOALS_QUESTION: QuizQuestion = {
  title: 'What do you want to achieve?',
  subtitle: 'You will get recommendations that help you achieve your goals.',
  options: [
    { title: SETUP_GOALS.productivity.title, CustomElement: ProductivityIcon },
    { title: SETUP_GOALS.work.title, CustomElement: WorkIcon },
    { title: SETUP_GOALS.habits.title, CustomElement: HabitsIcon },
    { title: SETUP_GOALS.money.title, CustomElement: MoneyIcon },
    { title: SETUP_GOALS.happiness.title, CustomElement: HappinessIcon },
    { title: SETUP_GOALS.social.title, CustomElement: SocializingIcon },
  ],
};

export const SETUP_TIME_SPENT_QUESTON: QuizQuestion = {
  title: 'How much time do you want to spend on your personal growth?',
  subtitle: 'We will tailor your content based on this.',
  options: [
    { title: 'Easy', text: '5 min/day', emoji: '✌️' },
    { title: 'Common', text: '10 min/day', emoji: '👌' },
    { title: 'Serious', text: '15 min/day', emoji: '✊' },
    { title: 'Intensive', text: '20 min/day', emoji: '💪' },
  ],
};

export const SETUP_CONTENT_VALUE_PROP: QuizQuestion = {
  title: 'Unlock 200,000+ ideas on pretty much any topic.',
  subtitle:
    'Created by the smartest people around & well-organized so you can explore at will.',
  image: 'https://static.deepstash.com/phone-landing-image.png',
  options: [],
};

export const SETUP_TIME_VALUE_PROP: QuizQuestion = {
  title: 'With Deepstash you will save on several hours of reading every week.',
  subtitle:
    'You will essentially read 2-4x more than what the average person reads!',
  image: 'https://static.deepstash.com/phone-landing-image.png',
  options: [],
};

export const SETUP_GENDER_QUESTION: QuizQuestion = {
  title: 'Select your gender',
  options: [
    {
      emoji: '👨',
      title: 'Male',
    },
    {
      emoji: '👩',
      title: 'Female',
    },
    {
      emoji: '🧑',
      title: 'Other',
    },
  ],
};

export const SETUP_AGE_QUESTION: QuizQuestion = {
  title: 'What is your age?',
  options: [
    {
      title: '20s',
      text: '18-29 years',
    },
    {
      title: '30s',
      text: '30-39 years',
    },
    {
      title: '40s',
      text: '40-49 years',
    },
    {
      title: '50+',
      text: '50+ years',
    },
  ],
};

export const SETUP_READING_TIME_QUESTION: QuizQuestion = {
  title: 'When do you read books or listen to podcasts?',
  subtitle: 'We will send you content based on your preference.',
  options: [
    {
      emoji: '☕️',
      title: 'In the morning',
    },
    {
      emoji: '🏃',
      title: 'While working out',
    },
    {
      emoji: '🚙',
      title: 'On my commute',
    },
    {
      emoji: '🛌',
      title: 'While taking a break',
    },
  ],
};

export const SETUP_STEPS: Record<string, QuizQuestion> = {
  'content-value-prop': SETUP_CONTENT_VALUE_PROP,
  'time-value-prop': SETUP_TIME_VALUE_PROP,
  time: SETUP_TIME_SPENT_QUESTON,
  achieve: SETUP_ACHIEVEMENT_GOALS_QUESTION,
  age: SETUP_AGE_QUESTION,
  gender: SETUP_GENDER_QUESTION,
  'time-of-the-day': SETUP_READING_TIME_QUESTION,
};
