import { useDisclosure, useToast } from 'deepstash-ui';
import useStripePrices from 'hooks/purchases/useStripePrices';
import useRouter from 'hooks/useRouter';
import React, { useEffect, useState } from 'react';
import { ProductType, RequestStatus, SubscriptionType } from 'types';
import { TOAST_TIME } from 'utils/constants';
import usePaymentInternals from '../usePaymentInternals';
import InvalidPromoCodeModal from '../InvalidPromoCodeModal';
import PaywallCheckoutModal from './PaywallCheckoutModal';

export interface CheckoutModalOpenerButtonProps {
  purchaseType: SubscriptionType | ProductType;
  promoCode?: string;
  expName?: string;
  analyticsLocation?: string;
}

const usePaymentCheckoutModal = ({
  purchaseType,
  promoCode: promoCodeProp,
  expName,
  analyticsLocation,
}: CheckoutModalOpenerButtonProps) => {
  const {
    isOpen: isCheckoutModalOpen,
    onOpen: onCheckoutModalOpen,
    onClose: onCheckoutModalClose,
  } = useDisclosure();

  const router = useRouter();
  const [promoCode, setPromoCode] = useState(
    promoCodeProp ?? (router.query.promo_code as string | undefined),
  );

  useEffect(() => {
    promoCodeProp && setPromoCode(promoCodeProp);
  }, [promoCodeProp]);

  const { stripePrices, errorMessage } = useStripePrices({
    promoCode,
    extraRequestParam: expName,
    requestNsi: true,
  });

  const [subscriptionIntentRequestStatus, setSubscriptionIntentRequestStatus] =
    useState<RequestStatus | undefined>();

  const setSubscriptionRequestStatus = (status: RequestStatus) => {
    setSubscriptionIntentRequestStatus(status);
  };

  const [isPromoCodeInvalid, setIsPromoCodeInvalid] = useState(false);

  const {
    clientSecret,
    subscriptionId,
    stripe,
    elementsOptions,
    chooseSubscriptionPlan,
    deleteStripeSubscriptionIntent,
  } = usePaymentInternals({
    extraRequestParam: expName,
    selectedSubscriptionOrProductType: purchaseType,
    promoCodes: {
      '1 MONTH': stripePrices?.subscriptions['1 MONTH']?.promotion?.code,
      '3 MONTH': stripePrices?.subscriptions['1 MONTH']?.promotion?.code,
      '6 MONTH': stripePrices?.subscriptions['1 MONTH']?.promotion?.code,
      '1 YEAR': stripePrices?.subscriptions['1 YEAR']?.promotion?.code,
      LIFETIME: stripePrices?.products['LIFETIME']?.promotion_code,
    },
    setInvalidPromo: () => setIsPromoCodeInvalid(true),
    type: purchaseType === 'LIFETIME' ? 'product' : 'subscription',
  });

  const onModalOpen = () => {
    onCheckoutModalOpen();
    chooseSubscriptionPlan(setSubscriptionRequestStatus);
  };

  const onCheckoutModalCloseAndDelete = () => {
    onCheckoutModalClose?.();
    // make sure to delete the data
    // otherwise the clientSecret will be corrupted (won't be changed)
    deleteStripeSubscriptionIntent();
  };

  const refetchPricesWithoutPromoAndClose = () => {
    setPromoCode(undefined);
    setIsPromoCodeInvalid(false);
  };

  const toast = useToast();

  useEffect(() => {
    if (errorMessage) {
      toast({
        title: 'Error Opening Payment Terminal',
        description: errorMessage,
        status: 'error',
        duration: TOAST_TIME,
        isClosable: true,
      });
    }
  }, [errorMessage]);

  const checkoutModal = (
    <>
      <InvalidPromoCodeModal
        refetchPricesWithoutPromoAndClose={refetchPricesWithoutPromoAndClose}
        onClosePaywall={onCheckoutModalClose}
        isOpen={isPromoCodeInvalid}
      />
      <PaywallCheckoutModal
        isCheckoutModalOpen={isCheckoutModalOpen}
        onCheckoutModalClose={onCheckoutModalCloseAndDelete}
        clientSecret={clientSecret}
        paymentDetails={{
          priceAmount:
            purchaseType === 'LIFETIME'
              ? stripePrices?.products[purchaseType]?.promotion_price_amount ??
                stripePrices?.products[purchaseType]?.price_amount ??
                0
              : stripePrices?.subscriptions[purchaseType]?.promotion
                  ?.priceAmount ??
                stripePrices?.subscriptions[purchaseType]?.priceAmount ??
                0,
          priceCurrency:
            purchaseType === 'LIFETIME'
              ? stripePrices?.products[purchaseType]?.price_currency ?? 'usd'
              : stripePrices?.subscriptions[purchaseType]?.priceCurrency ??
                'usd',
          subscriptionOrProductType: purchaseType,
          freeTrial:
            purchaseType === 'LIFETIME'
              ? undefined
              : stripePrices?.subscriptions[purchaseType]?.freeTrial,
          renewalPriceAmount:
            purchaseType === 'LIFETIME'
              ? undefined
              : stripePrices?.subscriptions[purchaseType]?.priceAmount,
        }}
        isFromSetup={false}
        subscriptionId={subscriptionId}
        location={analyticsLocation ?? 'checkout-modal-opener'}
        stripe={stripe}
        elementsOptions={elementsOptions}
        type={purchaseType === 'LIFETIME' ? 'product' : 'subscription'}
      />
    </>
  );

  return {
    onOpenCheckoutModal: onModalOpen,
    checkoutModal,
    subscriptionIntentRequestStatus,
  };
};

export default usePaymentCheckoutModal;
