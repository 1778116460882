import { CollectionApiResponse } from 'api/api.types';

export const COLLECTIONS_LANDING_DATA: CollectionApiResponse[] = [
  {
    description: 'Mastering the greatest pursuit there ever was: Happiness',
    emoji: '😃',
    hashtags: ['personaldevelopment', 'health', 'habits', 'communication'],
    id: 63,
    image:
      'https://audio.deepstash.com/covers/collection/202304/how_to_be_happy.png',
    image_blurhash: 'LeKyDJxu0yM{t7ayf5ay0yWB-Vt7',
    image_color: 'F89067',
    image_compressed:
      'https://d3t70bohx4vuj7.cloudfront.net/NvuoXCyyOyY5tEx9bwRGUUNT9DVx9pVCwbmCJ37UVG8/resize:fill:600:0/gravity:sm/quality:90/aHR0cHM6Ly9hdWRpby5kZWVwc3Rhc2guY29tL2NvdmVycy9jb2xsZWN0aW9uLzIwMjMwNC9ob3dfdG9fYmVfaGFwcHkucG5n',
    image_preview: 'https://preview.deepstash.com/c/63-1647960009.png',
    name: 'How to Be Happy',
    skills: [
      'How to find purpose and meaning in life',
      'How to cultivate gratitude',
      'Techniques for managing negative thoughts',
      'Understanding the science of happiness',
    ],
    time_to_read: 917,
    total_article_sources: 4,
    total_blocks: 100,
    total_book_sources: 3,
    total_podcast_sources: 0,
    total_selfpost_sources: 0,
    total_video_sources: 0,
  },
  {
    description: 'The best books on building habits',
    emoji: '👀',
    hashtags: [
      'productivity',
      'personaldevelopment',
      'problemsolving',
      'books',
    ],
    id: 101,
    image:
      'https://audio.deepstash.com/covers/collection/202304/habit_book.png',
    image_blurhash: 'LhNl}SRj-6t6}9ofRPfQRjj[ENWB',
    image_color: 'BE3241',
    image_compressed:
      'https://d3t70bohx4vuj7.cloudfront.net/-wtewmT1YUzMDBEHZBJYM0tCthu5m85YgnyMvOJLE3c/resize:fill:600:0/gravity:sm/quality:90/aHR0cHM6Ly9hdWRpby5kZWVwc3Rhc2guY29tL2NvdmVycy9jb2xsZWN0aW9uLzIwMjMwNC9oYWJpdF9ib29rLnBuZw',
    image_preview: 'https://preview.deepstash.com/c/101-1660722307.png',
    name: '7 Books on Habits',
    skills: [
      'How to break bad habits',
      'How habits are formed',
      'The importance of consistency',
      'How habits impact productivity and success',
    ],
    time_to_read: 662,
    total_article_sources: 0,
    total_blocks: 88,
    total_book_sources: 7,
    total_podcast_sources: 0,
    total_selfpost_sources: 0,
    total_video_sources: 0,
  },
  {
    description:
      'What is the best way to live longer? Here are a few factors within your control',
    emoji: '🚴‍♂️',
    hashtags: ['food', 'personaldevelopment', 'health', 'scienceandnature'],
    id: 93,
    image: 'https://audio.deepstash.com/covers/collection/202304/longevity.png',
    image_blurhash: 'LwMGw;NGxWog~BjYIot6IVofR*WB',
    image_color: 'D6BE97',
    image_compressed:
      'https://d3t70bohx4vuj7.cloudfront.net/r-MSJktLtnE1imtHcpQ7lKHS1-M8KI7CUHFgT3YjWIc/resize:fill:600:0/gravity:sm/quality:90/aHR0cHM6Ly9hdWRpby5kZWVwc3Rhc2guY29tL2NvdmVycy9jb2xsZWN0aW9uLzIwMjMwNC9sb25nZXZpdHkucG5n',
    image_preview: 'https://preview.deepstash.com/c/93-1657277873.png',
    name: 'How To Live A Long Life',
    skills: [
      'The importance of physical activity',
      'The role of genetics in lifespan',
      'How to maintain a healthy diet',
      'The impact of stress on longevity',
    ],
    time_to_read: 855,
    total_article_sources: 4,
    total_blocks: 71,
    total_book_sources: 1,
    total_podcast_sources: 3,
    total_selfpost_sources: 0,
    total_video_sources: 1,
  },
  {
    description:
      'How to prioritize what matters, and how good decisions are made.',
    emoji: '👨‍⚖️',
    hashtags: [
      'personaldevelopment',
      'problemsolving',
      'communication',
      'timemanagement',
    ],
    id: 54,
    image:
      'https://audio.deepstash.com/covers/collection/202304/making_better_decisions.png',
    image_blurhash: 'LaIG.7ofGHWV?[WBWVoLXTofwIWV',
    image_color: 'D5A244',
    image_compressed:
      'https://d3t70bohx4vuj7.cloudfront.net/vGDGOgtF3TFHb04o6kCPsoeKu2Yt0sZgObSaPR8YiKE/resize:fill:600:0/gravity:sm/quality:90/aHR0cHM6Ly9hdWRpby5kZWVwc3Rhc2guY29tL2NvdmVycy9jb2xsZWN0aW9uLzIwMjMwNC9tYWtpbmdfYmV0dGVyX2RlY2lzaW9ucy5wbmc',
    image_preview: 'https://preview.deepstash.com/c/54-1647959995.png',
    name: 'Making Better Decisions',
    skills: [
      'How to make good decisions',
      'How to manage work stress',
      'How to manage email effectively',
      'How to learn anything fast',
    ],
    time_to_read: 892,
    total_article_sources: 5,
    total_blocks: 85,
    total_book_sources: 3,
    total_podcast_sources: 0,
    total_selfpost_sources: 0,
    total_video_sources: 1,
  },
  {
    description:
      'This collection will help you make easy routine changes and take back control of your time.',
    emoji: '⏰',
    hashtags: [
      'productivity',
      'personaldevelopment',
      'timemanagement',
      'teamwork',
      'books',
    ],
    id: 10,
    image:
      'https://audio.deepstash.com/covers/collection/202304/managing_time_like_a_pro.png',
    image_blurhash: 'LfQuf.E.R3IV}6#UnQxsMcRPtSt6',
    image_color: 'FECB68',
    image_compressed:
      'https://d3t70bohx4vuj7.cloudfront.net/vkM81Dfc-dEq8SmVpZzNRbtLPbZdBXQ9ieqQPgTnl74/resize:fill:600:0/gravity:sm/quality:90/aHR0cHM6Ly9hdWRpby5kZWVwc3Rhc2guY29tL2NvdmVycy9jb2xsZWN0aW9uLzIwMjMwNC9tYW5hZ2luZ190aW1lX2xpa2VfYV9wcm8ucG5n',
    image_preview: 'https://preview.deepstash.com/c/10-1682337269.png',
    name: 'Managing Time Like a Pro',
    skills: [
      'How to set achievable goals',
      'How to manage time for personal and professional life',
      'How to avoid distractions',
      'The importance of taking breaks',
    ],
    time_to_read: 865,
    total_article_sources: 5,
    total_blocks: 69,
    total_book_sources: 3,
    total_podcast_sources: 0,
    total_selfpost_sources: 0,
    total_video_sources: 0,
  },
  // {
  //   description:
  //     "Steps to overcome the feeling that you don't belong where you are, and you only got there through dumb luck.",
  //   emoji: '😰',
  //   hashtags: [
  //     'personaldevelopment',
  //     'problemsolving',
  //     'communication',
  //     'books',
  //   ],
  //   id: 69,
  //   image:
  //     'https://audio.deepstash.com/covers/collection/202304/the_imposter_cure.png',
  //   image_blurhash: 'LvL4$nMx0fn%bvoLRiWC9ajZxaj[',
  //   image_color: 'D7A374',
  //   image_compressed:
  //     'https://d3t70bohx4vuj7.cloudfront.net/ZMUaggR9Os-yMfweGpWNFhDbEAIpixaVoSITV29ceGE/resize:fill:600:0/gravity:sm/quality:90/aHR0cHM6Ly9hdWRpby5kZWVwc3Rhc2guY29tL2NvdmVycy9jb2xsZWN0aW9uLzIwMjMwNC90aGVfaW1wb3N0ZXJfY3VyZS5wbmc',
  //   image_preview: 'https://preview.deepstash.com/c/69-1647960028.png',
  //   name: 'The Imposter Cure',
  //   skills: [
  //     'Strategies for building self-confidence',
  //     'Techniques for embracing your strengths and accomplishments',
  //     'Tips for seeking support and feedback',
  //     'How to identify and overcome imposter syndrome',
  //   ],
  //   time_to_read: 874,
  //   total_article_sources: 2,
  //   total_blocks: 46,
  //   total_book_sources: 2,
  //   total_podcast_sources: 1,
  //   total_selfpost_sources: 0,
  //   total_video_sources: 0,
  // },
];
