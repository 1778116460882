import { Icon, IconProps } from 'deepstash-ui';
import * as React from 'react';

export const SpottyConnectionIllustraton = React.memo<IconProps>(props => {
  return (
    <Icon
      width={props.width ?? props.w ?? 78}
      height={props.height ?? props.h ?? 78}
      viewBox="0 0 78 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_640_45988)">
        <path
          d="M35.3434 9.75036C31.9797 9.75036 28.7573 10.3841 25.8031 11.559C24.9019 11.9171 24.1799 12.6186 23.7959 13.5091C23.4119 14.3995 23.3974 15.4061 23.7556 16.3072C24.1137 17.2084 24.8152 17.9304 25.7056 18.3144C26.5961 18.6984 27.6026 18.7129 28.5038 18.3547C30.6001 17.526 32.9059 17.0629 35.3434 17.0629C39.1311 17.0343 42.8327 18.1924 45.9287 20.3747C49.0247 22.557 51.3596 25.6539 52.6058 29.2309C52.8551 29.9488 53.3219 30.5713 53.9414 31.0116C54.5609 31.452 55.3022 31.6884 56.0622 31.6879C64.2327 31.6879 70.6872 38.1424 70.6872 46.3129C70.7062 49.7246 69.5318 53.0356 67.3673 55.6729C66.7806 56.4234 66.5101 57.3732 66.6131 58.3202C66.7162 59.2673 67.1848 60.1367 67.9193 60.7434C68.6537 61.3501 69.5959 61.6462 70.5453 61.5687C71.4948 61.4912 72.3765 61.0463 73.0028 60.3285C76.2518 56.3822 78.0194 51.4245 77.9997 46.3129C78.0308 40.922 76.0634 35.7107 72.4774 31.6854C68.8915 27.6601 63.9412 25.1061 58.5826 24.5167C56.5325 20.0863 53.2519 16.3388 49.1316 13.7207C45.0113 11.1026 40.2251 9.72441 35.3434 9.75036Z"
          fill="#743C7A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.51044 8.38636C4.19599 7.70166 5.12528 7.31708 6.09419 7.31708C7.0631 7.31708 7.99239 7.70166 8.67794 8.38636L19.9441 19.6476C20.0903 19.7695 20.2171 19.9011 20.3341 20.0376L61.9227 61.6311C61.9813 61.6846 62.0382 61.7399 62.0933 61.7969L72.0529 71.7614C72.4122 72.0961 72.7003 72.4997 72.9001 72.9482C73.1 73.3967 73.2074 73.8809 73.2161 74.3718C73.2247 74.8627 73.1344 75.3504 72.9505 75.8056C72.7666 76.2609 72.4929 76.6745 72.1458 77.0217C71.7986 77.3689 71.385 77.6426 70.9297 77.8265C70.4745 78.0103 69.9868 78.1007 69.4959 78.092C69.005 78.0833 68.5208 77.9759 68.0723 77.776C67.6238 77.5762 67.2202 77.2881 66.8854 76.9289L58.1104 68.1587C57.4297 68.2205 56.7465 68.2514 56.0629 68.2514H17.0629C14.8177 68.2676 12.5917 67.8373 10.5143 66.9856C8.43684 66.1338 6.54943 64.8776 4.96179 63.29C3.37416 61.7024 2.11796 59.815 1.26623 57.7375C0.414496 55.6601 -0.0157699 53.4341 0.000441626 51.1889C0.000441626 44.3151 3.94432 38.4456 9.75532 35.7351C9.66527 31.2718 10.7514 26.8635 12.9046 22.9529L3.51044 13.5539C2.82575 12.8683 2.44116 11.939 2.44116 10.9701C2.44116 10.0012 2.82575 9.07191 3.51044 8.38636ZM17.0629 35.3451C17.0629 32.8832 17.5309 30.553 18.3841 28.4324L50.8954 60.9389H17.0629C11.5834 60.9389 7.31294 56.6684 7.31294 51.1889C7.31294 46.5576 10.3549 42.8234 14.5474 41.7509C15.4211 41.5279 16.1816 40.99 16.6828 40.2405C17.184 39.4909 17.3906 38.5826 17.2628 37.69C17.1502 36.913 17.0835 36.13 17.0629 35.3451Z"
          fill="#743C7A"
        />
      </g>
      <defs>
        <clipPath id="clip0_640_45988">
          <rect width="78" height="78" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
});

export default SpottyConnectionIllustraton;
