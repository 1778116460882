import {
  color,
  Flex,
  Link,
  PrimaryText,
  spacing,
  StyleProps,
  Text,
  useColorMode,
} from 'deepstash-ui';
import React, { useState } from 'react';
import { Analytics, Events } from 'services/analytics';
import { FOOTER_SECTIONS } from 'src/components/layout/components/Footer';
import NextLink from 'src/components/navigation/NextLink';

const PublicLinksSection: React.FC<StyleProps> = ({ ...props }) => {
  const { colorMode } = useColorMode();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Flex
      color={color[colorMode].textSecondary}
      bgColor={color[colorMode].background}
      flexDirection="column"
      {...props}
    >
      <PrimaryText size="l">
        deep<b>stash</b>
      </PrimaryText>
      <Flex pt={spacing.XS.rem} justifyContent="space-between">
        {FOOTER_SECTIONS.map(section => (
          <Flex
            flexDirection="column"
            key={section.id}
            alignItems="left"
            width={spacing.toRem(96)}
            gridGap="1rem"
          >
            <PrimaryText
              size="m"
              fontWeight={600}
              cursor="pointer"
              onClick={() => setIsExpanded(e => !e)}
            >
              {section.title}
            </PrimaryText>
            <Flex
              transition="all 0.3s ease-in-out"
              height={isExpanded ? spacing.toRem(144) : 0}
              overflow="hidden"
              flexDirection="column"
              gridGap="1rem"
            >
              {section.links.map((link, index) =>
                link.href.startsWith('https') ? (
                  <Link href={link.href}>{link.title}</Link>
                ) : (
                  <NextLink key={index} href={link.href} passHref>
                    <Link _focus={undefined}>
                      <Text
                        onClick={() => {
                          Analytics.logEvent({
                            eventName: Events.consumption.footerTap,
                            properties: {
                              button: '',
                            },
                            platforms: ['amplitude'],
                          });
                        }}
                      >
                        {link.title}
                      </Text>
                    </Link>
                  </NextLink>
                ),
              )}
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default PublicLinksSection;
